import { ApolloProvider } from "@apollo/react-common";
import { ConnectedRouter } from "connected-react-router";
import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {Provider, useDispatch} from "react-redux";
import "typeface-roboto";
import { initAuth } from "./auth";
import history from "./history";
import configureStore from "./store";
import registerServiceWorker from "./utils/register-service-worker";
import App from "./views/app";
import "./views/styles/styles.scss";
import {getGoogleClient} from "./auth/actions";
import {Helmet} from "react-helmet";

const store = configureStore();
const rootElement = document.getElementById("root");

//const {whyDidYouUpdate} = require('why-did-you-update');
//whyDidYouUpdate(React);

function render(Component) {



  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>

        <div>
          <Component />
        </div>
      </ConnectedRouter>
    </Provider>,
    rootElement
  );
}

if (module.hot) {
  module.hot.accept("./views/app", () => {
    render(require("./views/app").default);
  });
}

registerServiceWorker();

initAuth(store.dispatch)
  .then(() =>
      // dispatch(getGoogleClient())

      render(App)
  )
  .catch((error) => {
    console.error(error);
    render(App);
  });
