import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
  CircularProgress,
  Modal,
} from "@material-ui/core";
import {chargeebeeUrlApi} from "auth/manager"
// import {
//   CardComponent,
//   CardNumber,
//   CardExpiry,
//   CardCVV,
//   Provider,
// } from "@chargebee/chargebee-js-react-wrapper";

import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";

import {
  AssignmentTurnedInOutlined,
  CallMissedOutgoing,
  DoneRounded,
  Sync,
} from "@material-ui/icons";
import moment from "moment";
import MUIDataTable from "mui-datatables";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { clientActions } from "getDetails";
import { authActions, CANCEL_SCHEDULE_MODAL } from "auth";
import CodeIcon from "@material-ui/icons/Code";

import DatePicker from "views/components/date-picker";
import StatusTag from "views/components/StatusTags";
import ErrorPage from "../Error/error";
import Dashboard from "./dashboard";

import Plan from "../account/Plan";
import Pricing from "./PlanCard";

// import {chargeebeeUrlApi} from "auth/manager"
import { checkOut } from "getDetails/actions";
import {sendDemoApiRequest} from "../../../auth/actions";

const styles = (theme) => ({
  customBtn: {
    justifyContent: "space-between",
    height: "100%",
    borderRadius: 0,
    textTransform: "none",
  },
  viewLogsButton: {
    margin: "25px 10%",
    backgroundColor: "#0084ec",
    borderRadius: "8px",
    color: "#fff",
    boxShadow: "0px 0px 15px 0px #ccc",
    border: "none",
    textTransform: "none",
  },
  circularProgress: {
    paddingTop: "10px",
  },
  loadingData: {
    height: "60%",
  },
  paper1: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    outline: "none",
    boxShadow: theme.shadows[5],
    padding: "24px 25px 20px 24px",
    borderRadius:"10px"
  },
  modal: {
    backdropFilter: "blur(1px)",
  },
  paper: {
    position: "absolute",
    // width: "90%",
    width: "600px",
    backgroundColor: "#ffff",
    // overflowY: "scroll",
    outline: "none",
    padding: "10px",

    borderRadius: "10px",

    boxShadow: theme.shadows[5],
    // "& .focus-visible": {
    //   outline: "none",
    // },

    // padding: "1px 19px 1px",
  },
});

const infoBar = [
  {
    title: "Invitations made",
    value: "6",
    icon: <CallMissedOutgoing fontSize={"large"} />,
    color: "#6c63ff",
  },
  {
    title: "Onboarding completed",
    value: "1",
    icon: <DoneRounded fontSize={"large"} />,
    color: "#19da69",
  },
  {
    title: "Documents collected",
    value: "5",
    icon: <AssignmentTurnedInOutlined fontSize={"large"} />,
    color: "#FF9800",
  },
];

class Home extends React.Component {
  constructor(props) {
    super(props);
    // console.log(this.props);
    this.state = {
      planData: [],

      checkoutData: {},
      currentPlan: {},
      authenticated: true,
      timer: "",
      open: false,
      modalOpen: false,
      planId: "",

      modalStyle: this.getModalStyle(),

      handleClose: this.handleClose.bind(this),
      handleOpenModal: this.handleOpenModal.bind(this),
      // callData: this.callData.bind(this),
      data: [
        {
          client_id: "aksjdjasdklom",
          name: "Akram singh",
          email: "ak23476@gmail.com",
          phone: "8368621097",
          date: "2021-05-03T18:07:01.560000",
          status: "completed",
        },

        ],
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    // do things with nextProps.someProp and prevState.cachedSomeProp

    if (nextProps.planId) {
      console.log(prevState.checkoutData)
      console.log(nextProps.checkout?.data?.hosted_page?.id === prevState.checkoutData?.data?.hosted_page?.id)
      console.log(nextProps.checkout?.data?.hosted_page?.id,prevState.checkoutData?.data?.hosted_page?.id)
      if (nextProps.checkout?.data?.hosted_page?.id !== prevState.checkoutData?.data?.hosted_page?.id) {
        // console.log("CheckoutPlanACtion", nextProps.initialCheckOut);

        if (nextProps.checkout) {
          if (!nextProps.initialCheckOut) {
            nextProps.setInitialCheckout(true);
            nextProps.getPlanId("");
            // console.log("CheckoutPlanACtion", nextProps.initialCheckOut);
            console.log("CheckoutData", nextProps.checkout);
            var chargebeeInstance = window.Chargebee.getInstance();
            chargebeeInstance.openCheckout({
              hostedPage: async () => {
                // console.log("Url", nextProps.checkout?.data?.hosted_page?.url);
                // We will discuss on how to implement this end point in the next step.
                if (prevState.checkoutData === nextProps.checkout) {
                  console.log(prevState)
                }
                return await nextProps.checkout?.data?.hosted_page;
              },
              success: function (hostedPageId) {
                // timer = setInterval(prevState.callData, 3000);
                // prevState.timer = timer;
                // console.log(prevState.timer);
                nextProps.currentPlanDetail();
                nextProps.successModalFn(true);

                // console.log("Timer", timer);
                setTimeout(() => {
                  chargebeeInstance.closeAll();
                }, 2000);
              },
              error: function (error) {
                // console.log("error", error);
              },
            });

          }
        }
      }
      var newState = prevState;
      newState.checkoutData = nextProps.checkout;
      console.log("New", newState);
      console.log("prev",prevState)

      return newState;
    }
    // console.log("NextProps", nextProps);
    // console.log("Prev", prevState);

    if (nextProps.currentPlan !== prevState.currentPlan) {
      // nextProps.currentPlanDetail();
      if (nextProps.currentPlan?.data?.plan_id) {
        nextProps.successModalFn(false);
      }
      if (nextProps.currentPlan?.data?.cancelled === true) {
        nextProps.cancelModalFn(false);
      }
      if (!nextProps.firstPop) {
        if (nextProps.currentPlan) {
          if (
            nextProps.currentPlan?.data?.plan_id === null &&
            nextProps.planId === null
          ) {
            // console.log("TRue open Popup");
            prevState.handleOpenModal();
            nextProps.firstPopShow(true);
          }
        }
      }

      var newState = prevState;
      newState.currentPlan = nextProps.currentPlan;
      return newState;
    }

    return prevState;
  }
  handleOpenModal = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };
  componentDidMount() {

    const el = document.createElement('script');
    el.onload = () => {
      chargeebeeUrlApi().then(res=>{
        window.Chargebee.init({
          site: res.data?.data?.chargebee_site,
          // domain: this.props.chargebeeDetails?.data?.chargebee_domain,
          publishableKey: res.data?.data?.chargebee_key,
        });

      }).catch(err=>console.log(err))

      // window.Chargebee.init({
      //   site: this.props.chargebeeDetails?.data?.chargebee_site,
      //   // domain: this.props.chargebeeDetails?.data?.chargebee_domain,
      //   publishableKey: this.props.chargebeeDetails?.data?.chargebee_key,
      // });
      // window.Chargebee.registerAgain();
    }



      // this.setState({ chargebeeReady: true });

    // el.setAttribute('data-cb-site',this.props.chargebeeDetails?.data?.chargebee_site)
    el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');

    document.body.appendChild(el);
    // console.log(this.props.planDetail);

    this.props.getPlanList();

    this.props.currentPlanDetail();
     this.props.getProfileData();
    // if (!this.props.currentPlan) this.props.currentPlanDetail();
     this.props.tokenListCall();
     this.props.getApiUsageData();

    if (this.props.planId) {
      console.log("planId",this.props.planId)
      this.props.checkoutPlan(this.props.planId);
    }
    // console.log(this.props.chargebeeDetails)



  }
  getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  changeTableStyle = () =>
    createMuiTheme({
      palette: this.props.theme.palette,
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            // textAlign: "center",
            color: this.props.theme.palette.primary.main,
            fontSize: "16px",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            // paddingLeft: "50px",
            // textAlign: "center",
            marginLeft: "auto",
            borderTop: `1px solid ${this.props.theme.palette.grey[300]}`,
            backgroundColor: "#f7f8fb !important",
            color: this.props.theme.palette.primary.main,
            fontSize: "18px",
            fontWeight: 600,
          },
        },
        MuiTableRow: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "#fafbfe !important",
            },
            "&:nth-child(odd)": {
              backgroundColor: "#ffffff !important",
            },
          },
          hover: {
            "&:hover": {
              backgroundColor: "rgba(122, 139, 237, 0.1)!important",
              cursor: "pointer",
            },
          },
        },
        MuiPaper: {
          root: {
            border: `1px solid ${this.props.theme.palette.grey[300]}`,
            borderBottom: 0,
          },
        },
        MuiToolbar: {
          root: {
            position: "static",
            paddingLeft: "15px !important",
          },
        },
        MuiTableCell: {
          paddingCheckbox: {
            display: "none",
          },
          head: {
            padding: "14px !important",
          },
          root: {
            "&:not(:nth-last-child(1))": {
              borderRight: `1px solid ${this.props.theme.palette.grey[300]}`,
            },
            padding: "14px !important",
          },
        },
      },
    });

  render() {
    const { classes } = this.props;
    // console.log("Classes", classes);
    const { data, open, hideHeading } = this.state;

    return (
      <>
        <Dashboard handleOpenModal={this.handleOpenModal} props={this.props} />
        {this.props.successModal && (
          <Modal open={this.props.successModal} className={classes.modal}>
            <div style={this.state.modalStyle} className={classes.paper1}>
              <Grid container justifyContent="space-between">
                <Grid item xs={2}>
                  <CircularProgress color="primary" />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body2">
                    Please wait while your payment is being processed
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Modal>
        )}

        {this.state.modalOpen && (
          <Modal
            open={this.state.modalOpen}
            onClose={this.handleClose}
            // className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div style={this.state.modalStyle} className={classes.paper}>
              <Pricing
                handleClose={this.handleClose}
                open={this.state.modalOpen}
                // open={true}
              />
            </div>
          </Modal>
        )}
      </>
    );
  }
}

//=====================================
//  CONNECT
//-------------------------------------

const mapStateToProps = (state) => {
  return {
    planDetail: state.clientData.planDetail,
    authenticated: state.auth.authenticated,
    checkout: state.clientData.checkout,
    initialCheckOut: state.clientData.initialCheckOut,
    planId: state.clientData.planId,
    currentPlan: state.clientData.currentPlan,
    profileData: state.clientData.profileData,
    tokenList: state.auth.tokenLists,
    apiUsageData: state.clientData.apiUsage,
    demoAPILoading: state.auth.demoAPILoading,
    responseFromDemoAPI: state.auth.responseFromDemoAPI,
    statusCode:state.auth.statusCode,
    errorMsg:state.auth.errorMsg,
    firstPop: state.planPopRed.first,
    successModal: state.modalRed.successModalOpen,
    cancelModal: state.modalRed.cancelModalState,
    chargebeeDetails:state.auth.chargebeeDetails
  };
};
const mapDispatchToProps = {
  getPlanList: clientActions.getPlanList,
  sendDemoApiRequest: authActions.sendDemoApiRequest,
  checkoutPlan: clientActions.checkOut,
  setInitialCheckout: clientActions.setInitialCheckout,
  getPlanId: clientActions.getPlanID,
  tokenListCall: authActions.tokenList,
  currentPlanDetail: clientActions.getCurrentPlanDetails,
  firstPopShow: clientActions.firstShow,
  successModalFn: authActions.successModal,
  cancelModalFn: authActions.cancelModal,
  getProfileData: clientActions.getProfileDetails,
  getApiUsageData: clientActions.getApiUsageDetail,




  cancelScheduleFn: () => (dispatch) =>
    dispatch({ type: CANCEL_SCHEDULE_MODAL, payload: false }),
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Home);
