import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
  CircularProgress,
  LinearProgress,
  Container,
  Snackbar,
} from "@material-ui/core";

import { AttachMoneyRounded } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import BalanceThreshold from "./balanceThreshold";
import SignHistory from "./eSignHistory";
import Invoices from "./invoices";
import Purchase from "./purchase";
import PurchaseHistory from "./purchaseHistory";
import StampHistory from "./stampHistory";
import { useSelector, useDispatch } from "react-redux";
import { getBilling } from "getDetails/actions";

import Payment from "./payments";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(4),
    textTransform: "none",
  },
  navButton: {
    marginRight: theme.spacing(3),
    borderRadius: 0,
    textTransform: "none",
    borderBottom: `2px solid #fff `,
    color: theme.palette.grey[700],
    "&:hover": {
      color: theme.palette.grey[900],
      borderBottom: `2px solid ${theme.palette.grey[700]}`,
    },
  },
  navButtonActive: {
    marginRight: theme.spacing(3),
    borderRadius: 0,
    textTransform: "none",
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  billing: {
    height: "100%",
  },
  circularProgress: {
    paddingTop: "10px",
  },
  errorBiling: {
    fontSize: "1rem",

    padding: "4px 0px",
  },

  root: {
    width: "100%",

    margin: "auto",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

const getTab = (idx) => {
  // switch (idx) {
  //     case 0:
  //         return <PurchaseHistory />
  //     case 1:
  //         return <Invoices />
  //     case 2:
  //         return <SignHistory />
  //     case 3:
  //         return <StampHistory />
  //     case 4:
  //         return <Purchase />
  //     case 5:
  //         return <BalanceThreshold />
  // }
  return (
    <>
      <Collapse mountOnEnter unmountOnExit in={idx === 0}>
        <Payment />
      </Collapse>

      {/* <Collapse mountOnEnter unmountOnExit in={idx === 1}>
        <Invoices />
      </Collapse>

      <Collapse mountOnEnter unmountOnExit in={idx === 2}>
        <SignHistory />
      </Collapse>

      <Collapse mountOnEnter unmountOnExit in={idx === 3}>
        <StampHistory />
      </Collapse>

      <Collapse mountOnEnter in={idx === 4}>
        <Purchase />
      </Collapse>

      <Collapse mountOnEnter unmountOnExit in={idx === 5}>
        <BalanceThreshold />
      </Collapse> */}
    </>
  );
};

export default function Billing() {
  const classes = useStyles();

  const {
    billingDetail,
    // billingDetailFailure,
    apiUsage,
    errormsg,
    statusCode,
  } = useSelector((state) => state.clientData);

  // console.log("ClientBillingData", billingDetail);
  // console.log("APi", apiUsage);
  const [activeTab, setActiveTab] = useState(0);
  const [errorMesg, setErrorMesg] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    if (!billingDetail) dispatch(getBilling());
  }, [dispatch]);
  useEffect(() => {
    setErrorMesg(errormsg);
  }, [errormsg]);

  return (
    <>
      <Snackbar
        open={!!errorMesg}
        autoHideDuration={3000}
        onClose={() => setErrorMesg(null)}
      >
        <Alert onClose={() => setErrorMesg(null)} severity="error">
          {statusCode}&nbsp;
          {errorMesg}
        </Alert>
      </Snackbar>
      <Grid
        container
        justifyContent="center"
        // className={!billingDetail ? classes.billing : null}
      >
        {billingDetail ? (
          <>
            <Grid
              component={Paper}
              variant="outlined"
              container
              item
              xs={12}
              justifyContent="center"
              style={{
                padding: "0px 4px",
                marginBottom: 10,
                backgroundColor: "transparent",
                border: "none",
              }}
            >
              <Grid item xs={12}>
                {/* <Button
                className={
                  activeTab === 0 ? classes.navButtonActive : classes.navButton
                }
                onClick={() => setActiveTab(0)}
              >
                Payment Details
              </Button> */}
                {/* <Button
                className={
                  activeTab === 1 ? classes.navButtonActive : classes.navButton
                }
                onClick={() => setActiveTab(1)}
                disabled
              >
                &nbsp;&nbsp; Invoices &nbsp;&nbsp;
              </Button>
              <Button
                className={
                  activeTab === 2 ? classes.navButtonActive : classes.navButton
                }
                onClick={() => setActiveTab(2)}
                disabled
              >
                eSign Consumption History
              </Button>
              <Button
                className={
                  activeTab === 3 ? classes.navButtonActive : classes.navButton
                }
                onClick={() => setActiveTab(3)}
                disabled
              >
                Stamp Consumption History
              </Button>
              <Button
                className={
                  activeTab === 4 ? classes.navButtonActive : classes.navButton
                }
                onClick={() => setActiveTab(4)}
                disabled
              >
                &nbsp;Purchase&nbsp;
              </Button>
              <Button
                className={
                  activeTab === 5 ? classes.navButtonActive : classes.navButton
                }
                onClick={() => setActiveTab(5)}
                disabled
              >
                Low Balance Threshold
              </Button> */}
              </Grid>
              {/* <Grid item xs={12}>
              <Divider />
            </Grid> */}
              <Grid item xs={12}>
                {getTab(activeTab)}
              </Grid>
            </Grid>
          </>
        ) : (
          <div className={classes.root}>
            <LinearProgress />
          </div>
        )}
      </Grid>
    </>
  );
}
