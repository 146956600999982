import axios from "axios";
import { axiosinstance } from "../utils/axios-instance";

export function getClientsListCall() {
  return axiosinstance.get(`api/v1/esign/realm/list`);
}

export function getClientDetailsCall(client_id) {
  return axiosinstance.get(`api/v1/esign/realm/details/${client_id}`);
}
export function getPlanListDetails() {
  return axiosinstance.get(`/api/v1/users/chargebee-plans?product=SurePDF`);
}

export function checkOutPlan(id) {
  // console.log("Id in manager Checkout", id);
  return axiosinstance.post(`/api/v1/users/chargebee-checkout?plan_id=${id}`);
}
export function getAuthLogs() {
  return axiosinstance.get(`/api/v1/users/login-audit-log`);
}
export function getBillingDetail() {
  return axiosinstance.get(`/api/v1/users/chargebee-recharge-history`);
}
export function getClientInfo() {
  return axiosinstance.get(`/api/v1/users/profile-details`);
}
export function getCurrentPlan() {
  return axiosinstance.get("/api/v1/users/chargebee-subscription-details");
}
export function getApiUsage() {
  return axiosinstance.get("/api/v1/users/templates-usage-history");
}

export function cancelSubscription() {
  return axiosinstance.post("/api/v1/users/chargebee-cancel");
}

export function changePlanAPI(value) {
  return axiosinstance.post("/api/v1/users/chargebee-change-plan", value);
}

export function cancelScheduleChangePlanAPI() {
  return axiosinstance.post(
    "/api/v1/users/chargebee-remove-scheduled-plan-change"
  );
}

export function removeCancelScheduleAPI() {
  return axiosinstance.post("/api/v1/users/chargbee-remove-cancellation");
}
