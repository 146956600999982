import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { FeaturedPlayListRounded } from "@material-ui/icons";

import moment from "moment";

import {
  Box,
  Typography,
  ListItemText,
  Divider,
  ListItem,
  List,
  Paper,
  CircularProgress,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
  LinearProgress,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { LockOpen, ExpandMore } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { getAuthLogData, getProfileDetails } from "getDetails/actions";
import { Container } from "@material-ui/core";
import { Alert, Pagination } from "@material-ui/lab";
import Tools from "views/components/custom-tootltip/Tools";
import chromeIcon from "assets/chrome.svg";
import fireFoxIcon from "assets/Firefox.svg";
import edgeIcon from "assets/Edge.svg";
import safariIcon from "assets/Safari.svg";
import WindowIcon from "assets/windows.svg";
import MacIcon from "assets/mac.svg";
import LinuxIcon from "assets/linux.svg";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",

    backgroundColor: theme.palette.background.paper,

    border: "1px solid lightgray",
    borderRadius: ".2rem",
  },
  rootLinear: {
    width: "100%",

    margin: "auto",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  paper: {
    width: "90%",
    margin: "40px auto",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },

  customHeading: {
    color: "#000",
    display: "flex",
    margin: "50px 0px 0px 55px",
    alignItems: "center",
  },
  customIcon: {
    fontSize: "35px",
    marginRight: "20px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(19),
    fontWeight: 500,
  },
  inline: {
    display: "inline",
    fontSize: ".9rem",
  },
  circularProgress: {
    paddingTop: "10px",
  },
  right: {
    display: "inline",
    fontSize: "14px",
    // paddingRight: "4px",
    position: "absolute",
    right: "50px",
    paddingRight: "10px",
  },
  browserIcon: {
    marginLeft: "15px",
    width: "30px",
  },
  authMain: {
    borderTopLeftRadius: ".2rem",
    borderTopRightRadius: ".2rem",
    borderBottom: "1px solid light",
    padding: theme.spacing(2),
    backgroundColor: "#bfbfbf57 ",
  },
  authBox: {
    padding: theme.spacing(1),
    "&:not(:last-child)": {
      borderBottom: "1px solid lightgray",
    },
  },
}));
const theme = createMuiTheme({
  //   palette: {
  //     primary: {
  //       //   light: "#26c6da",
  //       //   main: "#26c6da",
  //       //   dark: "#26c6da",
  //       //   contrastText: "#fff",
  //     },
  //     secondary: {
  //       //   light: "#ff7961",
  //       //   main: "#e91e63",
  //       //   dark: "#ba000d",
  //       //   contrastText: "#000",
  //     },
  //   },
  //   typography: {
  //     useNextVariants: true
  //   }
});

export default function AuthenticationLogs() {
  const { authLogs, profileData, errormsg, statusCode } = useSelector(
    (state) => state?.clientData
  );
  const [authLog, setAuthLog] = useState([]);
  const [profile, setProfile] = useState([]);
  const [errorMesg, setErrorMesg] = useState("");
  const classes = useStyles();
  const imageArray = [chromeIcon, fireFoxIcon, safariIcon, edgeIcon];
  let safari = "Safari";
  let chrome = "Chrome";
  let firefox = "Mozilla FireFox";
  let edge = "Microsoft Edge";

  let windows = "Windows";
  let mac = "Apple Mac Os X";
  let linux = "Linux";

  // console.log("ArrayLength", authLogs?.data?.length);
  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableToolbar: {
          actions: {
            display: "none",
          },
          root: {
            // backgroundColor: "#0084ec",
            // color: "#fff",
          },
        },
        MuiTableCell: {
          root: {
            padding: "10px",
          },
          body: {
            textAlign: "left",
            paddingLeft: "30px",
          },
        },
        MuiPaper: {
          root: {
            width: "80%",
            margin: "20px auto",
          },
        },
        MuiInputBase: {
          formControl: {
            "&:hover": {
              outline: "none",
            },
          },
        },
        MuiPaper: {
          elevation4: {
            boxShadow: "none",
          },
          outlined: {
            // border: "1px solid #ccc",
            border: "none",
            borderRight: "1px solid rgba(224, 224, 224, 1)",
            borderLeft: "1px solid rgba(224, 224, 224, 1)",
            borderTop: "1px solid rgba(224, 224, 224, 1)",
          },
        },
        MuiButton: {
          contained: {
            backgroundColor: "rgb(63, 81, 181)",
            color: "#fff",
            padding: ".5rem",

            fontWeight: "500",
            fontSize: ".875rem",

            lineHeight: "1",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "rgb(63, 81, 181)",
            },
          },
        },
        MUIDataTableHeadCell: {
          contentWrapper: {
            justifyContent: "flex-start",
            paddingLeft: "24px",
          },
          data: {
            fontWeight: "700",
          },
        },
        // MuiTableCell: {
        //   root: {
        //     "&:last-child": {
        //       // width: "10ch",
        //     },
        //   },
        // },
      },
    });

  function imageCheck(str) {
    // console.log(str);
    if (chrome.includes(str)) {
      return chromeIcon;
    } else if (safari.includes(str)) {
      return safariIcon;
    } else if (edge.includes(str)) {
      return edgeIcon;
    } else if (firefox.includes(str)) {
      return fireFoxIcon;
    } else {
      return chromeIcon;
    }
  }

  function osCheck(str) {
    if (windows.includes(str)) {
      return WindowIcon;
    } else if (mac.includes(str)) {
      return MacIcon;
    } else if (linux.includes(str)) {
      return LinuxIcon;
    } else {
      return WindowIcon;
    }
  }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAuthLogData());
    dispatch(getProfileDetails());
    setAuthLog(authLogs);
    setProfile(profileData);
  }, [dispatch]);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  // console.log("DataPerPage", dataPerPage);
  // console.log("CurrentPage", currentPage);

  const columns = ["Login Via", "Login Time", "Browser", "IP Address", "OS"];
  const rows = authLogs?.data?.map((data, index) => [
    data?.login_type,
    <Tools
      data={moment(data?.created_at).fromNow()}
      title={moment(data?.created_at).format("DD MMM YYYY hh:mm:ss A")}
    />,

    // data?.browser,
    imageCheck(data?.browser)!==null?<img className={classes.browserIcon} src={imageCheck(data?.browser)} />:'-',
    data?.ip,
    <div>
      <img
        className={classes.browserIcon}
        style={{ marginLeft: "0px" }}
        src={osCheck(data?.os)}
      />
    </div>,
    // data?.os,
  ]);
  //
  const indexOfLastPost = currentPage * dataPerPage;
  const indexOfFirstPost = indexOfLastPost - dataPerPage;
  // console.log("First", indexOfFirstPost);
  // console.log("Last", indexOfLastPost);

  const currentData = authLogs?.data?.slice(indexOfFirstPost, indexOfLastPost);
  // console.log("AuthLog", authLogs?.data?.slice(0, 10));
  // console.log(authLogs?.data?.slice(indexOfFirstPost, indexOfLastPost));
  // console.log("CurrentrData", currentData);

  const sortAuth = authLogs?.data?.sort((a, b) => {
    let date_a = moment(a?.created_at?.slice(0, 10)).format("YYYYMMDD");
    let date_b = moment(b?.created_at?.slice(0, 10)).format("YYYYMMDD");

    return date_b - date_a;
  });
  const capitalizeLetter = (string) => {
    const lower = string.toLowerCase();
    return string.charAt(0).toUpperCase() + lower.slice(1);
  };

  // useEffect(() => {
  //   setErrorMesg(errormsg);
  // }, [errormsg]);
  return (
    <>
      <Snackbar
        open={!!errorMesg}
        autoHideDuration={3000}
        onClose={() => setErrorMesg(null)}
      >
        <Alert onClose={() => setErrorMesg(null)} severity="error">
          {statusCode}&nbsp;
          {errorMesg}
        </Alert>
      </Snackbar>
      {authLogs && profileData ? (
        <Box>
          {/* <Grid container justify="center">
            <Box my={3} display="flex" alignItems="flex-end">
              <LockOpen /> &nbsp;&nbsp;&nbsp;
              <Typography variant="caption">
                Activity on this account
              </Typography>
            </Box>
          </Grid> */}

          <MuiThemeProvider theme={getMuiTheme()}>
            <Typography variant={"h4"} className={classes.customHeading}>
              <FeaturedPlayListRounded className={classes.customIcon} />
              Authentication Logs
            </Typography>
            <Paper className={classes.paper} variant="outlined" elevation={0}>
              <>
                <MUIDataTable
                  title={`Logs Info`}
                  data={rows}
                  columns={columns}
                  options={{
                    selectableRows: false, // <===== will turn off checkboxes in rows
                  }}
                />
                {/* <Data />
              <Grid container justify="center">
                <Pagination
                  page={currentPage}
                  count={dataPerPage}
                  onChange={(e, value) => setCurrentPage(value)}
                />
              </Grid> */}
              </>
            </Paper>
          </MuiThemeProvider>
        </Box>
      ) : (
        <div className={classes.rootLinear}>
          <LinearProgress />
        </div>
      )}
    </>
  );
}
