import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { authActions } from "auth";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import Billing from "views/pages/account/billing";
import Department from "views/pages/account/department";
import Logs from "views/pages/account/Logs";
import Profile from "views/pages/account/Profile";
import Settings from "views/pages/account/settings";
import CreateDocument from "views/pages/createDocument";
import CreateTemplate from "views/pages/createTemplate";
import MyDesk from "views/pages/my-desk";
import MyDocuments from "views/pages/my-documents";
import DocumentDetails from "../pages/my-documents/DocumentDetails";
import MyFolders from "views/pages/my-folders/MyFolders";
import RootFolder from "views/pages/my-folders/RootFolder";
import MyStamps from "views/pages/my-stamps";
import MyTemplates from "views/pages/my-templates";
import RequireAuthRoute from "../components/require-auth-route";
import RequireUnauthRoute from "../components/require-unauth-route";
import Sidenav from "../containers/sidenav";
import Login from "../pages/auth/Login";
import SignUp from "../pages/auth/SignUp";
import DashboardComponent from "../pages/home";
import Landing from "../pages/landing";
import { clientActions } from "getDetails";
import { Link, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import AuthenticationLogs from "views/pages/auth/AuthenticationLogs";
import Plan from "views/pages/account/Plan";
import TokenList from "views/pages/tokenList/tokenList";
import {Helmet} from "react-helmet"
import ErrorPage from "views/pages/Error/error";
import BackgroundHexagon from "assets/blue-pattern.svg";
// import BackgroundHexagon from "assets/medical.jpg"

//  Global material ui theme wrapper
const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#0084ec",
      main: "#4263eb",
      dark: "#0084ec",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#e91e63",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
  // typography: {
  //   useNextVariants: true
  // }

  overrides: {
    "& .MuiModal-root": {
      filter: "blur(100px)",
    },
  },
});

const App = (props) => {
  // const [planId, setPlanId] = useState("");
  // const [checkoutData, setCheckoutData] = useState([]);
  const search = useLocation().search;

  // console.log(query);
  const [token, setToken] = useState("");
  // useEffect(()=>{
  //
  //   const script = document.createElement("script");
  //
  //   script.src = "https://js.chargebee.com/v2/chargebee.js";
  //   script.setAttribute("data-cb-domain",`https://${props.chargebeeDetails?.data?.chargebee_domain}`)
  //   script.async = true;
  //
  //   document.head.appendChild(script);
  //   script.onload=scriptLoader()
  // },[])
  function scriptLoader(){
    // var chargebee=Chargebee.init({
    //   site: this.props.chargebeeDetails?.data?.chargebee_site,
    //   domain: this.props.chargebeeDetails?.data?.chargebee_domain,
    //   publishableKey: this.props.chargebeeDetails?.data?.chargebee_key,
    // });

  }

  useEffect(() => {
    if (token !== props.accessToken) {
      // props.chargebeeDetailsData()
      setToken(props.accessToken);
      setInterval(() => {
        props.refreshToken();
      }, 900000); // refersh token every 800sec
    }
  }, [props.accessToken]);

  // useEffect(()=>{
  //   if(props.chargebeeDetails?.data?.length===0){
  //     props.chargebeeDetailsData()
  //   }
  //
  // },[props.chargebeeDetails?.data?.length])



  // useEffect(()=>{
  //   props.getGoogleClientId()
  // },[])

  // props.authenticated is true when,
  // onboardRequired is false when
  // or past login token is validated

  //  onboardRequired : i.e is new user / sign in required?

  // useEffect(() => {
  //   let ignore = false;
  //   if (!props.tokenLists) {
  //     if (!ignore) {
  //       props.tokenList();
  //     }
  //   }
  //   return () => {
  //     if (props.tokenLists) {
  //       ignore = true;
  //     }
  //   };
  // }, [props.tokenLists]);

  useEffect(() => {
    if (props.modal) {
      window.addEventListener("keydown", (e) => {
        // console.log(e);
        if (e.key == "Escape") {
          props.changeModal(false);
        }
      });
    }
  }, [props.modal]);
useEffect(()=>{
  const name = new URLSearchParams(search).get("plan");
  console.log(name)
  props.getPlanId(name);
},[])

  return (
    <MuiThemeProvider theme={theme}>

{/*      <Helmet>*/}
{/*        <script*/}
{/*            src="https://js.chargebee.com/v2/chargebee.js"*/}
{/*            // data-cb-site={props.chargebeeDetails?.data?.chargebee_site}*/}
{/*async*/}
{/*            // data-cb-domain={props.chargebeeDetails?.data?.chargebee_domain}*/}

{/*        >*/}


{/*        </script>*/}

{/*      </Helmet>*/}
      <main
        style={{
          // backgroundImage: `url("${BackgroundHexagon}")`,
          backgroundColor: "#edf2fe",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >

        <Sidenav authenticated={props.authenticated}>
          <RequireAuthRoute
            authenticated={props.authenticated}
            exact
            path="/home"
            component={DashboardComponent}
          />
          {/* <RequireAuthRoute
            authenticated={props.authenticated}
            exact
            path="**"
            component={ErrorPage}
          /> */}

          <RequireAuthRoute
            authenticated={props.authenticated}
            exact
            path="/desk"
            component={MyDesk}
          />
          <RequireAuthRoute
            authenticated={props.authenticated}
            exact
            path="/documents"
            component={MyDocuments}
          />

          <RequireAuthRoute
            authenticated={props.authenticated}
            exact
            path="/applicant/:clientId"
            component={DocumentDetails}
          />
          <RequireAuthRoute
            authenticated={props.authenticated}
            exact
            path="/profile"
            component={Profile}
          />
          <RequireAuthRoute
            authenticated={props.authenticated}
            exact
            path="/plan"
            component={Plan}
          />
          {/* <RequireAuthRoute
            component={ErrorPage}
            
            

          /> */}

          <RequireAuthRoute
            authenticated={props.authenticated}
            exact
            path="/templates"
            component={MyTemplates}
          />
          <RequireAuthRoute
            authenticated={props.authenticated}
            exact
            path="/api-keys"
            component={TokenList}
          />

          <RequireAuthRoute
            authenticated={props.authenticated}
            exact
            path="/stamps"
            component={MyStamps}
          />
          <RequireAuthRoute
            authenticated={props.authenticated}
            exact
            path="/folders"
            component={RootFolder}
          />
          <RequireAuthRoute
            authenticated={props.authenticated}
            exact
            path="/folders/:id"
            component={MyFolders}
          />

          <RequireAuthRoute
            authenticated={props.authenticated}
            exact
            path="/account"
          >
            <Redirect to="/account/profile"></Redirect>
          </RequireAuthRoute>
          <RequireAuthRoute
            authenticated={props.authenticated}
            exact
            path="/account/profile"
            component={Profile}
          />
          <RequireAuthRoute
            authenticated={props.authenticated}
            exact
            path="/account/settings"
            component={Settings}
          />
          <RequireAuthRoute
            authenticated={props.authenticated}
            exact
            path="/account/billing"
            component={Billing}
          />
          <RequireAuthRoute
            authenticated={props.authenticated}
            exact
            path="/authentication_logs"
            component={AuthenticationLogs}
          />
          <RequireAuthRoute
            authenticated={props.authenticated}
            exact
            path="/billing"
            component={Billing}
          />
          <RequireAuthRoute
            authenticated={props.authenticated}
            exact
            path="/account/department"
            component={Department}
          />
          <RequireAuthRoute
            authenticated={props.authenticated}
            exact
            path="/account/activity-logs"
            component={Logs}
          />

          <RequireAuthRoute
            authenticated={props.authenticated}
            exact
            path="/document"
          >
            <Redirect to="/document/create"></Redirect>
          </RequireAuthRoute>
          <RequireAuthRoute
            authenticated={props.authenticated}
            exact
            path="/document/create"
            component={CreateDocument}
          />
          <RequireAuthRoute
            authenticated={props.authenticated}
            exact
            path="/document/create-template"
            component={CreateTemplate}
          />

          <RequireUnauthRoute
            authenticated={props.authenticated}
            exact
            path="/sign-in"
            component={Landing}
          />

          <RequireUnauthRoute
            authenticated={props.authenticated}
            exact
            path="/"
            component={Login}
          />
          {/* <RequireUnauthRoute
            authenticated={props.authenticated}
            exact
            path="**"
            component={ErrorPage}
          /> */}
          <RequireUnauthRoute
            authenticated={props.authenticated}
            exact
            path="/get-onboard"
            component={SignUp}
          />
          {/* <RequireAuthRoute
            exact={true}
            path="*"
            component={ErrorPage}
            

          /> */}
        </Sidenav>
      </main>
    </MuiThemeProvider>
  );
};
App.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  signOut: PropTypes.func.isRequired,
};

//=====================================
//  CONNECT
//-------------------------------------

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
  onboardRequired: state.auth.onboardRequired,
  accessToken: state.auth.accessToken,
  tokenLists: state.auth.tokenLists,
  modal: state.modalRed.openModal,
  checkout: state.clientData.checkout,
  chargebeeDetails:state.auth.chargebeeDetails,
  planDetail: state.clientData.planDetail,
});

const mapDispatchToProps = {
  signOut: authActions.signOut,
  refreshToken: authActions.refreshToken,
  tokenList: authActions.tokenList,
  changeModal: authActions.changeModal,
  getProductList: clientActions.getProductList,
  checkoutPlan: clientActions.checkOut,
  getPlanId: clientActions.getPlanID,
  chargebeeDetailsData:authActions.getChargebeeDetails,
  getGoogleClientId:authActions.getGoogleClient,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
