import { Grid, IconButton, Paper, StepConnector } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  CheckRounded,
  CloseRounded,
  DoneOutlineRounded,
  GroupAdd,
  NoteAddRounded,
  PlaylistAddCheckRounded,
} from "@material-ui/icons";
import Logo from "assets/logo.png";
import classNames from "classnames";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Create from "./Create/Create";
import Finish from "./Finish";
import Invite from "./Invite/Invite";
import Success from "./Success";
import Typography from "@material-ui/core/Typography";
import CodeIcon from "@material-ui/icons/Code";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    width: "100%",
    height: "100%",
    gridTemplateRows: "auto 1fr auto",
    gridTemplateColumns: "1fr",
  },
  StepperRoot: {
    padding: 0,
  },
  button: {
    marginRight: theme.spacing(4),
  },
  offset: theme.mixins.toolbar,
  cowinBold: {
    color: "#3f51b5",
  },
}));

const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: "2.5rem",
    height: "2.5rem",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;",
  },
  completed: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const ColorlibConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  completed: {
    "& $line": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  line: {
    height: 2,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
}))(StepConnector);

function getSteps() {
  return ["Create", "Invite", "Finalize"];
}

function getStepContent(step, setActiveStep) {
  switch (step) {
    case 0:
      return <Create setActiveStep={setActiveStep} />;
    case 1:
      return <Invite setActiveStep={setActiveStep} />;
    case 2:
      return <Finish setActiveStep={setActiveStep} />;
    case 3:
      return <Success />;
  }
}

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <NoteAddRounded fontSize="small" />,
    2: <GroupAdd fontSize="small" />,
    3: <PlaylistAddCheckRounded fontSize="small" />,
  };

  return (
    <div
      className={classNames(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed ? <CheckRounded /> : <>{icons[String(props.icon)]}</>}
    </div>
  );
}

function CreateDocument() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  const steps = getSteps();

  return (
    <div className={classes.root}>
      <Paper square>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "4rem" }}
        >
          <Grid container item xs={2} justifyContent="center" pt={4}>
            <Link to="/">
              {/*<img src={Logo} style={{ padding: 4, height: "3.2rem" }} />*/}
              {/* <Typography variant="h5">
                <b>Sure</b>
                <b className={classes.cowinBold}>Cowin</b>
              </Typography> */}
              <Typography
                variant="h5"
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: 1,
                }}
              >
                <b style={{ marginLeft: 10 }}>No</b>
                <CodeIcon style={{ fontSize: "30" }} color={"primary"} />
                <b>Pdf</b>
              </Typography>
            </Link>
          </Grid>
          <Grid
            container
            item
            xs={8}
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant={"h5"}>
              <b>Generate verification link to verify your customer</b>{" "}
            </Typography>
          </Grid>
          <Grid container item xs={2} justifyContent="flex-end">
            <IconButton
              component={Link}
              to="/"
              color="secondary"
              style={{ marginRight: 20 }}
            >
              <CloseRounded />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>

      {getStepContent(activeStep, setActiveStep)}
    </div>
  );
}

export default CreateDocument;
