import React, { useState, useEffect } from "react";
import { Line, Bar, Pie, Doughnut, Radar } from "react-chartjs-2";
import { Grid } from "@material-ui/core";
import moment from "moment";

function LineChart({ apiUsage }) {
  let label = [];
  let data = [];
  let currentDate = "";

  let dataValue = 0;
  let a = 1;
  let array = {
    date: [],
    amount: [],
  };

  let sourceArray = []

  let temp = apiUsage?.data.map(item=>{
    let date = moment(item?.created_at).format("MMMM Do")
    if(currentDate == date){
      let ind = sourceArray.map((it)=>it?.date).indexOf(date)
      sourceArray[ind].amount += item?.amount
    }else{
      currentDate = date
      sourceArray.push({date , amount: item?.amount})
    }
  })




  // console.log(sourceArray);
  for(let item of sourceArray){
    // console.log(item)
    label.push(item?.date)
    data.push(item?.amount)
  }


  const chartData = {
    labels: label.reverse(),
    datasets: [
      {
        label: "Credits",
        data: data.reverse(),
        fill: false,
        borderColor: "rgb(75,192,192)",
        tension: 0.1,
      },
    ],
    options: {
      title: {
        display: true,
        text: "No<>Pdf",
        fontSize: 24,
      },
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "Credits",
            },
          },
        ],
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "Days",
            },
          },
        ],
      },
      layout: {
        padding: {
          left: 10,
          right: 0,
          bottom: 0,
          top: 0,
        },
      },
      tooltips: {
        enabled: false,
      },
    },
  };
  // console.log(chartData?.options);

  return (
    <Grid container style={{ paddingLeft: "20px" }}>
      <Line data={chartData} height={80} options={chartData.options} />
    </Grid>
  );
}

export default React.memo(LineChart);
