import { INIT_SIGN, ADD_SIGNERS } from "./action-types";
import { SIGN_OUT_SUCCESS } from "../auth/action-types";

export function signState() {
  return {
    clientId: "",
    initialized: false,
    signers: [],
  };
}

export function initSignReducer(state = new signState(), { payload, type }) {
  switch (type) {
    case SIGN_OUT_SUCCESS:
      return new signState();
    case INIT_SIGN:
      return {
        ...state,
        clientId: payload.data.client_id,
        initialized: payload.data.initialized,
      };
    case ADD_SIGNERS:
      return {
        ...state,
        signers: payload.data.signers,
      };
    default:
      return state;
  }
}
