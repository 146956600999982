// import React, { useState, useEffect } from "react";
//
// import {
//   Snackbar,
//   Grid,
//   IconButton,
//   Button,
//   MuiThemeProvider,
//   createMuiTheme,
//   CircularProgress,
//   LinearProgress,
//   Paper,
//   Tooltip,
//   Typography,
//   Modal
// } from "@material-ui/core";
//
// import DeleteIcon from "@material-ui/icons/Delete";
//
// import MUIDataTable from "mui-datatables";
// import { Alert } from "@material-ui/lab";
// import { generateToken, tokenList } from "auth/actions";
// import FileCopyIcon from "@material-ui/icons/FileCopy";
// import setAuthorizationToken from "utils/axios-instance";
// import { connect } from "react-redux";
// import { withRouter } from "react-router";
// import moment from "moment";
// import { authActions } from "auth";
// import { makeStyles } from "@material-ui/styles";
// import Tools from "views/components/custom-tootltip/Tools";
// import { VpnKeyRounded } from "@material-ui/icons";
// import DeleteTokenModal from "../modalPage/DeleteTokenModal";
// function getModalStyle() {
//   const top = 50;
//   const left = 50;
//
//   return {
//     top: `${top}%`,
//     left: `${left}%`,
//     transform: `translate(-${top}%, -${left}%)`,
//   };
// }
// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//     margin: "auto",
//     "& > * + *": {
//       marginLeft: 16,
//     },
//   },
//   paper: {
//     width: "90%",
//     margin: "40px auto",
//     boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
//   },
//   customHeading: {
//     color: "#000",
//     display: "flex",
//     margin: "50px 0px 0px 55px !important",
//     alignItems: "center",
//   },
//   customIcon: {
//     fontSize: "35px",
//     marginRight: "20px",
//   },
// }));
//
// const TokenList = (props) => {
//   const modalStyle=useState(getModalStyle)
//   const { loader } = props;
//   const classes = useStyles();
//   const [errormsg, setErrorMsg] = useState("");
//   const [token, setTokenData] = useState([]);
//   const [show, setShow] = useState(false);
//   const getMuiTheme = () =>
//     createMuiTheme({
//       overrides: {
//         MUIDataTableToolbar: {
//           actions: {
//             display: "none",
//           },
//           root: {
//             // backgroundColor: "#0084ec",
//             // color: "#fff",
//           },
//         },
//         MuiTableCell: {
//           root: {
//             padding: "2px",
//           },
//           body: {
//             textAlign: "left",
//             paddingLeft: "30px",
//           },
//         },
//
//         MuiInputBase: {
//           formControl: {
//             "&:hover": {
//               outline: "none",
//             },
//           },
//         },
//         MuiPaper: {
//           root: {
//             width: "100%",
//             margin: "20px auto",
//           },
//           elevation4: {
//             boxShadow: "none",
//           },
//           outlined: {
//             // border: "1px solid #ccc",
//             border: "none",
//             borderRight: "1px solid rgba(224, 224, 224, 1)",
//             borderLeft: "1px solid rgba(224, 224, 224, 1)",
//             borderTop: "1px solid rgba(224, 224, 224, 1)",
//           },
//         },
//         MuiButton: {
//           contained: {
//             backgroundColor: "rgb(63, 81, 181)",
//             color: "#fff",
//             padding: ".5rem",
//
//             fontWeight: "500",
//             fontSize: ".875rem",
//
//             lineHeight: "1",
//             textTransform: "capitalize",
//             "&:hover": {
//               backgroundColor: "rgb(63, 81, 181)",
//             },
//           },
//         },
//         MUIDataTableHeadCell: {
//           contentWrapper: {
//             justifyContent: "flex-start",
//             paddingLeft: "24px",
//           },
//           data: {
//             fontWeight: "700",
//             padding: "0 4px ",
//           },
//         },
//
//         // MuiTableCell: {
//         //   root: {
//         //     "&:last-child": {
//         //       // width: "10ch",
//         //     },
//         //   },
//         // },
//
//       },
//     });
//
//
//   const [successAlert, setSuccessAlert] = useState(false);
//   const [successRevoke, setSuccessRevoke] = useState(false);
//   const [openModal, setOpenModal] = useState(false);
//   const[tokenValue,setTokenValue]=useState("")
//   const [open,setOpen]=useState(false)
//     const columns = ["Name", "Created At", "Token", "Copy", "Delete"];
//     const handleClose = () => {
//
//   }
//   const handleRemove = (e) => {
//     e.preventDefault();
//     setOpen(true);
//
//
//     setOpenModal(false);
//   };
//
//   function truncate(str, n) {
//     // console.log("Icon", icon);
//
//     return (
//       <span style={{ fontWeight: "700" }}>
//         {str?.length > n ? "****" + str.slice(-4) : str}
//       </span>
//     );
//   }
//
//   const copyToClipBoard = async (copyMe) => {
//     try {
//       await navigator.clipboard.writeText(copyMe);
//
//       setSuccessAlert(true);
//     } catch (err) {
//       setSuccessAlert(false);
//     }
//   };
//   const FileCopy = ({ value }) => {
//     return (
//       // <div style={{ position: "relative", right: "7px", display: "flex" }}>
//       <>
//         <IconButton onClick={() => copyToClipBoard(value)}>
//           <FileCopyIcon style={{ color: "#0084ec" }} />
//         </IconButton>
//       </>
//     );
//   };
//   const DeleteComponent = ({ value }) => {
//     return (
//       <IconButton
//         variant="contained"
//         onClick={() => {
//           setTokenValue(value)
//           setOpenModal(true)
//           setAuthorizationToken(value);
//           // props.revokeToken();
//           // setShow(true);
//           // // console.log("accessToken", props.accessToken === data?.token);
//           // setAuthorizationToken(props.accessToken);
//           // setTimeout(() => {
//           //   props.tokenListCall();
//           // }, 500);
//           // // setShow(true)
//
//           // setSuccessRevoke(true);
//         }}
//       >
//         <DeleteIcon style={{ color: " #FE4F4F" }} />
//       </IconButton>
//     );
//   };
//
//
//
//   const rows = props.tokenLists?.map((data, index) => [
//     data?.name,
//
//     <Tools
//       data={moment(data?.created_at).fromNow()}
//       title={moment(data?.created_at).format("DD MMM YYYY hh:mm:ss A")}
//     />,
//     <Tools title={data?.token} data={truncate(data?.token, 20)} />,
//
//     // <FileCopy value={data?.token} />,
//
//     <>
//       <FileCopy value={data?.token} />
//     </>,
//     <DeleteComponent value={data?.token} />,
//   ]);
//
//   useEffect(() => {
//     if (!props.tokenLists) props.tokenListCall();
//
//     // props.tokenListCall();
//     // setTokenData(props.tokenList);
//   }, [props.tokenLists]);
//
//   useEffect(() => {
//     setErrorMsg(props.errorMsg);
//   }, [props.errorMsg]);
//
//   return (
//     <>
//       {/* {show && (!loader ? <LinearProgress /> : null)} */}
//       <Snackbar
//         open={!!errormsg}
//         autoHideDuration={3000}
//         onClose={() => setErrorMsg(null)}
//       >
//         <Alert onClose={() => setErrorMsg(null)} severity="error">
//           {props.statusCode}
//           {errormsg}
//         </Alert>
//       </Snackbar>
//       {loader && successRevoke && !errormsg ? (
//         <Snackbar
//           open={successRevoke}
//           autoHideDuration={3000}
//           onClose={() => setSuccessRevoke(false)}
//         >
//           <Alert onClose={() => setSuccessRevoke(false)} severity="success">
//             Revoke Token Successfully
//           </Alert>
//         </Snackbar>
//       ) : null}
//       <Snackbar
//         open={successAlert}
//         autoHideDuration={3000}
//         onClose={() => setSuccessAlert(false)}
//       >
//         <Alert onClose={() => setSuccessAlert(false)} severity="success">
//           Token Copied
//         </Alert>
//       </Snackbar>
//
//
//
//       {show && (
//         <>
//           {!loader ? (
//             <div className={classes.rootLinear}>
//               <LinearProgress />
//             </div>
//           ) : null}
//         </>
//       )}
//       {props.tokenLists ? (
//
//         <>
//
//           {openModal && (
//             <DeleteTokenModal setOpenModal={setOpenModal} openModal={openModal} tokenValue={tokenValue} setSuccessRevoke={setSuccessRevoke} setShow={setShow}/>
// )}
//           <MuiThemeProvider theme={getMuiTheme()} >
//           <Typography variant={"h4"}
//                       // className={classes.customHeading}
//               style={{color: "#000",
//                 display: "flex",
//                 margin:"50px 0px 0px 55px",
//                 // margin: "50px 0px 0px 55px !important",
//                 alignItems: "center",}}
//           >
//             <VpnKeyRounded
//                 // className={classes.customIcon}
//                 style={{
//                   fontSize: "35px",
//                   marginRight: "20px",
//                 }}
//             />
//             Generated Tokens
//           </Typography>
//           <Paper
//               // className={classes.paper}
//               style={{
//                 width: "90%",
//                 margin: "40px auto",
//                 boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
//               }}
//               variant="outlined" elevation={0}>
//             <MUIDataTable
//               title={`Token List`}
//               data={rows}
//                 columns={columns}
//
//               options={{
//                 selectableRows: false, // <===== will turn off checkboxes in rows
//               }}
//             />
//           </Paper>
//           </MuiThemeProvider>
//           </>
//       ) : (
//         <div className={classes.root}>
//           <LinearProgress />
//         </div>
//       )}
//     </>
//   );
// };
// const mapStateToProps = (state) => ({
//   tokenLists: state.auth.tokenLists,
//   accessToken: state.auth.accessToken,
//   loader: state.auth.loader,
//   errorMsg: state.auth.errorMsg,
//   statusCode: state.auth.statusCode,
// });
//
// const mapDispatchToProps = {
//   generateToken: authActions.generateToken,
//   tokenListCall: authActions.tokenList,
//   revokeToken: authActions.callRevokeToken,
// };
//
// export default withRouter(
//   connect(mapStateToProps, mapDispatchToProps)(TokenList)
// );

import React, { useState, useEffect } from "react";

import {
  Snackbar,
  Grid,
  IconButton,
  Button,
  MuiThemeProvider,
  createMuiTheme,
  CircularProgress,
  LinearProgress,
  Paper,
  Tooltip,
  Typography,
    makeStyles,
  Modal
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";

import MUIDataTable from "mui-datatables";
import { Alert } from "@material-ui/lab";
import { generateToken, tokenList } from "auth/actions";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import setAuthorizationToken from "utils/axios-instance";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from "moment";
import { authActions } from "auth";

import Tools from "views/components/custom-tootltip/Tools";
import { VpnKeyRounded } from "@material-ui/icons";
import DeleteTokenModal from "../modalPage/DeleteTokenModal";
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",

    margin: "auto",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  paper: {
    width: "90%",
    margin: "40px auto",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },
  customHeading: {
    color: "#000",
    display: "flex",
    margin: "50px 0px 0px 55px",
    alignItems: "center",
  },
  customIcon: {
    fontSize: "35px",
    marginRight: "20px",
  },
}));

const TokenList = (props) => {
  const modalStyle=useState(getModalStyle)
  const { loader } = props;
  const classes = useStyles();
  const [errormsg, setErrorMsg] = useState("");
  const [token, setTokenData] = useState([]);
  const [show, setShow] = useState(false);
  const getMuiTheme = () =>
      createMuiTheme({
        overrides: {

          MUIDataTableToolbar: {
            actions: {
              display: 'none'
            },
            root: {
              display: 'none'
              // backgroundColor: "#0084ec",
              // color: "#fff",
            }
          },
          MUIDataTable:{responsiveBase:{
              padding:"23px 0"
            }},
          MuiTableCell: {
            root: {
              padding: '2px'
            },
            body: {
              textAlign: 'left',
              paddingLeft: '30px'
            }
          },
          MuiPaper: {
            root: {
              width: '80%',
              margin: '20px auto'
            }
          },
          MuiInputBase: {
            formControl: {
              '&:hover': {
                outline: 'none'
              }
            }
          },
          MuiPaper: {
            elevation4: {
              boxShadow: 'none'
            },
            outlined: {
              // border: "1px solid #ccc",
              border: 'none',
              borderRight: '1px solid rgba(224, 224, 224, 1)',
              borderLeft: '1px solid rgba(224, 224, 224, 1)',
              borderTop: '1px solid rgba(224, 224, 224, 1)'
            }
          },
          MuiButton: {
            contained: {
              backgroundColor: 'rgb(63, 81, 181)',
              color: '#fff',
              padding: '.5rem',

              fontWeight: '500',
              fontSize: '.875rem',

              lineHeight: '1',
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: 'rgb(63, 81, 181)'
              }
            }
          },
          MUIDataTableHeadCell: {
            contentWrapper: {
              justifyContent: 'flex-start',
              paddingLeft: '24px'
            },
            data: {
              fontWeight: '700',
              padding: '0 4px '
            }
          }
          // MuiTableCell: {
          //   root: {
          //     "&:last-child": {
          //       // width: "10ch",
          //     },
          //   },
          // },
        }
      })

  const [successAlert, setSuccessAlert] = useState(false);
  const [successRevoke, setSuccessRevoke] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const[tokenValue,setTokenValue]=useState("")
  const [open,setOpen]=useState(false)
  const columns = ["Name", "Created At", "API Key", "Copy", "Delete"];
  const handleClose = () => {

  }
  const handleRemove = (e) => {
    e.preventDefault();
    setOpen(true);


    setOpenModal(false);
  };

  function truncate(str, n) {
    // console.log("Icon", icon);

    return (
        <span style={{ fontWeight: "700" }}>
        {str?.length > n ? "****" + str.slice(-4) : str}
      </span>
    );
  }
  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);

      setSuccessAlert(true);
    } catch (err) {
      setSuccessAlert(false);
    }
  };
  const FileCopy = ({ value }) => {
    return (
        // <div style={{ position: "relative", right: "7px", display: "flex" }}>
        <>
          <IconButton onClick={() => copyToClipBoard(value)}>
            <FileCopyIcon style={{ color: "#0084ec" }} />
          </IconButton>
        </>
    );
  };
  const DeleteComponent = ({ value }) => {
    return (
        <IconButton
            variant="contained"
            onClick={() => {
              setTokenValue(value)
              setOpenModal(true)

            }}
            disabled={props.tokenLists.length<=1?true:false  }
        >
          <DeleteIcon style={{ color: `${props.tokenLists.length==1?'disable':"#FE4F4F"}` }} />
        </IconButton>
    );
  };



  const rows = props.tokenLists?.map((data, index) => [
    data?.name,

    <Tools
        data={moment(data?.created_at).fromNow()}
        title={moment(data?.created_at).format("DD MMM YYYY hh:mm:ss A")}
    />,
    <Tools title={data?.api_key} data={truncate(data?.api_key, 20)} />,

    // <FileCopy value={data?.token} />,


      <FileCopy value={data?.api_key} />,
    <DeleteComponent value={data?.api_key} />,
  ]);

  useEffect(() => {
    console.log(props.tokenLists)
    props.tokenListCall()
    console.log(props.tokenLists)
  }, [props.tokenLists.length]);

  useEffect(() => {
    setErrorMsg(props.errorMsg);
  }, [props.errorMsg]);

  return (
      <>
        {/* {show && (!loader ? <LinearProgress /> : null)} */}
        <Snackbar
            open={!!errormsg}
            autoHideDuration={3000}
            onClose={() => setErrorMsg(null)}
        >
          <Alert onClose={() => setErrorMsg(null)} severity="error">
            {props.statusCode}
            {errormsg}
          </Alert>
        </Snackbar>
        {loader && successRevoke && !errormsg ? (
            <Snackbar
                open={successRevoke}
                autoHideDuration={3000}
                onClose={() => setSuccessRevoke(false)}
            >
              <Alert onClose={() => setSuccessRevoke(false)} severity="success">
                Revoke Token Successfully
              </Alert>
            </Snackbar>
        ) : null}
        <Snackbar
            open={successAlert}
            autoHideDuration={3000}
            onClose={() => setSuccessAlert(false)}
        >
          <Alert onClose={() => setSuccessAlert(false)} severity="success">
            Copied
          </Alert>
        </Snackbar>



        {show && (
            <>
              {!loader ? (
                  <div className={classes.rootLinear}>
                    <LinearProgress />
                  </div>
              ) : null}
            </>
        )}
        {props.tokenLists ? (

            <>

              {openModal && (
                  <DeleteTokenModal setOpenModal={setOpenModal} openModal={openModal} tokenValue={tokenValue} setSuccessRevoke={setSuccessRevoke} setShow={setShow}/>
              )}
              <MuiThemeProvider theme={getMuiTheme()}>
                <Typography variant={"h4"} className={classes.customHeading}>
                  <VpnKeyRounded className={classes.customIcon} />
                  API Keys
                </Typography>
                <Paper className={classes.paper} variant="outlined" elevation={0}>
                  <MUIDataTable

                      data={rows}
                      columns={columns}

                      options={{
                        selectableRows: false, // <===== will turn off checkboxes in rows
                      }}
                  />
                </Paper>
              </MuiThemeProvider>
            </>
        ) : (
            <div className={classes.root}>
              <LinearProgress />
            </div>
        )}
      </>
  );
};
const mapStateToProps = (state) => ({
  tokenLists: state.auth.tokenLists,
  accessToken: state.auth.accessToken,
  loader: state.auth.loader,
  errorMsg: state.auth.errorMsg,
  statusCode: state.auth.statusCode,
});

const mapDispatchToProps = {
  generateToken: authActions.generateToken,
  tokenListCall: authActions.tokenList,
  revokeToken: authActions.callRevokeToken,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(TokenList)
);
