import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button ,Grid,Typography,Paper} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { useSelector } from "react-redux";

import moment from "moment";
import ScheduleCancelModal from "../modalPage/cancelSchedule";
import CancleChangeModal from "../modalPage/cancelChangePlan";
import { Divider } from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';

const styles = (theme) => ({
  snackbar: {
    // margin: theme.spacing.unit,
    // backgroundColor: "#4263eb",
    backgroundColor: "#fff",
    color: "#111",
  },
  paper: {
    width: "96.6%",
    margin: " 10px auto 0 auto",
    boxShadow:
              "0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
    
  },
  box: {
    padding:"16px 0px"
  },
  text: {
    fontSize: "14px",
    marginLeft:"10px"
  },
  divider: {
    background: "red",
    height:"4px"
  }
  
  
});

function NotificationBar(props) {
  //   console.log("Props", props);
  //   console.log(action1);

  const [openCancelChangeModal, setOpenCancelChangeModal] = useState(false);
  const [openCancelSchedule, setOpenCancelSchedule] = useState(false);
  const { classes } = props;
  const handleCancelChange = (e) => {
    setOpenCancelChangeModal(true);
  };
  const handleRemoveCancel = (e) => {
    setOpenCancelSchedule(true);
  };
  const handleClose = () => {
    setOpenCancelChangeModal(false);
    setOpenCancelSchedule(false);
  };
  const Button1 = ()=>(
    <Button
      // color="secondary"
      style={{
        color: "#fff",
        background: "red",
        fontWeight: "500",
        marginLeft: "1rem",
        textTransform:"capitalize",
        //   fontSize: ".875rem",
        "&:hover": {
          backgroundColor: "red",
        },
      }}
      size="small"
      variant="contained"
      onClick={handleRemoveCancel}
    >
      Remove 
    </Button>
  );

  const Button2 = ()=>(
    <Button
      // color="secondary"
      style={{
        color: "#fff",
        background: "red",
        marginLeft:"1rem",
        fontWeight: "500",
        //   fontSize: ".875rem",
        textTransform:"capitalize",
        "&:hover": {
          backgroundColor: "red",
        },
      }}
      size="small"
      variant="contained"
      onClick={handleCancelChange}
    >
      Remove
    </Button>
  );

  const { currentPlan, planDetail } = useSelector((state) => state.clientData);
  let currentPlanName = planDetail?.plans?.filter((plan) =>
    plan?.id === currentPlan?.data?.plan_id ? plan?.name : null
  );
  let changePlanName = planDetail?.plans?.filter((plan) =>
    plan?.id === currentPlan?.data?.scheduled_plan_id ? plan?.name : null
  );

  let cancelChangePlanId = currentPlan?.data?.scheduled_plan_id;

  let scheduleCancelPlanId = currentPlan?.data?.plan_id;

  return (
    <>
      
      <Paper  className={classes.paper}>
        <Divider className={ classes.divider}/>
        
        {currentPlan?.data?.change_scheduled === true && (

          <Grid container justifyContent="space-between" alignItems="center" className={classes.box} >
            <Grid container item xs={10} style={{paddingLeft:"10px"}} alignItems="center">

              <InfoIcon color="error" className={ classes.icon}/>
            <Typography variant="subtitle" className={classes.text}>
                Your plan will automatically change from <strong>
                  
              {currentPlanName?.[0]?.name}</strong> to <strong>{changePlanName?.[0]?.name}</strong> at the end of the <strong>{currentPlan?.data?.expiry &&
                moment(currentPlan?.data?.expiry).format("DD MMM YYYY")
                }</strong>
            </Typography>
            </Grid>
            <Grid item xs={2} >

            <Button2 />
            </Grid>

            

          </Grid>
        )}
        {currentPlan?.data?.cancelled === true && (
          <>
            <Grid container justifyContent="space-between" alignItems="center" className={classes.box}>
              <Grid container item xs={10} style={{paddingLeft:"10px"}} alignItems="center">
                <InfoIcon color="error" className={classes.icon} />
                

            <Typography variant="subtitle" className={classes.text}>
            Your <strong>{
              currentPlanName?.[0]?.name
            }</strong> plan will automatically cancel on <strong>{
              currentPlan?.data?.expiry &&
              moment(currentPlan?.data?.expiry).format("DD MMM YYYY")
            }</strong>
            </Typography>
              </Grid>
              <Grid item xs={2}>
                
            <Button1 />
</Grid>
          </Grid>
          </>
          
        )}
      </Paper>
      <ScheduleCancelModal
        handleClose={handleClose}
        openModal={openCancelSchedule}
        setOpenModal={setOpenCancelSchedule}
        planId={scheduleCancelPlanId}
        // classes={classes}
      />
      <CancleChangeModal
        handleClose={handleClose}
        openModal={openCancelChangeModal}
        setOpenModal={setOpenCancelChangeModal}
        planId={cancelChangePlanId}
        // classes={classes}
      />
    </>
  );
}

NotificationBar.propTypes = {
  classes: PropTypes.object.isRequired,
  action1: PropTypes.func.isRequired,
  action2: PropTypes.func.isRequired,
};

export default withStyles(styles)(NotificationBar);
