import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";

import {
  Grid,
  Typography,
  Button,
  Container,
  Grow,
  Box,
  Paper,
  Divider,
  IconButton,
  Snackbar,
  CircularProgress,
  Modal,
  Chip,
  Tabs,
  Tab,
  AppBar, TextField, InputAdornment, Collapse,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import AddIcon from "@material-ui/icons/Add";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useDispatch, useSelector } from "react-redux";
import LinearProgressBar from "./ProgressBar";
import moment from "moment";
import {
  cancelSubscriptionPlan,
  checkOut,
  getApiUsageDetail,
  getCurrentPlanDetails,
  getPlanID,
  getProfileDetails,
  setInitialCheckout,
} from "getDetails/actions";
import {
  cancelModal,
  clearError,
  successModal,
  tokenList,
  changeModal,
} from "auth/actions";
import {Check, Clear, Lock} from "@material-ui/icons";
import Plan from "../account/Plan";

import LineChart from "./Charts/charts";

import { FileCopyOutlined } from "@material-ui/icons";
import ModalBody from "../modalPage/cancelModal";
import { CANCEL_SCHEDULE_MODAL } from "auth";
import ScheduleCancelModal from "../modalPage/cancelSchedule";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { CheckCircle } from "@material-ui/icons";
import classNames from "classnames";
import NotificationBar from "./NotificationBar";
import Body from "views/containers/sidenav/Body";
import {setDemoAPIToken} from "../../../utils/axios-instance";
import GenerateTokenNotification from "../../containers/sidenav/generateTokenNotification";
const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: "800",
    fontSize: "1.2rem",
    paddingBottom: theme.spacing(0.5),
    fontFamily: "Baloo Chettan 2, cursive",
  },
  linkContainer: {
    backgroundColor: "#f3f3f329",
    padding: "0 .25rem",
    borderRadius: "10px",
  },
  box: {
    // padding: theme.spacing(1),
    // paddingTop: theme.spacing(2),
    // paddingLeft: theme.spacing(3.5),
    padding: "1px 0px 0px 20px",
  },
  button: {
    backgroundColor: "#00D97C",
    color: "#fff",

    textTransform: "capitalize",
    fontWeight: "600",
    transition: "all .2s ease-in",

    "&:hover": {
      backgroundColor: "#00D97C",
      transform: "scale(1.02)",
    },
  },
  span: {
    color: "#abb2bf !important",
    fontSize: "16px",
    fontFamily: "Consolas, monaco, monospace !important",
  },
  span2: {
    color: "#94e15c !important",
    fontSize: "16px",
    fontFamily: "Consolas, monaco, monospace !important",
  },
  btn1: {
    padding: "10px",
    width: "80%",
    maxWidth: "150px",
    boxShadow: "0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
    marginTop: "8px",
    borderRadius: "8px",
    textTransform: "capitalize",
    textAlign: "justify",
    // borderColor: "beige",
    fontWeight: "600",

    transition: "all .2s ease",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: "#fff",
      borderColor: "transparent",
    },
  },
  btn2: {
    padding: "10px",
    width: "80%",
    maxWidth: "150px",
    boxShadow: "0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
    marginTop: "8px",
    borderRadius: "8px",
    textTransform: "capitalize",
    textAlign: "justify",
    // borderColor: "beige",
    fontWeight: "600",
    color: "#DF2E2E",
    border: "1px solid #DF2E2E",
    backgroundColor: "#fff",
    transition: "all .2s ease",
    "&:hover": {
      backgroundColor: "#DF2E2E",
      color: "#fff",
      borderColor: "transparent",
    },
  },
  btn3: {
    padding: "10px",
    width: "80%",
    boxShadow: "0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
    marginTop: "8px",
    borderRadius: "8px",
    textTransform: "capitalize",
    textAlign: "justify",
    // borderColor: "beige",
    color: "#111",
    fontWeight: "600",

    transition: "all .2s ease",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      borderColor: "transparent",
    },
  },
  copy: {
    color: "#abb2bf !important",
    marginRight: "1rem",

    "&:hover": {
      cursor: "pointer",
    },
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,

    boxShadow: theme.shadows[5],
    padding: "24px 0px 24px 0px",
  },
  paper1: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    outline: "none",
    boxShadow: theme.shadows[5],
    padding: "24px 45px 20px",
  },
  modal: {
    backdropFilter: "blur(1px)",
  },
  paper2: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,

    boxShadow: theme.shadows[5],
    height: "50%",
  },
  checkCircleIcon: {
    color: "#00D97C",
    marginRight: "10px",
  },
  activeSubs: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  codeHeader: {
    marginTop: "12px",
    marginLeft: "4px",
    marginBottom: "10px",
  },
  point: {
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    marginLeft: "6px",
  },
  yellowPoint: {
    backgroundColor: "#ffb700",
  },
  redPoint: {
    backgroundColor: "#ff4040",
  },
  greenPoint: {
    backgroundColor: "#00ae55",
  },
  linkButton: {
    color: "#111",
    textTransform: "capitalize",
    // letterSpacing: "1px",
  },
  link: {
    textDecoration: "underline",
    textTransform: "capitalize",
    color: "#4263eb",
    fontSize: ".975rem",
    fontWeight: "600",
    "&:hover": {
      textDecoration: "underline",
      background: "rgba(66, 99, 235, 0.09)",
    },
    // letterSpacing: "1px",
  },
  pdfLink:{
    textDecoration: "underline",
    width:"80%",
    textOverflow:"ellipsis",
    color: "#4263eb",
    fontSize: ".975rem",
    fontWeight: "600",
    "&:hover": {
      textDecoration: "underline",
      background: "rgba(66, 99, 235, 0.09)",
    },
  },
sendButton:{
    "&:hover":{
      backgroundColor:"#fff",
      color:"#4263eb",
      border:"1px solid #4263eb"
    }
},
  shadowBackground: {
    background: "#fff",
    margin: "40px 20px",
    padding: "20px",
    boxShadow: "0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
    borderRadius: "8px",
    border: "1px solid #ccc",
    overFlow:"auto"
  },
}));
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}



const Dashboard = ({props, handleOpenModal}) => {
  const dispatch = useDispatch();
  
  const {
    apiUsage,
    currentPlan,

    planDetail,

    errormsg,
    statusCode,
  } = useSelector((state) => state.clientData);
  const { successModalOpen } = useSelector((state) => state.modalRed);
  const { cancelSubscription } = useSelector((state) => state.planReducer);
  // console.log(cancelSubscription);
  const [modalStyle] = useState(getModalStyle);



  const [id, setId] = useState(0);
  const [langValue, setLangValue] = useState(0);
  const { accessToken, tokenLists, authenticated } = useSelector(
    (state) => state.auth
  );
  const { cancelModalState } = useSelector((state) => state.modalRed);
  const [successAlert, setSuccessAlert] = useState(false);
const [notificationModal,setNotificationModal]=useState(false)
  const [openCancelSchedule, setOpenCancelSchedule] = useState(false);
  const { openModal } = useSelector((state) => state.modalRed);
  // console.log(planDetail)
  const currentPlanName = planDetail?.plans?.filter(plan => currentPlan?.data?.plan_id === plan?.id ? plan?.name : null);
  // console.log("CurrentPlanName",currentPlanName)


  const array = [
    {
      0:`curl --location --request POST 'https://api.nocodepdf.com/api/v1/templates/render'
--header 'Authorization: Bearer ${tokenLists?.[0]?.token}'
--header 'Content-Type: application/json'
--data-raw '{
"type": "generate_output",
"url": "https://example.com",
"pdf_render_options": \'{
  "output": "pdf",
  "full_page": false,
  "format": "A4",
  "margin_top": 10,
  "margin_left": 10,
  "margin_right": 10
  }'
}'` ,
      1:`const https = require('https');
const options = JSON.stringify({
    'type': 'generate_output',
    'url': 'https://example.com/',
    'pdf_render_options': {
        'output': "pdf",
        'full_page': false,
        'format': "A4",
        'margin_top': 10,
        'margin_bottom': 10,
        'margin_left': 10,
        'margin_right': 10
    }
});
const headers = {
    'Authorization': 'Bearer ${tokenLists?.[0]?.token}',
    'Content-Type': 'application/json',
    'Content-Length': Buffer.byteLength(options),
};
function callback(res) {
    res.on('data', (d) => {
        console.log(d.toString());
    })
}
const req = https.request({
    hostname: 'api.nocodepdf.com',
    port: 443,
    path: '/api/v1/templates/render',
    method: 'POST',
    headers: headers,
}, callback);
req.write(options);
req.end();
`,
      2: `import requests
headers = {
    'Authorization': "Bearer ${tokenLists?.[0]?.token}",
}
options = {
    "type": "generate_output",
    "url": "https://example.com/",
    "pdf_render_options": {
        "output": "pdf",
        "full_page": False,
        "format": "A4",
        "margin_top": 10,
        "margin_bottom": 10,
        "margin_left": 10,
        "margin_right": 10
    }
}
response = requests.post("https://api.nocodepdf.com/api/v1/templates/render", headers=headers, json=options)
print(response.content)
`,
      3:`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
  CURLOPT_URL => 'https://api.nocodepdf.com/api/v1/templates/render',
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => '',
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 0,
  CURLOPT_FOLLOWLOCATION => true,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => 'POST',
  CURLOPT_POSTFIELDS =>'{
    "type": "generate_output",
    "url": "https://example.com/",
    "pdf_render_options": {
        "output": "pdf",
        "full_page": false,
        "format": "A4",
        "margin_top": 10,
        "margin_bottom": 10,
        "margin_left": 10,
        "margin_right": 10
    }
}',
  CURLOPT_HTTPHEADER => array(
    'Authorization: Bearer ${tokenLists?.[0]?.token}',
    'Content-Type: application/json'
  ),
));
$response = curl_exec($curl);
curl_close($curl);
echo $response;
`,
      4:`require "uri"
require "json"
require "net/http"
url = URI("https://api.nocodepdf.com/api/v1/templates/render")
https = Net::HTTP.new(url.host, url.port)
https.use_ssl = true
request = Net::HTTP::Post.new(url)
request["Authorization"] = "Bearer ${tokenLists?.[0]?.token}"
request["Content-Type"] = "application/json"
request.body = JSON.dump({
  "type": "generate_output",
  "url": "https://example.com/",
  "pdf_render_options": {
    "output": "pdf",
    "full_page": false,
    "format": "A4",
    "margin_top": 10,
    "margin_bottom": 10,
    "margin_left": 10,
    "margin_right": 10
  }
})
response = https.request(request)
puts response.read_body
`,
      5:`import java.io.IOException;
import java.io.InputStream;
import java.io.OutputStreamWriter;
import java.net.HttpURLConnection;
import java.net.URL;
import java.util.Scanner;
class Main {
    public static void main(String[] args) throws IOException {
        URL url = new URL("https://api.nocodepdf.com/api/v1/templates/render");
        HttpURLConnection httpConn = (HttpURLConnection) url.openConnection();
        httpConn.setRequestMethod("POST");
        httpConn.setRequestProperty("Authorization", "Bearer ${tokenLists?.[0]?.token}");
        httpConn.setRequestProperty("Content-Type", "application/json");
        httpConn.setDoOutput(true);
        OutputStreamWriter writer = new OutputStreamWriter(httpConn.getOutputStream());
        writer.write("{\"type\":\"generate_output\",\"url\":\"https://example.com/\",\"pdf_render_options\":\n{\"output\":\"pdf\",\"full_page\":false,\"format\":\"A4\",\"margin_top\":10,\"margin_bottom\":10,\"margin_left\":10,\n\"margin_right\":10}}");
        writer.flush();
        writer.close();
        httpConn.getOutputStream().close();
        InputStream responseStream = httpConn.getResponseCode() / 100 == 2
                ? httpConn.getInputStream()
                : httpConn.getErrorStream();
        Scanner s = new Scanner(responseStream).useDelimiter("\\A");
        String response = s.hasNext() ? s.next() : "";
        System.out.println(response);
    }
}
`,
    },
  ];


  // const [openModal, setOpenModal] = useState(false);
  const [generateModal, setGenerateModal] = useState(false);
  const [errorMesg, setErrorMesg] = useState("");
  const [urlForTesting , setUrlForTesting] = useState("")
  function truncate(str, n) {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  }

  // console.log(
  //   `APi-Usage: ${apiUsage?.data}, CurrentPlan: ${currentPlan?.data}`
  // );

  const classes = useStyles();
  // const removeCancellationScheduled = () => {
  //   // dispatch({
  //   //   type: CANCEL_SCHEDULE_MODAL,
  //   //   payload: true,
  //   // });

  //   setOpenCancelSchedule(true);
  // };

  const handleClose = () => {
    // setOpenModal(false);
    // setOpenCancelSchedule(false);
  };
  const handleCloseModal = () => {
    setGenerateModal(false);
  };

  const CustomTabs = withStyles({
    root: {
      // borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
      background: "none",
    },
  })(Tabs);

  const CustomItemTab = withStyles((theme) => ({
    root: {
      textTransform: "none",

      minWidth: 72,
      fontWeight: theme.typography.fontWeightBold,
      marginRight: theme.spacing(2),
      color: "#0e141a",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:hover": {
        color: "#4263eb",
        fontWeight: theme.typography.fontWeightBold,
        opacity: 1,
        background: "#edf2fe",
        borderRadius: "18px",
      },
      "&$selected": {
        color: "#4263eb",
        fontWeight: theme.typography.fontWeightBold,
        background: "#edf2fe",
        borderRadius: "18px",
      },
      "&:focus": {
        color: "#4263eb",
        fontWeight: theme.typography.fontWeightBold,
        background: "#edf2fe",
        borderRadius: "18px",
      },
    },
    selected: {},
  }))((props) => <Tab {...props} />);

  const copyToClipBoard = async (copyMe, a) => {
    // console.log("CopyMe", copyMe);
    try {
      await navigator.clipboard.writeText(copyMe);
      setSuccessAlert(true);
    } catch (err) {
      setSuccessAlert(false);
    }
  };

  const handleCancelation = (e) => {
    e.preventDefault();
    dispatch(cancelModal(true));
    dispatch(cancelSubscriptionPlan());

    // setOpenModal(false);
    setTimeout(() => {
      dispatch(getCurrentPlanDetails());
    }, 400);
  };

  const handleModal = (e) => {
    e.preventDefault();
    // setOpenModal(true);
    // dispatch(cancelModal(true));
  };
  const handleGenerateTokenModal = () => {
    // setGenerateModal(true);
    dispatch(changeModal(true));
  };
  // console.log(tokenLists)

  useEffect(() => {
    setErrorMesg(errormsg);
  }, [errormsg]);

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      // console.log("SuccessMOdal", successModalOpen);
      if (successModalOpen) {
        if (!currentPlan?.data?.plan_id)
          setTimeout(() => dispatch(getCurrentPlanDetails()), 3000);
      }
    }

    return () => {
      if (currentPlan?.data?.plan_id !== null) {
        // console.log("Ignore", ignore);
        dispatch(successModal(false));
        ignore = true;
        // console.log("Current ", currentPlan?.data);
      }
    };
  }, [currentPlan]);

  useEffect(() => {
    if (notificationModal) {
      window.addEventListener("keydown", (e) => {
        // console.log(e);
        if (e.key == "Escape") {
          setNotificationModal(false);
        }
      });
    }
  }, [notificationModal]);

  const handleCodeChange = (event, newValue) => {
    setLangValue(newValue);
    // console.log(newValue);
  };
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Paper
            variant="elevation"
            style={{
              padding: "1px",
              marginTop: "20px",
              width: "100%",
              background: "#0e141a",
              borderRadius: "6px",
              color: "#ffff",
            }}
          >
            <CodeHeader />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {children}
              {tokenLists && (
                <FileCopyOutlined
                  onClick={() => {
                    let a = array.map((datas) => {
                      Object.keys(datas).map((data) => {
                        // console.log("langValue", typeof langValue);
                        // console.log("data", typeof data);
                        if (Number(data) === langValue) {
                          // console.log(datas[0]);
                          copyToClipBoard(datas[data]);
                        }
                      });
                    });

                    setId(2);
                  }}
                  className={classes.copy}
                />
              )}
            </div>
          </Paper>
        )}
      </div>
    );
  };

  const CodeHeader = () => (
    <Grid container className={classes.codeHeader}>
      <Grid item className={classNames(classes.point, classes.redPoint)} />
      <Grid item className={classNames(classes.point, classes.yellowPoint)} />
      <Grid item className={classNames(classes.point, classes.greenPoint)} />
    </Grid>
  );

  useEffect(() => {
    let ignore = false;
    // dispatch(getCurrentPlanDetails());
    if (!ignore) {
      if (cancelModalState) {
        if (!currentPlan?.data?.cancelled) {
          setTimeout(() => dispatch(getCurrentPlanDetails()), 2000);
        }
      }
      return () => {
        if (currentPlan?.data?.cancelled === true) {
          // console.log("CurrentPlan", ignore);

          ignore = true;
        }
      };
    }
  }, [currentPlan]);

  // console.log("CurrentPlan", currentPlan);
const [urlForTestingError,setUrlForTestingError]=useState(false)
  const sendDemoAPIRequest=(e)=>{
    e.preventDefault()
    // if (!currentPlan?.data?.plan_id ) {
    //   dispatch(changeModal(true));
    // }
    if(tokenLists?.length==0){
      // dispatch(changeModal(true))
      setNotificationModal(true)
    }
    else if(urlForTesting !== "" && urlForTesting !== null){
      setUrlForTestingError(false)
      let urlTesting = urlForTesting.includes("https") || urlForTesting.includes("http")

      let obj = {
        type : "generate_output",
        url: urlTesting ? urlForTesting : `https://${urlForTesting}`,
        pdf_render_options:{
          output: "pdf",
          full_page: false,
          format: "A4",
          margin_top: 10,
          margin_left: 10,
          margin_right: 10
        }
      }
      // console.log(obj)
      // console.log(tokenLists)
      setDemoAPIToken(tokenLists?.[0]?.token)
      props.sendDemoApiRequest(obj)
    }
    else {
      setUrlForTestingError(true)
    }
  }

  return (
    <>
      <Snackbar
        open={!!errorMesg}
        autoHideDuration={3000}
        onClose={() => {
          setErrorMesg(null);
          dispatch(clearError());
        }}
      >
        <Alert onClose={() => {setErrorMesg(null)
          dispatch(clearError())}
        } severity="error">
          {statusCode}&nbsp;
          {errorMesg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={successAlert}
        autoHideDuration={3000}
        onClose={() => setSuccessAlert(null)}
      >
        <Alert onClose={() => setSuccessAlert(null)} severity="success">
          {id === 1 && `Token Copied`}
          {id === 2 && `Copied`}
        </Alert>
      </Snackbar>

      {currentPlan?.data?.change_scheduled === true ||
      currentPlan?.data?.cancelled === true ? (
        <NotificationBar />
      ) : null}


      <Grid container className={classes.main}>
        <Grid
          container
          item
          xs={12}
          style={{
            marginLeft: ".3rem",
            boxShadow:
              "0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
            padding: "20px 10px",
            borderRadius: "8px",
            background: "#fff",
            border: "1px solid #ccc",
          }}
        >
          <Grid
              container
              item
              xs={12}
              style={{ marginLeft: "20px", marginBottom: "20px" }}
          >
            <Grid container alignItems={"center"}>
              <Grid item xs={12}>
                <Typography style={{ color: "#000", fontWeight:"bold" }} variant={"h6"}>
                  Try Demo
                </Typography>
                <Divider style={{background:"#000",
                  width:"5%", margin:"10px 0px 20px 0px",height:"2px"}}/>


                <Box display={"flex"} flexDirection={"row"} alignItems={"flex-start"}>
                  <form style={{display:"flex",flexDirection:"row",alignItems:"flex-start",width:"100%"}}>
                  <TextField
                      // label={"Enter the url ex: https://surepass.io/"}
                      value={`${urlForTesting}`}
                      variant={"outlined"}
                      disabled={props.demoAPILoading}
                      onChange={(e)=>setUrlForTesting(e.target.value)}
                      placeholder={"Enter the url ex: nocodepdf.com/"}
                      fullWidth
                      InputProps={{
                        startAdornment:(
                            <InputAdornment position={"start"} style={{color:"#616161" , marginRight:2}}>
                              <Lock/>
                              <Typography>https://</Typography>
                            </InputAdornment>
                        )
                      }}
                      error={!!urlForTestingError}
                      helperText={props.errorMsg}
                      size={"small"}
                      style={{width:"70%", marginRight:20}}
                  />
                  <Button variant={"contained"}
                          type={"submit"}
                          disabled={props.demoAPILoading}
                          onClick={sendDemoAPIRequest}
                          className={classes.sendButton}
                          color={"primary"}>
                    {props.demoAPILoading
                        ? <CircularProgress size={24} style={{color:"#fff"}} />
                        :"Send request"}
                  </Button>
                  </form>
                </Box>
                <Collapse in={props?.responseFromDemoAPI?.url} style={{marginTop:"20px" ,
                  width:"95%", overflow:"hidden"}}>
                  <Typography style={{ color: "#000", fontWeight:"bold" }} variant={"body1"}>
                    Response
                  </Typography>
                  <Divider style={{background:"#000",
                    width:"5%", margin:"10px 0px 20px 0px",height:"2px"}}/>
                  <a href={props?.responseFromDemoAPI?.url} target={"_blank"}
                     className={classes.pdfLink}>
                    {/*{props?.responseFromDemoAPI?.url}*/}
                    {truncate(props?.responseFromDemoAPI?.url, 200)}
                  </a>
                </Collapse>
              </Grid>
            </Grid>
          </Grid>
          <br />
        </Grid>
        <br/>
        <Grid
          container
          item
          xs={12}
          style={{
            marginLeft: ".3rem",
            boxShadow:
              "0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
            padding: "20px 10px",
            borderRadius: "8px",
            background: "#fff",
            border: "1px solid #ccc",
          }}
        >
          <Grid
            container
            item
            xs={12}
            style={{ marginLeft: "20px", marginBottom: "20px" }}
          >
            <Grid container alignItems={"center"}>
              <Grid item>
                <Typography style={{ color: "#000" }} variant={"body1"}>
                  Active Subscription
                </Typography>
                {currentPlan?.data?.plan_id && (
                  <Typography variant={"caption"}>
                    {`Your plan will renew at `}
                    {currentPlan?.data?.expiry &&
                      moment(currentPlan?.data?.expiry).format("DD MMM YYYY")}
                  </Typography>
                )}
              </Grid>
              <Grid item style={{ marginLeft: 20 }}>
                <Chip
                  icon={<Check />}
                  color={"primary"}
                  label={
                    currentPlan?.data?.plan_id === null
                      ? `No Active Plan`
                      : currentPlanName?.[0]?.name
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              style={{ marginTop: "1rem" }}
            >
              <Grid item>
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: "600",
                    fontSize: "1.2rem",
                    paddingTop: ".2rem",
                  }}
                  color="textPrimary"
                >
                  Credits
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="caption"
                  color="textPrimary"
                  style={{ paddingTop: ".3rem", fontSize: ".875rem" }}
                >
                  Used &nbsp;
                  {currentPlan?.data && (
                    <span style={{ fontWeight: "600", fontSize: ".875rem" }}>
                      {currentPlan?.data?.credits === 0
                        ? currentPlan?.data?.total_credits
                        : currentPlan?.data?.total_credits -
                          currentPlan?.data?.credits}
                      &nbsp; of {currentPlan?.data?.total_credits}
                    </span>
                  )}
                  {currentPlan?.data?.length === 0 && `0 of 0`}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ paddingTop: ".2rem" }}>
              <LinearProgressBar
                value={
                  currentPlan?.data?.credits === 0
                    ? currentPlan?.data?.credits
                    : ((currentPlan?.data?.total_credits -
                        currentPlan?.data?.credits) /
                        currentPlan?.data?.total_credits) *
                      100
                }
              />
            </Grid>
          </Grid>
          <br />
          <LineChart apiUsage={apiUsage} />
        </Grid>

        {/* <ModalBody
          handleClose={handleClose}
          handleCancelation={handleCancelation}
          openModal={openModal}
          // setOpenModal={setOpenModal}
          classes={classes}
        /> */}
        {/* <ScheduleCancelModal
          handleClose={handleClose}
          openModal={openCancelSchedule}
          setOpenModal={setOpenCancelSchedule}
          classes={classes}
        /> */}
      </Grid>

      <Box>
        <Grid container direction="column">
          <Grid item xs={12} className={classes.shadowBackground}>
            <Typography
              variant="body1"
              component="div"
              style={{
                // paddingTop: "1rem",
                // paddingLeft: "1rem",
                color: "#111",
                fontSize: "2rem",
                fontWeight: "400",
              }}
              gutterBottom
            >
              API Token
            </Typography>
            {tokenLists?.length > 0 && (
              <Paper
                variant="elevation"
                style={{
                  width: "100%",
                  borderRadius: "6px",
                  background: "#0e141a",
                  color: "#ffff",
                  paddingTop: 1,
                }}
              >
                <CodeHeader />
                <Grid container>
                  <div
                    style={{
                      marginRight: "20px",
                      textAlign: "right",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      container="div"
                      style={{
                        overflowX: "auto",
                        fontSize: "14px",
                        padding: ".5rem",
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "Consolas, monaco, monospace",
                      }}
                      className={classes.span2}
                    >
                      {truncate(tokenLists?.[0]?.token, 70)}&nbsp;{" "}
                    </Typography>
                    {tokenLists && (
                      <FileCopyOutlined
                        onClick={() => {
                          copyToClipBoard(tokenLists?.[0]?.token);
                          setId(1);
                        }}
                        className={classes.copy}
                      />
                    )}
                  </div>
                </Grid>
              </Paper>
            )}

            {tokenLists?.length === 0 && (
              <Grid container className={classes.linkContainer}>
                <Typography variant="body1" className={classes.linkButton}>
                  Sorry, you don't have any API token right now&nbsp;
                  <Button
                    variant="text"
                    className={classes.link}
                    onClick={handleGenerateTokenModal}
                  >
                    Click to generate token
                  </Button>
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid item className={classes.shadowBackground}>
            <Typography
                variant="body1"
                component="div"
                style={{
                  // paddingTop: "1rem",
                  // paddingLeft: "1rem",
                  color: "#111",
                  fontSize: "2rem",
                  fontWeight: "400",
                }}
                gutterBottom
            >
              Sample API Code
            </Typography>
            <div>
              <CustomTabs onChange={handleCodeChange} value={langValue}>
                <CustomItemTab label={"Bash"}></CustomItemTab>
                <CustomItemTab label={"Node"}></CustomItemTab>
                <CustomItemTab label={"Python"}></CustomItemTab>
                <CustomItemTab label={"PHP"}></CustomItemTab>
                <CustomItemTab label={"Ruby"}></CustomItemTab>
                <CustomItemTab label={"Java"}></CustomItemTab>
              </CustomTabs>
              <TabPanel value={langValue} index={0}>
                <SyntaxHighlighter language="bash" style={dracula} wrapLines={true} >
                  {`curl --location --request POST 'https://api.nocodepdf.com/api/v1/templates/render'
--header 'Authorization: Bearer ${truncate(tokenLists?.[0]?.token,50)} '
--header 'Content-Type: application/json'
--data-raw '{
"type": "generate_output",
"url": "https://example.com",
"pdf_render_options": \'{
  "output": "pdf",
  "full_page": false,
  "format": "A4",
  "margin_top": 10,
  "margin_left": 10,
  "margin_right": 10
  }'
}'`}
                </SyntaxHighlighter>
              </TabPanel>
              <TabPanel value={langValue} index={1}>
                <SyntaxHighlighter language="javascript" style={dracula}>
                  {`const https = require('https');
const options = JSON.stringify({
    'type': 'generate_output',
    'url': 'https://example.com/',
    'pdf_render_options': {
        'output': "pdf",
        'full_page': false,
        'format': "A4",
        'margin_top': 10,
        'margin_bottom': 10,
        'margin_left': 10,
        'margin_right': 10
    }
});
const headers = {
    'Authorization': 'Bearer ${truncate(tokenLists?.[0]?.token,50)}',
    'Content-Type': 'application/json',
    'Content-Length': Buffer.byteLength(options),
};
function callback(res) {
    res.on('data', (d) => {
        console.log(d.toString());
    })
}
const req = https.request({
    hostname: 'api.nocodepdf.com',
    port: 443,
    path: '/api/v1/templates/render',
    method: 'POST',
    headers: headers,
}, callback);
req.write(options);
req.end();
`}
                </SyntaxHighlighter>
              </TabPanel>
              <TabPanel value={langValue} index={2}>
                <SyntaxHighlighter language="python" style={dracula}>
                  {`import requests
headers = {
    'Authorization': "Bearer ${truncate(tokenLists?.[0]?.token,50)}",
}
options = {
    "type": "generate_output",
    "url": "https://example.com/",
    "pdf_render_options": {
        "output": "pdf",
        "full_page": False,
        "format": "A4",
        "margin_top": 10,
        "margin_bottom": 10,
        "margin_left": 10,
        "margin_right": 10
    }
}
response = requests.post("https://api.nocodepdf.com/api/v1/templates/render", headers=headers, json=options)
print(response.content)
`}
                </SyntaxHighlighter>
              </TabPanel>
              <TabPanel value={langValue} index={3}>
                <SyntaxHighlighter language="php" style={dracula}>
                  {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
  CURLOPT_URL => 'https://api.nocodepdf.com/api/v1/templates/render',
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => '',
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 0,
  CURLOPT_FOLLOWLOCATION => true,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => 'POST',
  CURLOPT_POSTFIELDS =>'{
    "type": "generate_output",
    "url": "https://example.com/",
    "pdf_render_options": {
        "output": "pdf",
        "full_page": false,
        "format": "A4",
        "margin_top": 10,
        "margin_bottom": 10,
        "margin_left": 10,
        "margin_right": 10
    }
}',
  CURLOPT_HTTPHEADER => array(
    'Authorization: Bearer ${truncate(tokenLists?.[0]?.token,50)}',
    'Content-Type: application/json'
  ),
));
$response = curl_exec($curl);
curl_close($curl);
echo $response;
`}
                </SyntaxHighlighter>
              </TabPanel>
              <TabPanel value={langValue} index={4}>
                <SyntaxHighlighter language="ruby" style={dracula}>
                  {`require "uri"
require "json"
require "net/http"
url = URI("https://api.nocodepdf.com/api/v1/templates/render")
https = Net::HTTP.new(url.host, url.port)
https.use_ssl = true
request = Net::HTTP::Post.new(url)
request["Authorization"] = "Bearer ${truncate(tokenLists?.[0]?.token,50)}"
request["Content-Type"] = "application/json"
request.body = JSON.dump({
  "type": "generate_output",
  "url": "https://example.com/",
  "pdf_render_options": {
    "output": "pdf",
    "full_page": false,
    "format": "A4",
    "margin_top": 10,
    "margin_bottom": 10,
    "margin_left": 10,
    "margin_right": 10
  }
})
response = https.request(request)
puts response.read_body
`}
                </SyntaxHighlighter>
              </TabPanel>
              <TabPanel value={langValue} index={5}>
                <SyntaxHighlighter language="java" style={dracula}>
                  {`import java.io.IOException;
import java.io.InputStream;
import java.io.OutputStreamWriter;
import java.net.HttpURLConnection;
import java.net.URL;
import java.util.Scanner;
class Main {
    public static void main(String[] args) throws IOException {
        URL url = new URL("https://api.nocodepdf.com/api/v1/templates/render");
        HttpURLConnection httpConn = (HttpURLConnection) url.openConnection();
        httpConn.setRequestMethod("POST");
        httpConn.setRequestProperty("Authorization", "Bearer ${truncate(tokenLists?.[0]?.token,30)}");
        httpConn.setRequestProperty("Content-Type", "application/json");
        httpConn.setDoOutput(true);
        OutputStreamWriter writer = new OutputStreamWriter(httpConn.getOutputStream());
        writer.write("{\"type\":\"generate_output\",\"url\":\"https://example.com/\",\"pdf_render_options\":\n{\"output\":\"pdf\",\"full_page\":false,\"format\":\"A4\",\"margin_top\":10,\"margin_bottom\":10,\"margin_left\":10,\n\"margin_right\":10}}");
        writer.flush();
        writer.close();
        httpConn.getOutputStream().close();
        InputStream responseStream = httpConn.getResponseCode() / 100 == 2
                ? httpConn.getInputStream()
                : httpConn.getErrorStream();
        Scanner s = new Scanner(responseStream).useDelimiter("\\A");
        String response = s.hasNext() ? s.next() : "";
        System.out.println(response);
    }
}
`}
                </SyntaxHighlighter>
              </TabPanel>
            </div>
          </Grid>

          {tokenLists?.length===0 && (
              <>
                <Modal open={notificationModal} onClose={()=>setNotificationModal(false)}>
                  <GenerateTokenNotification setNotification={setNotificationModal} />
                </Modal>

              </>
          )}
          {generateModal && (
            <Modal open={openModal} onCLose={() => handleCloseModal()}>
              <Body
                open={openModal}
                // setGenerateModal={setGenerateModal}
                handleCloseModal={handleCloseModal}
              />
            </Modal>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default React.memo(Dashboard);
