import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  makeStyles,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
  Grid,
} from "@material-ui/core";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import { UiActions } from "globalUI";
import MUIDataTable from "mui-datatables";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from "moment";

const buttonStyle = {
  marginRight: "2rem",
  paddingLeft: "2rem",
  paddingRight: "2rem",
  borderRadius: 0,
  textTransform: "none",
};

const useStyles = makeStyles((theme) => ({
  navButton: {
    ...buttonStyle,
    borderBottom: `2px solid #fff `,
    color: theme.palette.grey[500],
    "&:hover": {
      color: theme.palette.grey[900],
      borderBottom: `2px solid ${theme.palette.grey[700]}`,
    },
  },
  navButtonActive: {
    ...buttonStyle,
    color: theme.palette.grey[900],
    borderBottom: `2px solid ${theme.palette.grey[900]}`,
  },
  darkText: {
    color: theme.palette.grey[700],
  },
}));

const StyledTableCell = withStyles((theme) => ({
  body: (props) => ({
    fontWeight: props.fontWeight,
    border: `1px solid ${theme.palette.grey[300]}`,
  }),
}))(TableCell);

const PurchaseHistory = (props) => {
  const classes = useStyles();
  const [billingData, setBillingData] = useState([]);
  // console.log("Billing Data", billingData);
  const theme = useTheme();
  useEffect(() => {
    setBillingData(props.billingDetail);
  }, [props.billingDetail]);

  //   const changeTableStyle = () =>
  //     createMuiTheme({
  //       palette: theme.palette,
  //       overrides: {
  //         MUIDataTableToolbar: {
  //           root: {
  //             display: "none",
  //           },
  //         },
  //         MUIDataTableBodyCell: {
  //           root: {
  //             color: theme.palette.primary.main,
  //             fontSize: "14px",
  //           },
  //         },
  //         MUIDataTableHeadCell: {
  //           root: {
  //             marginLeft: "auto",
  //             // borderTop: "1px solid #ccc",
  //             backgroundColor: "#f7f8fb !important",
  //             color: theme.palette.primary.main,
  //             fontSize: "14px",
  //             fontWeight: 600,
  //           },
  //         },
  //         MuiTableRow: {
  //           root: {
  //             "&:nth-child(even)": {
  //               backgroundColor: "#fafbfe !important",
  //             },
  //             "&:nth-child(odd)": {
  //               backgroundColor: "#ffffff !important",
  //             },
  //           },
  //           hover: {
  //             "&:hover": {
  //               backgroundColor: "rgba(122, 139, 237, 0.1)!important",
  //             },
  //           },
  //         },
  //         MuiPaper: {
  //           root: {
  //             border: `1px solid ${theme.palette.grey[300]}`,
  //           },
  //           rounded: {
  //             borderRadius: 0,
  //           },
  //         },
  //         MuiToolbar: {
  //           root: {
  //             position: "static",
  //             paddingLeft: "15px !important",
  //           },
  //         },
  //         MuiTableCell: {
  //           paddingCheckbox: {
  //             display: "none",
  //           },
  //           root: {
  //             "&:not(:nth-last-child(1))": {
  //               borderRight: `1px solid ${theme.palette.grey[300]}`,
  //             },
  //             padding: "10px !important",
  //           },
  //           head: {
  //             padding: "10px !important",
  //           },
  //           footer: {
  //             border: 0,
  //           },
  //         },
  //         MUIDataTablePagination: {
  //           tableCellContainer: {
  //             paddingTop: "0 !important",
  //             paddingBottom: "0 !important",
  //           },
  //         },
  //       },
  //     });

  //   const options = {
  //     rowsPerPage: 5,
  //     rowsPerPageOptions: [5, 10, 20, 50],
  //     jumpToPage: true,
  //     pagination: true,
  //     elevation: 0,
  //     selectableRows: "none",
  //     responsive: "simple",
  //     sort: false,
  //   };

  //   const CreditColumns = [
  //     {
  //       name: "id",
  //       label: "#",
  //       options: {
  //         empty: true,
  //         customBodyRender: (d, tableMeta) => (
  //           <Typography variant="subtitle2" color="textPrimary">
  //             {tableMeta.rowIndex + 1}.{" "}
  //           </Typography>
  //         ),
  //       },
  //     },
  //     {
  //       name: "denomination",
  //       label: "Type",
  //       options: {
  //         filter: true,
  //         sort: false,
  //         customBodyRender: (d) => (
  //           <>
  //             <Typography variant="body1" color="textPrimary">
  //               {d || "-"}
  //             </Typography>
  //           </>
  //         ),
  //       },
  //     },
  //     {
  //       name: "state",
  //       label: "Date",
  //       options: {
  //         customBodyRender: (d) => (
  //           <>
  //             <Typography variant="body1" color="textPrimary">
  //               {d || "-"}
  //             </Typography>
  //           </>
  //         ),
  //       },
  //     },
  //     {
  //       name: "total",
  //       label: "Expiry Date",
  //       options: {
  //         customBodyRender: (d) => (
  //           <>
  //             <Typography variant="body1" color="textPrimary">
  //               {d || "-"}
  //             </Typography>
  //           </>
  //         ),
  //       },
  //     },
  //     {
  //       name: "total",
  //       label: "Expired",
  //       options: {
  //         customBodyRender: (d) => (
  //           <>
  //             <Typography variant="body1" color="textPrimary">
  //               {d || "-"}
  //             </Typography>
  //           </>
  //         ),
  //       },
  //     },
  //     {
  //       name: "total",
  //       label: "Consumed ",
  //       options: {
  //         customBodyRender: (d) => (
  //           <>
  //             <Typography variant="body1" color="textPrimary">
  //               {d || "-"}
  //             </Typography>
  //           </>
  //         ),
  //       },
  //     },
  //     {
  //       name: "total",
  //       label: "Value",
  //       options: {
  //         customBodyRender: (d) => (
  //           <>
  //             <Typography variant="body1" color="textPrimary">
  //               {d || "-"}
  //             </Typography>
  //           </>
  //         ),
  //       },
  //     },
  //     {
  //       name: "total",
  //       label: "Remain",
  //       options: {
  //         customBodyRender: (d) => (
  //           <>
  //             <Typography variant="body1" color="textPrimary">
  //               {d || "-"}
  //             </Typography>
  //           </>
  //         ),
  //       },
  //     },
  //   ];

  //   const StampColumns = [
  //     {
  //       name: "id",
  //       label: "#",
  //       options: {
  //         empty: true,
  //         customBodyRender: (d, tableMeta) => (
  //           <Typography variant="subtitle2" color="textPrimary">
  //             {tableMeta.rowIndex + 1}.{" "}
  //           </Typography>
  //         ),
  //       },
  //     },
  //     {
  //       name: "state",
  //       label: "Date",
  //       options: {
  //         customBodyRender: (d) => (
  //           <>
  //             <Typography variant="body1" color="textPrimary">
  //               {d || "-"}
  //             </Typography>
  //           </>
  //         ),
  //       },
  //     },
  //     {
  //       name: "total",
  //       label: "State",
  //       options: {
  //         customBodyRender: (d) => (
  //           <>
  //             <Typography variant="body1" color="textPrimary">
  //               {d || "-"}
  //             </Typography>
  //           </>
  //         ),
  //       },
  //     },
  //     {
  //       name: "total",
  //       label: "Denomination",
  //       options: {
  //         customBodyRender: (d) => (
  //           <>
  //             <Typography variant="body1" color="textPrimary">
  //               {d || "-"}
  //             </Typography>
  //           </>
  //         ),
  //       },
  //     },
  //     {
  //       name: "total",
  //       label: "Series ",
  //       options: {
  //         customBodyRender: (d) => (
  //           <>
  //             <Typography variant="body1" color="textPrimary">
  //               {d || "-"}
  //             </Typography>
  //           </>
  //         ),
  //       },
  //     },
  //     {
  //       name: "total",
  //       label: "Under Process",
  //       options: {
  //         customBodyRender: (d) => (
  //           <>
  //             <Typography variant="body1" color="textPrimary">
  //               {d || "-"}
  //             </Typography>
  //           </>
  //         ),
  //       },
  //     },
  //     {
  //       name: "total",
  //       label: "Unused",
  //       options: {
  //         customBodyRender: (d) => (
  //           <>
  //             <Typography variant="body1" color="textPrimary">
  //               {d || "-"}
  //             </Typography>
  //           </>
  //         ),
  //       },
  //     },
  //     {
  //       name: "total",
  //       label: "Used",
  //       options: {
  //         customBodyRender: (d) => (
  //           <>
  //             <Typography variant="body1" color="textPrimary">
  //               {d || "-"}
  //             </Typography>
  //           </>
  //         ),
  //       },
  //     },
  //     {
  //       name: "total",
  //       label: "Amount",
  //       options: {
  //         customBodyRender: (d) => (
  //           <>
  //             <Typography variant="body1" color="textPrimary">
  //               {d || "-"}
  //             </Typography>
  //           </>
  //         ),
  //       },
  //     },
  //   ];
  const capitalizeLetter = (string) => {
    const lower = string.toLowerCase();
    return string.charAt(0).toUpperCase() + lower.slice(1);
  };

  return (
    <>
      {/* <Box mt={4} display="flex">
        {/* <Button
          className={
            classes.navButtonActive 
          }
          
        >
          Billing Details
        </Button> */}
      {/* <Button
          className={stampRequest ? classes.navButtonActive : classes.navButton}
                  onClick={() => setStampRequest(true)}
                  
        >
          Stamp Request
        </Button> */}
      {/* <Typography variant="subtitle1"> </Typography> */}
      {/* </Box> */}
      <Divider />

      <Box my={4} display="flex">
        <Box flexGrow={1}>
          <Grid container>
            {billingData?.data?.length === 0 ? (
              <Typography variant="h6" color="text-primary" align="center">
                No payment has done yet!!!
              </Typography>
            ) : null}
          </Grid>
          {billingData?.data?.map((data) => (
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <StyledTableCell fontWeight={500}>Amount:</StyledTableCell>
                    <StyledTableCell>
                      {data?.amount === null ? "null" : data?.amount}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell fontWeight={500}>
                      Before Amount:
                    </StyledTableCell>
                    <StyledTableCell>
                      {data?.before_amount === null
                        ? "null"
                        : data?.before_amount}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell fontWeight={500}>
                      After Amount:
                    </StyledTableCell>
                    <StyledTableCell>
                      {data?.after_amount === null
                        ? "null"
                        : data?.after_amount}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell fontWeight={500}>
                      Category:
                    </StyledTableCell>
                    <StyledTableCell>
                      {capitalizeLetter(data?.category)}
                    </StyledTableCell>
                  </TableRow>

                  <TableRow>
                    <StyledTableCell fontWeight={500}>
                      Payment Date & Time:
                    </StyledTableCell>
                    <StyledTableCell>
                      {moment(data?.created_at?.slice(0, 19)).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell fontWeight={500}>
                      Payment Method:
                    </StyledTableCell>
                    <StyledTableCell>{data?.payment_method}</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell fontWeight={500}>Reason:</StyledTableCell>
                    <StyledTableCell>{data?.reason}</StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <br />
            </TableContainer>
          ))}
        </Box>
        {/* <div style={{ display: "flex", justifyContent: "flex-end", flex: 1 }}>
            <FormControlLabel
              control={
                <Switch
                  onChange={(e) => setCompleted(e.target.checked)}
                  checked={completed ?? false}
                />
              }
              label={<Typography variant="body2">Show Completed</Typography>}
            />
          </div> */}
      </Box>
      {/* <Box my={2}>
          <MuiThemeProvider theme={changeTableStyle()}>
            <MUIDataTable
              data={[]}
              // columns={CreditColumns}
              // options={options}
            />
          </MuiThemeProvider>
        </Box> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  createData: state.ui.createData,
  inviteeData: state.ui.inviteData,
  billingDetail: state.clientData.billingDetail,
});

const mapDispatchToProps = {
  setCreateData: UiActions.setCreateData,
  setInviteeData: UiActions.setInviteData,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PurchaseHistory)
);
