import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import {
  AttachMoneyRounded,
  BlurCircularOutlined,
  DashboardOutlined,
  DescriptionOutlined,
  DesktopWindows,
  FolderOpenRounded,
  HistoryRounded,
  NaturePeopleOutlined,
  PermIdentity,
  SettingsRounded,
  LockOpen,
  LocalOffer,
  DonutLarge,
} from "@material-ui/icons";

import DataUsageIcon from "@material-ui/icons/DataUsage";
import React from "react";
import SupervisorAccountRoundedIcon from "@material-ui/icons/SupervisorAccountRounded";
import { Link, Route } from "react-router-dom";

const styles = {
  listColor: {
    // backgroundColor: "#7A8BED",
    // backgroundColor: "#00bcd429",
    backgroundColor: "rgb(66 99 235 / 9%)",
    padding: ".8rem 1rem 0.8rem 22px",
    borderLeft: `5px solid #4263eb`,
  },
  listDefault: {
    padding: ".8rem 1rem 0.8rem 22px",
  },
  listItemActiveText: {
    fontSize: "1rem",
    // color: "#ffffff"
    color: "#4263eb",
    fontWeight: 600,
  },
  listItemDefaultText: {
    fontSize: "1rem",
    color: "#707070",
  },
  listItemIconActive: {
    // color: "#fff"
    color: "#4263eb",
  },
  listItemIconDefault: {
    color: "#4263eb",
  },
};

export const MailFolderListItems = ({ home }) => {
  //default styles
  let listDefault = styles.listDefault;
  let listItemIconDefault = styles.listItemIconDefault;
  let listItemDefaultText = styles.listItemDefaultText;

  //active color
  let listItemStyles = styles.listColor;
  let listItemIcon = styles.listItemIconActive;
  let listItemText = styles.listItemActiveText;

  const NavLink = ({ to, children, className, activeClassName, ...rest }) => {
    const path = typeof to === "object" ? to.pathname : to;
    return (
      <Route
        path={path}
        children={({ match }) => {
          const isActive = match;
          return (
            <Link {...rest} to={to}>
              {typeof children === "function" ? children(isActive) : children}
            </Link>
          );
        }}
      />
    );
  };

  return (
    <div>
      {/* For Dashboard */}
      {home &&
        HomeArray.map((item, k) => {
          return (
            <NavLink
              to={`${
                item.name === "billing" ? `/${item.name}` : `/${item.name}`
              }`}
              key={k}
            >
              {(isActive) => (
                <ListItem
                  button
                  style={isActive ? listItemStyles : listDefault}
                >
                  <ListItemIcon
                    style={isActive ? listItemIcon : listItemIconDefault}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    disableTypography={true}
                    style={isActive ? listItemText : listItemDefaultText}
                  >
                    {item.title}
                  </ListItemText>
                </ListItem>
              )}
            </NavLink>
          );
        })}

      {!home &&
        AccountArray.map((item, k) => {
          return (
            <NavLink to={`/account/${item.name}`} key={k}>
              {(isActive) => (
                <ListItem
                  button
                  style={isActive ? listItemStyles : listDefault}
                >
                  <ListItemIcon
                    style={isActive ? listItemIcon : listItemIconDefault}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    disableTypography={true}
                    style={isActive ? listItemText : listItemDefaultText}
                  >
                    {item.title}
                  </ListItemText>
                </ListItem>
              )}
            </NavLink>
          );
        })}
    </div>
  );
};

const HomeArray = [
  { name: "home", title: "API Usage", icon: <DataUsageIcon /> },
  // { name: "documents", title: "Documents", icon: <DescriptionOutlined /> },
  // { name: "profile", title: "Profile", icon: <PermIdentity /> },
  { name: "billing", title: "Payment", icon: <AttachMoneyRounded /> },
  { name: "plan", title: "Subscription", icon: <LocalOffer /> },
  {
    name: "authentication_logs",
    title: "Authentication Logs",
    icon: <LockOpen />,
  },
  {
    name: "api-keys",
    title: "API Keys",
    icon: <DonutLarge />,
  },

  // { name: "templates", title: "Templates", icon: <DashboardOutlined /> },
  // { name: "stamps", title: "Stamps", icon: <BlurCircularOutlined /> },
  // { name: "folders", title: "Folders", icon: <FolderOpenRounded /> },
];

const AccountArray = [
  { name: "profile", title: "Profile", icon: <PermIdentity /> },
  { name: "settings", title: "Settings", icon: <SettingsRounded /> },
  { name: "billing", title: "Billing", icon: <AttachMoneyRounded /> },
  { name: "department", title: "Department", icon: <NaturePeopleOutlined /> },
  { name: "activity-logs", title: "Activity Logs", icon: <HistoryRounded /> },
];
