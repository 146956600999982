import React from "react";
import { Redirect, Route } from "react-router-dom";
import {useSelector} from "react-redux"

const RequireUnauthRoute = ({
  component: Component,
  authenticated,
  ...rest
}) => {
const {onboardRequired}=useSelector(state=>state.auth)
  // console.log(authenticated,onboardRequired);

  return (
    <Route
      {...rest}
      render={(props) => {
        return authenticated && onboardRequired ? (
          <Redirect
            to={{
              pathname: "/plan",
              state: { from: props.location },
            }}
          />
        ) : (
          <>
              {
                authenticated && !onboardRequired?
                <Redirect
            to={{
              pathname: "/home",
              state: { from: props.location },
            }}
          />:<Component {...props} />

            }

              </>
        );
      }}
    />
    //   <Route
    //       {...rest}
    //
    //       render={props => {
    //           return authenticated ? (
    //               <Redirect to={{
    //                   pathname: '/home',
    //                   state: { from: props.location }
    //               }} />
    //           ) : (
    //               <Component {...props} />
    //           )
    //       }}
    //   />
  );
};

export default RequireUnauthRoute;
