import {Button, withStyles} from "@material-ui/core";

export const GenerateButton = withStyles((theme) => ({
    root: {
        height: "2.5rem",
        padding: "0 2%",
        borderRadius: "8px",
        backgroundColor: "#3f51b5",
        boxShadow: "0px 0px 8px 0px #ccc",
        fontWeight: 800,
        color: "#fff",
        "&:hover": {
            background: 'none',
            color: theme.palette.grey[900],
        },
        "& .MuiTouchRipple-root": {
            opacity: 0.2,
            zIndex: -1,
        }
    },
}))(Button);
