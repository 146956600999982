import {
  Backdrop,
  Box,
  Button,
  Collapse,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import {
  AddRounded,
  ArrowDownward,
  ArrowUpward,
  CloseRounded,
  Delete,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { UiActions } from "globalUI";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import OptDrawer from "./OptDrawer";
import SignDrawer from "./SignDrawer";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
    color: "#fff",
  },
  button: {
    marginRight: theme.spacing(4),
  },
  inviteeButton: {
    textTransform: "none",
  },
}));

function Invite({ setActiveStep, ...props }) {
  const classes = useStyles();
  const [showError, setShowError] = useState(false);
  const [showIloDrawer, setShowIloDrawer] = useState(true);
  const [showSignDrawer, setShowSignDrawer] = useState(true);

  const user = {
    name: "Simranjeet",
    contact: "smrnjeet222@gmail.com",
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    errors,
    setError,
    clearErrors,
    getValues,
  } = useForm({
    defaultValues: { ...props?.inviteData },
    mode: "onBlur",
  });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "invitees", // unique name for your Field Array
      // keyName: "id", default to "id", you can change the key name
    }
  );

  const fixedOrder = watch("fixedOrder", false);
  const mySign = watch("mySign", true);

  useEffect(() => {
    // const userIdx = fields.map((f) => f.isUser).indexOf(true);
    const userIdx = fields.findIndex((f) => !!f.isUser);
    if (userIdx === -1 && !!mySign) {
      prepend({ ...user, isUser: true });
    }
    if (userIdx !== -1 && !mySign) {
      remove(userIdx);
    }
  }, [mySign]);

  useEffect(() => {
    if (!fields.length) {
      setError("invitees", {
        type: "required",
        message: "Atleast one invitee is required",
      });
      setShowError(true);
    } else {
      clearErrors("invitees");
      setShowError(false);
    }
  }, [fields.length]);

  const validateContact = (e) => {
    const validEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        e
      );
    const validPhone =
      /^\+?([0-9]{2})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{5})$/.test(e) ||
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(e);
    if (!validEmail && !validPhone) {
      return "Invalid Email/Phone No.";
    }

    return true;
  };

  const onSubmit = (data) => {
    // console.log(data);
    props.setInviteData(data);
    setActiveStep((prev) => prev + 1);
  };

  return (
    // dont wrap inside a div or any component keep main container and footer structure
    <>
      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
      >
        <Alert onClose={() => setShowError(false)} severity="error">
          {errors?.invitees?.message || "Fill all required fields"}
        </Alert>
      </Snackbar>
      {/* main container */}
      <Grid container justifyContent="center" style={{ margin: "1rem 0" }}>
        <Grid item xs={11}>
          <Grid container justifyContent="center">
            <Grid item xs={9}>
              <FormControlLabel
                control={
                  <Switch
                    inputRef={register}
                    name="fixedOrder"
                    color="primary"
                    defaultChecked={fixedOrder ?? false}
                  />
                }
                label="Request invitees to sign in fixed order"
              />
            </Grid>
            <Grid item xs={9}>
              <FormControlLabel
                control={
                  <Switch
                    inputRef={register}
                    name="mySign"
                    color="primary"
                    defaultChecked={mySign ?? false}
                  />
                }
                label="I want to sign this document"
              />
            </Grid>
            <Grid item xs={10}>
              {fields.map((item, idx) => (
                <Paper
                  variant="outlined"
                  component={Box}
                  py={2}
                  px={4}
                  my={2}
                  key={item?.id}
                >
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <div>
                      {fixedOrder && (
                        <Typography variant="caption">
                          Sign Order: {idx + 1}
                        </Typography>
                      )}
                    </div>
                    <div>
                      {fixedOrder && (
                        <>
                          <IconButton
                            disabled={idx === 0}
                            onClick={() => move(idx, idx - 1)}
                          >
                            <ArrowUpward fontSize="small" />
                          </IconButton>
                          <IconButton
                            disabled={idx === fields.length - 1}
                            onClick={() => move(idx, idx + 1)}
                          >
                            {" "}
                            <ArrowDownward fontSize="small" />
                          </IconButton>
                        </>
                      )}
                      <IconButton
                        color="primary"
                        disabled={item?.isUser}
                        onClick={() => remove(idx)}
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    </div>
                  </Box>
                  <Grid container spacing={6}>
                    <Grid item xs={6}>
                      <Typography color="textPrimary" gutterBottom>
                        Name *
                      </Typography>
                      <Controller
                        as={
                          <TextField
                            error={!!errors?.invitees?.[idx]?.name}
                            disabled={item?.isUser}
                            placeholder="Invitee Name"
                            variant="outlined"
                            autoComplete="off"
                            size="small"
                            fullWidth
                          />
                        }
                        name={`invitees[${idx}].name`}
                        control={control}
                        rules={{ required: true }}
                        defaultValue={item?.name || ""} // make sure to set up defaultValue
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="textPrimary" gutterBottom>
                        Email/Mobile *
                      </Typography>
                      <Controller
                        as={
                          <TextField
                            error={!!errors?.invitees?.[idx]?.contact}
                            disabled={item?.isUser}
                            placeholder="Email or Phone Number"
                            helperText={
                              errors?.invitees?.[idx]?.contact?.message
                            }
                            variant="outlined"
                            autoComplete="off"
                            size="small"
                            fullWidth
                          />
                        }
                        name={`invitees[${idx}].contact`}
                        control={control}
                        rules={{ required: true, validate: validateContact }}
                        defaultValue={item?.contact || ""} // make sure to set up defaultValue
                      />
                    </Grid>
                    <Controller
                      as={null}
                      name={`invitees[${idx}].isUser`}
                      control={control}
                      defaultValue={item?.isUser ?? false} // make sure to set up defaultValue
                    />
                  </Grid>
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    mt={2}
                  >
                    <div>
                      <Button
                        color="primary"
                        onClick={() => setShowSignDrawer(idx)}
                        className={classes.inviteeButton}
                      >
                        Signature Type
                      </Button>
                      <Backdrop
                        className={classes.backdrop}
                        open={showSignDrawer === idx}
                        onClick={() => setShowSignDrawer(null)}
                      />
                      <Drawer
                        anchor="right"
                        variant="persistent"
                        classes={{ paperAnchorDockedRight: "no-border-drawer" }}
                        open={showSignDrawer === idx}
                        onClose={() => setShowSignDrawer(null)}
                      >
                        <Box width={400} role="presentation">
                          <Box
                            position="sticky"
                            top={0}
                            zIndex={10}
                            display="flex"
                            py={1}
                            px={2}
                            justifyContent="space-between"
                            alignItems="center"
                            bgcolor="primary.main"
                            color="primary.contrastText"
                          >
                            <Typography variant="h5">Signature Type</Typography>
                            <IconButton
                              onClick={() => setShowSignDrawer(null)}
                              style={{ color: "white" }}
                            >
                              <CloseRounded />
                            </IconButton>
                          </Box>
                          <Divider />
                          <SignDrawer item={item} idx={idx} control={control} />
                        </Box>
                      </Drawer>
                    </div>

                    <div>
                      <Button
                        color="primary"
                        onClick={() => setShowIloDrawer(idx)}
                        className={classes.inviteeButton}
                      >
                        Invitee level option
                      </Button>
                      <Backdrop
                        className={classes.backdrop}
                        open={showIloDrawer === idx}
                        onClick={() => setShowIloDrawer(null)}
                      />
                      <Drawer
                        anchor="right"
                        variant="persistent"
                        classes={{ paperAnchorDockedRight: "no-border-drawer" }}
                        open={showIloDrawer === idx}
                        onClose={() => setShowIloDrawer(null)}
                      >
                        <Box width={500} role="presentation">
                          <Box
                            position="sticky"
                            top={0}
                            zIndex={10}
                            display="flex"
                            py={1}
                            px={2}
                            justifyContent="space-between"
                            alignItems="center"
                            bgcolor="primary.main"
                            color="primary.contrastText"
                          >
                            <Typography variant="h5">More Options</Typography>
                            <IconButton
                              onClick={() => setShowIloDrawer(null)}
                              style={{ color: "white" }}
                            >
                              <CloseRounded />
                            </IconButton>
                          </Box>
                          <Divider />
                          <OptDrawer item={item} idx={idx} control={control} />
                        </Box>
                      </Drawer>
                    </div>
                  </Box>
                </Paper>
              ))}
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddRounded />}
                onClick={() => append({})}
              >
                Add Invitee
              </Button>
            </Grid>
          </Grid>
          <div className="padding-for-firefox">
            {/* Provide padding required for firefox browser for fixed footer */}
          </div>
        </Grid>
      </Grid>

      {/* footer */}
      <Paper square style={{ position: "sticky", bottom: 0 }}>
        <Box
          p={2}
          mx={3}
          display="flex"
          justifyContent="flex-end"
          maxHeight="5rem"
        >
          <Button
            size="large"
            color="primary"
            edge="end"
            onClick={() => setActiveStep((prev) => prev - 1)}
            className={classes.button}
          >
            Back
          </Button>
          <Button
            size="large"
            variant="contained"
            color="primary"
            edge="end"
            onClick={() => {
              errors?.invitees && setShowError(true);
              handleSubmit(onSubmit)();
            }}
            className={classes.button}
          >
            Next
          </Button>
        </Box>
      </Paper>
    </>
  );
}

const mapStateToProps = (state) => ({
  inviteData: state.ui.inviteData,
});

const mapDispatchToProps = {
  setInviteData: UiActions.setInviteData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Invite));
