import React from "react";
import { Avatar } from "@material-ui/core";
import { useSelector } from "react-redux";


const AvatarCom = (props) => {
  const { userName } = useSelector((state) => state.clientData);
  return (
    <>
      <Avatar
        style={{
          background: "#4263eb",
          paddingRight: ".1rem",
          width: `${props.width}`,
          height: `${props.height}`,
          fontSize: `${props.fontSize}`,
        }}
      >
        {userName}
      </Avatar>
    </>
  );
};

export default AvatarCom;
