import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import {
  Card,
  Divider,
  Container,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Grid,
  Snackbar,
  Typography,
  MuiThemeProvider,
  createMuiTheme,
  LinearProgress,
} from "@material-ui/core";
import ModalBody from "../modalPage/cancelModal";
import {
  checkOut,
  getCurrentPlanDetails,
  getPlanList,
  getPlanID,
  setInitialCheckout,
  cancelSubscriptionPlan,
  changePlan,
  removeCancelSchedule,
  removeChangePlan, clearCheckout,
} from "getDetails/actions";
import ScheduleCancelModal from "../modalPage/cancelSchedule";
import { ViewCarouselRounded } from "@material-ui/icons";

import { Alert } from "@material-ui/lab";

import moment from "moment";
import { CLEAR_ERROR_MESSAGE, CLEAR_STATUS_CODE } from "auth";
import { cancelModal, clearError } from "auth/actions";
import ChangeModal from "../modalPage/changePlanModal";
import CancleChangeModal from "../modalPage/cancelChangePlan";

const theme = createMuiTheme({
  overrides: {
    MuiCardContent: {
      root: {
        padding: "20px 10px",
      },
    },
    palette: {
      action: {
        disabledBackground: "red",
        disabled: "#fff",
      },
    },
    // MuiButton: {
    //   contained: {
    //     background: "red",
    //     color: "#fff",
    //     "&.Mui-disabled": {
    //       backgroundColor: "red",
    //       color: "#fff",
    //       borderRadius: "10px",
    //       fontWeight: "600",
    //     },
    //   },
    // },

    // Mui: {
    //   disabled: {
    //     backgroundColor: "red",
    //     color: "#fff",
    //     borderRadius: "10px",
    //     fontWeight: "600",
    //   },
    // },
  },
});
const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(3, 0),
  },
  rootLinear: {
    width: "100%",

    margin: "auto",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  cardHeader: {
    // marginLeft: ".25rem",
    // marginRight: ".25rem",
  },
  cardPricing: {
    paddingTop: "10px",
  },

  basicDetails: {
    padding: "20px 0px",
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: ".5rem",
    marginBottom: ".5rem",
    borderRadius: "10px",

    padding: "8px 14px",
  },
  card: {
    padding: "15px",
    borderRadius: "10px",
    // boxShadow:
    //   "rgba(0, 0, 0, 0.16) 0px 3px 3px, rgba(0, 0, 0, 0.23) 0px 3px 3px",
    border: ".5px solid #0084ec69",
  },
  button: {
    borderRadius: "14px",
    textTransform: "capitalize",
  },

  buyBtn: {
    fontWeight: "600",
    borderRadius: "10px",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  buyBtn_10: {
    fontWeight: "600",
    borderRadius: "10px",
    color: theme.palette.primary.main,
    backgroundColor: "#fff",
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "#fff",
    },
  },
  cancelBtn: {
    backgroundColor: "red",
    color: "#fff",
    borderRadius: "10px",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "#DF2E2E",
    },

    // "& .Mui-disabled": {},
  },
  changeBtn: {
    backgroundColor: "rgb(206 227 255 / 48%)",
    fontWeight: "600",
    borderRadius: "10px",
    color: theme.palette.primary.main,

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
  cancelChangeButton: {
    backgroundColor: "red",
    fontWeight: "600",
    borderRadius: "10px",
    color: "#fff",

    "&:hover": {
      backgroundColor: "red",
      color: "#fff",
    },
  },
  removeCancel: {
    backgroundColor: "red",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "red",
    },
  },
  title: {
    fontWeight: "700",
    textAlign: "left",
  },
  heading: {
    color: "#000",
    display: "flex",
    margin: "50px 0px 50px 55px",
    alignItems: "center",
  },
  customIcon: {
    fontSize: "35px",
    marginRight: "20px",
  },
}));

const details = [
  "12+ Geotargetting",
  "JS Rendering",
  "Residential Proxies",
  "JSON Auto Parsing",
];

export default function Plan(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [plan_id, setplanId] = useState("");

  const [openCancelSchedule, setOpenCancelSchedule] = useState(false);
  const [openChangeModal, setOpenChangeModal] = useState(false);
  const [openCancelChangeModal, setOpenCancelChangeModal] = useState(false);
  const { planDetail, currentPlan, errormsg, statusCode,planId } = useSelector(
    (state) => state.clientData
  );
  const {
    errormesg,
    changePlanData,
    cancelRemoveSchedule,
    cancelSubscription,
  } = useSelector((state) => state.planReducer);
  const { cancelModalState } = useSelector((state) => state.modalRed);
  const handleClose = () => {
    setOpenCancelSchedule(false);
    setOpenChangeModal(false);
  };

  // console.log("CancelModal", openCancelSchedule);
  // console.log("ChangeModal", openChangeModal);
  // console.log("RemoveCancel", openCancelChangeModal);
  const classes = useStyles();
  const handleCancel = () => {
    setOpenModal(true);
    // dispatch(cancelSubscriptionPlan());
  };
  const handleBuy = (plan_id) => {
    dispatch({
      type: CLEAR_ERROR_MESSAGE,
      payload: "",
    });
    dispatch({
      type: CLEAR_STATUS_CODE,
      payload: "",
    });
    dispatch(setInitialCheckout(false));
    dispatch(getPlanID(plan_id));
    dispatch(clearCheckout())
    dispatch(checkOut(plan_id));
    // dispatch(getPlanID(""));

    history.push("/home");
  };

  const [errorMesg, setErrorMesg] = useState("");

  const handleRemoveCancelSchedule = (plan_id) => {
    dispatch({
      type: CLEAR_ERROR_MESSAGE,
      payload: "",
    });
    dispatch({
      type: CLEAR_STATUS_CODE,
      payload: "",
    });
    setplanId(plan_id);
    // dispatch(removeCancelSchedule(plan_id));

    setOpenCancelSchedule(true);
  };

  const handleCancelChangePlan = (plan_id) => {
    dispatch({
      type: CLEAR_ERROR_MESSAGE,
      payload: "",
    });
    dispatch({
      type: CLEAR_STATUS_CODE,
      payload: "",
    });
    // dispatch(removeChangePlan(plan_id));
    setplanId(plan_id);
    setOpenCancelChangeModal(true);
  };
  const handleChangePlan = (plan_id) => {
    dispatch({
      type: CLEAR_ERROR_MESSAGE,
      payload: "",
    });
    dispatch({
      type: CLEAR_STATUS_CODE,
      payload: "",
    });
    // dispatch(
    //   changePlan({
    //     plan_id: plan_id,
    //   })
    // );
    setplanId(plan_id);
    setOpenChangeModal(true);
  };
  useEffect(() => {
    dispatch(getPlanList());
    dispatch(getCurrentPlanDetails());
  }, [changePlanData]);
  useEffect(() => {
    setErrorMesg(errormsg);

  }, [errormsg]);
  useEffect(()=>{
    if(planId){
      history.push("/home")
    }
  },[])

  // console.log("CurrentPlan", currentPlan);

  const planData = planDetail?.plans?.sort((a, b) => {
    return a?.price - b?.price;
  });
  // console.log(planData);

  let dateLessThanExpiry =
    moment(moment().format("DD MMM YYYY")) <=
    moment(moment(currentPlan?.data?.expiry).format("DD MMM YYYY"));
  // console.log("Expiry Check", dateLessThanExpiry);

  // console.log(
  //   "Expiry Date",
  //   moment(moment(currentPlan?.data?.expiry).format("DD MMM YYYY"))
  // );
  // console.log("Current", moment(moment().format("DD MMM YYYY")));
  // console.log(dateLessThanExpiry);
  return (
    <React.Fragment>
      {/* Hero unit */}
      <Snackbar
        open={!!errorMesg}
        autoHideDuration={3000}
        onClose={() => {
          setErrorMesg(null);
          // dispatch(clearError());
        }}
      >
        <Alert
          onClose={() => {
            setErrorMesg(null);
            // dispatch(clearError());
          }}
          severity="error"
        >
          {statusCode}&nbsp;
          {errorMesg}
        </Alert>
      </Snackbar>

      {planData ? (
        <>
          <Typography variant="h4" className={classes.heading} gutterBottom>
            <ViewCarouselRounded className={classes.customIcon} />
            Plans & Pricing
          </Typography>

          <Container component="main">
            <Grid container spacing={3} xs={12}>
              {planData?.map((plan) => (
                // Enterprise card is full width at sm breakpoint
                <Grid
                  item
                  key={plan?.id}
                  xs={12}
                  lg={planDetail?.plans?.length < 2 ? 5 : 4}
                >
                  <MuiThemeProvider theme={theme}>
                    <Card
                      className={classes.card}
                      elevation={0}
                      style={{
                        backgroundColor: `${
                          plan?.id === currentPlan?.data?.plan_id
                            ? currentPlan?.data?.cancelled === true &&
                              dateLessThanExpiry
                              ? "#4263eb"
                              : dateLessThanExpiry
                              ? "#4262eb"
                              : "#ffff"
                            : plan?.id === "Surepdf-Basic-USD-Monthly" &&
                              !currentPlan?.data?.plan_id
                            ? // !currentPlan?.data?.plan_id
                              "#4262eb"
                            : "#ffff"
                        }`,

                        border: `${
                          currentPlan?.data?.scheduled_plan_id === plan?.id
                            ? ".5px solid #0084ec69"
                            : ".5px solid #0084ec69"
                        }`,
                        color: `${
                          plan?.id === currentPlan?.data?.plan_id
                            ? currentPlan?.data?.cancelled === true &&
                              dateLessThanExpiry
                              ? "#fff"
                              : dateLessThanExpiry
                              ? "#fff"
                              : "#111"
                            : plan?.id === "Surepdf-Basic-USD-Monthly" &&
                              !currentPlan?.data?.plan_id
                            ? "#ffff"
                            : "#111"
                        }`,
                        padding: `${
                          plan?.id === currentPlan?.data?.plan_id
                            ? currentPlan?.data?.cancelled === true &&
                              dateLessThanExpiry
                              ? "0px 0px 15px 0px"
                              : dateLessThanExpiry
                              ? "0px 0px 15px 0px"
                              : "15px"
                            : currentPlan?.data?.change_scheduled === true &&
                              currentPlan?.data?.scheduled_plan_id === plan?.id
                            ? "0px 0px 15px 0px"
                            : "15px"
                        }`,
                        marginTop: `${
                          plan?.id === currentPlan?.data?.plan_id
                            ? currentPlan?.data?.cancelled === true &&
                              dateLessThanExpiry
                              ? "0px"
                              : dateLessThanExpiry
                              ? "0px"
                              : "30px"
                            : currentPlan?.data?.change_scheduled === true &&
                              currentPlan?.data?.scheduled_plan_id === plan?.id
                            ? "-30px"
                            : plan?.id === "Surepdf-Basic-USD-Monthly" &&
                              !currentPlan?.data?.plan_id
                            ? "0px"
                            : "30px"
                        }`,
                      }}
                    >
                      <Typography
                        variant={"subtitle1"}
                        style={{
                          background: "#0f2c7c61",
                          padding: "10px",
                          display: `${
                            plan?.id === currentPlan?.data?.plan_id
                              ? currentPlan?.data?.cancelled === true &&
                                dateLessThanExpiry
                                ? "block"
                                : dateLessThanExpiry
                                ? "block"
                                : "none"
                              : "none"
                          }`,
                        }}
                      >
                        Active Subscription
                      </Typography>
                      {currentPlan?.data?.change_scheduled === true &&
                      currentPlan?.data?.scheduled_plan_id === plan?.id ? (
                        <Typography
                          variant={"subtitle1"}
                          style={{
                            background: "rgb(66, 98, 235)",
                            padding: "10px",
                            color: "#fff",
                            display: `${
                              currentPlan?.data?.change_scheduled === true &&
                              currentPlan?.data?.scheduled_plan_id === plan?.id
                                ? dateLessThanExpiry
                                  ? "block"
                                  : dateLessThanExpiry
                                  ? "block"
                                  : "none"
                                : "none"
                            }`,
                          }}
                        >
                          Will automatically activate on{" "}
                          <strong>
                            {moment(currentPlan?.data?.expiry).format(
                              "DD MMM YYYY"
                            )}
                          </strong>
                        </Typography>
                      ) : null}
                      <CardContent>
                        <div className={classes.cardHeader}>
                          <Typography className={classes.title} variant="h5">
                            {plan?.name}
                          </Typography>
                        </div>
                      </CardContent>
                      <CardContent
                        style={{
                          backgroundColor: "transparent",
                          userSelect: "auto",
                          padding: "0px 10px",
                          marginTop: "-18px",
                        }}
                      >
                        <div className={classes.cardPricing}>
                          <Typography
                            variant="h3"
                            align="left"
                            style={{
                              color: `${
                                plan?.id === currentPlan?.data?.plan_id
                                  ? currentPlan?.data?.cancelled === true &&
                                    dateLessThanExpiry
                                    ? "#ffff"
                                    : dateLessThanExpiry
                                    ? "#ffff"
                                    : "#4262eb"
                                  : plan?.id === "Surepdf-Basic-USD-Monthly" &&
                                    !currentPlan?.data?.plan_id
                                  ? // !currentPlan?.data?.plan_id
                                    "#ffff"
                                  : "#4262eb"
                              }`,
                              fontWeight: "bold",
                            }}
                          >
                            ${plan?.price}
                          </Typography>
                          <Typography
                            variant="h6"
                            align="left"
                            style={{
                              color: `${
                                plan?.id === currentPlan?.data?.plan_id
                                  ? currentPlan?.data?.cancelled === true &&
                                    dateLessThanExpiry
                                    ? "#ffff"
                                    : dateLessThanExpiry
                                    ? "#ffff"
                                    : "rgb(78, 78, 78)"
                                  : plan?.id === "Surepdf-Basic-USD-Monthly" &&
                                    !currentPlan?.data?.plan_id
                                  ? "#ffff"
                                  : "rgb(78, 78, 78)"
                              }`,

                              marginTop: "-4px",
                            }}
                          >
                            per {plan?.period}
                          </Typography>
                        </div>
                        <Grid
                          container
                          xs={12}
                          className={classes.basicDetails}
                        >
                          <Grid
                            container
                            item
                            className={classes.box}
                            style={{
                              backgroundColor: `${
                                plan?.id === currentPlan?.data?.plan_id
                                  ? currentPlan?.data?.cancelled === true &&
                                    dateLessThanExpiry
                                    ? "rgb(32 200 222 / 26%)"
                                    : dateLessThanExpiry
                                    ? "rgb(32 200 222 / 26%)"
                                    : "#ECF0F1"
                                  : plan?.id === "Surepdf-Basic-USD-Monthly" &&
                                    !currentPlan?.data?.plan_id
                                  ? "rgb(32 200 222 / 26%)"
                                  : "#ECF0F1"
                              }`,

                              justifyContent: "left",
                            }}
                          >
                            {/* <Typography
                        variant="subtitle2"
                        color={
                          plan?.id === currentPlan?.data?.plan_id
                            ? "#ffff"
                            : "#2471A3"
                        }
                      >
                        Description
                      </Typography> */}
                            <Typography
                              variant="caption"
                              component="h5"
                              align="center"
                              style={{
                                fontSize: "14px",

                                fontWeight: "600",
                              }}
                              color={
                                plan?.id === currentPlan?.data?.plan_id
                                  ? currentPlan?.data?.cancelled === true &&
                                    dateLessThanExpiry
                                    ? "#ffff"
                                    : dateLessThanExpiry
                                    ? "#ffff"
                                    : "#2471A3"
                                  : plan?.id === "Surepdf-Basic-USD-Monthly" &&
                                    !currentPlan?.data?.plan_id
                                  ? "#ffff"
                                  : "#2471A3"
                              }
                            >
                              {plan?.description}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            item
                            className={classes.box}
                            style={{
                              backgroundColor: `${
                                plan?.id === currentPlan?.data?.plan_id
                                  ? currentPlan?.data?.cancelled === true &&
                                    dateLessThanExpiry
                                    ? "rgb(32 200 222 / 26%)"
                                    : dateLessThanExpiry
                                    ? "rgb(32 200 222 / 26%)"
                                    : "#ECF0F1"
                                  : plan?.id === "Surepdf-Basic-USD-Monthly" &&
                                    !currentPlan?.data?.plan_id
                                  ? "rgb(32 200 222 / 26%)"
                                  : "#ECF0F1"
                              }`,
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              color={
                                plan?.id === currentPlan?.data?.plan_id
                                  ? currentPlan?.data?.cancelled === true &&
                                    dateLessThanExpiry
                                    ? "#ffff"
                                    : dateLessThanExpiry
                                    ? "#ffff"
                                    : "#2471A3"
                                  : plan?.id === "Surepdf-Basic-USD-Monthly" &&
                                    !currentPlan?.data?.plan_id
                                  ? // !currentPlan?.data?.plan_id
                                    "#ffff"
                                  : "#2471A3"
                              }
                            >
                              Product
                            </Typography>
                            <Typography
                              variant="caption"
                              component="h5"
                              style={{
                                fontSize: "14px",

                                fontWeight: "600",
                              }}
                              color={
                                plan?.id === currentPlan?.data?.plan_id
                                  ? currentPlan?.data?.cancelled === true &&
                                    dateLessThanExpiry
                                    ? "#ffff"
                                    : dateLessThanExpiry
                                    ? "#ffff"
                                    : "#2471A3"
                                  : plan?.id === "Surepdf-Basic-USD-Monthly" &&
                                    !currentPlan?.data?.plan_id
                                  ? // !currentPlan?.data?.plan_id
                                    "#ffff"
                                  : "#2471A3"
                              }
                            >
                              {plan?.product}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            item
                            className={classes.box}
                            style={{
                              backgroundColor: `${
                                plan?.id === currentPlan?.data?.plan_id
                                  ? currentPlan?.data?.cancelled === true &&
                                    dateLessThanExpiry
                                    ? "rgb(32 200 222 / 26%)"
                                    : dateLessThanExpiry
                                    ? "rgb(32 200 222 / 26%)"
                                    : "#ECF0F1"
                                  : plan?.id === "Surepdf-Basic-USD-Monthly" &&
                                    !currentPlan?.data?.plan_id
                                  ? // !currentPlan?.data?.plan_id
                                    "rgb(32 200 222 / 26%)"
                                  : "#ECF0F1"
                              }`,
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              color={
                                plan?.id === currentPlan?.data?.plan_id
                                  ? currentPlan?.data?.cancelled === true &&
                                    dateLessThanExpiry
                                    ? "#ffff"
                                    : dateLessThanExpiry
                                    ? "#ffff"
                                    : "#2471A3"
                                  : plan?.id === "Surepdf-Basic-USD-Monthly" &&
                                    !currentPlan?.data?.plan_id
                                  ? // !currentPlan?.data?.plan_id
                                    "#ffff"
                                  : "#2471A3"
                              }
                            >
                              Credits
                            </Typography>
                            <Typography
                              variant="caption"
                              component="h5"
                              color={
                                plan?.id === currentPlan?.data?.plan_id
                                  ? currentPlan?.data?.cancelled === true &&
                                    dateLessThanExpiry
                                    ? "#ffff"
                                    : dateLessThanExpiry
                                    ? "#ffff"
                                    : "#2471A3"
                                  : plan?.id === "Surepdf-Basic-USD-Monthly" &&
                                    !currentPlan?.data?.plan_id
                                  ? // !currentPlan?.data?.plan_id
                                    "#ffff"
                                  : "#2471A3"
                              }
                              style={{
                                fontSize: "14px",

                                fontWeight: "600",
                              }}
                            >
                              {plan?.credits}
                            </Typography>
                          </Grid>
                        </Grid>
                        <ul style={{ padding: "0px 20px" }}>
                          {plan?.features?.map((line) => (
                            <Typography
                              component="li"
                              variant="subtitle1"
                              align="left"
                              key={line}
                              style={{
                                color: `${
                                  plan?.id === currentPlan?.data?.plan_id
                                    ? currentPlan?.data?.cancelled === true &&
                                      dateLessThanExpiry
                                      ? "#ffff"
                                      : dateLessThanExpiry
                                      ? "#ffff"
                                      : "rgb(78,78,78)"
                                    : plan?.id ===
                                        "Surepdf-Basic-USD-Monthly" &&
                                      !currentPlan?.data?.plan_id
                                    ? // !currentPlan?.data?.plan_id
                                      "#ffff"
                                    : "rgb(78,78,78)"
                                }`,
                                fontSize: "14px",
                              }}
                            >
                              {line}
                            </Typography>
                          ))}
                        </ul>
                        <Divider
                          style={{
                            backgroundColor: `${
                              plan?.id === currentPlan?.data?.plan_id
                                ? currentPlan?.data?.cancelled === true &&
                                  dateLessThanExpiry
                                  ? "#ffffff59"
                                  : dateLessThanExpiry
                                  ? "#ffffff59"
                                  : "lightgray"
                                : plan?.id === "Surepdf-Basic-USD-Monthly" &&
                                  !currentPlan?.data?.plan_id
                                ? // !currentPlan?.data?.plan_id
                                  "#ffffff59"
                                : "lightgray"
                            }`,

                            marginTop: "10px",
                          }}
                        />
                      </CardContent>
                      <div
                        style={{
                          paddingLeft: "10px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}
                      >
                        <Typography variant="subtitle2" component="h1">
                          {/* {tier.footerDetails} */}
                        </Typography>
                      </div>
                      <CardActions
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {plan?.id === currentPlan?.data?.plan_id ? (
                          <>
                            {currentPlan?.data?.cancelled === true ? (
                              <>
                                {dateLessThanExpiry ? (
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.removeCancel}
                                    onClick={() =>
                                      handleRemoveCancelSchedule(
                                        currentPlan?.data?.plan_id
                                      )
                                    }
                                  >
                                    Remove Cancellation
                                  </Button>
                                ) : (
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    className={
                                      plan?.id ===
                                        "Surepdf-Basic-USD-Monthly" &&
                                      !currentPlan?.data?.plan_id
                                        ? classes.buyBtn_10
                                        : classes.buyBtn
                                    }
                                    onClick={() => {
                                      console.log(plan?.id)
                                      handleBuy(plan?.id)}}
                                  >
                                    Buy Plan
                                  </Button>
                                )}
                              </>
                            ) : (
                              <Button
                                // color="secondary"
                                variant="contained"
                                className={classes.cancelBtn}
                                onClick={handleCancel}
                                // disabled={
                                //   currentPlan?.data?.change_scheduled === true
                                //     ? true
                                //     : false
                                // }
                              >
                                Cancel Plan
                              </Button>
                            )}
                          </>
                        ) : (
                          <>
                            {currentPlan?.data?.plan_id ? (
                              <Button
                                color={
                                  currentPlan?.data?.change_scheduled ===
                                    true &&
                                  currentPlan?.data?.scheduled_plan_id ===
                                    plan?.id
                                    ? `secondary`
                                    : `primary`
                                }
                                variant="outlined"
                                className={
                                  currentPlan?.data?.change_scheduled ===
                                    true &&
                                  currentPlan?.data?.scheduled_plan_id ===
                                    plan?.id
                                    ? classes.cancelChangeButton
                                    : classes.changeBtn
                                }
                                onClick={() => {
                                  if (
                                    currentPlan?.data?.change_scheduled ===
                                      true &&
                                    currentPlan?.data?.scheduled_plan_id ===
                                      plan?.id
                                  ) {
                                    handleCancelChangePlan(
                                      currentPlan?.data?.scheduled_plan_id
                                    );
                                  } else {
                                    handleChangePlan(plan?.id);
                                  }
                                }}
                                disabled={
                                  currentPlan?.data?.cancelled === true
                                    ? true
                                    : false
                                }
                              >
                                {currentPlan?.data?.change_scheduled === true &&
                                currentPlan?.data?.scheduled_plan_id ===
                                  plan?.id
                                  ? `Cancel Changes`
                                  : `Change Plan`}
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                variant="contained"
                                className={
                                  plan?.id === "Surepdf-Basic-USD-Monthly" &&
                                  !currentPlan?.data?.plan_id
                                    ? classes.buyBtn_10
                                    : classes.buyBtn
                                }
                                onClick={() => {
                                  console.log(plan?.id)
                                  handleBuy(plan?.id)
                                }}
                              >
                                Buy Plan
                              </Button>
                            )}
                          </>
                        )}

                        {/* {plan?.id === currentPlan?.data?.plan_id &&
                  currentPlan?.data?.cancelled === false ? (
                    <Button
                      color="primary"
                      variant="contained"
                      style={{ color: "green" }}
                    >
                      Change Plan
                    </Button>
                  ) : null} */}

                        {/* <Button
                    className={classes.button}
                    onClick={handlePlan}
                    style={{
                      backgroundColor: `${
                        plan?.id === currentPlan?.data?.plan_id
                          ? "#ec1832"
                          : "#0084ec"
                      }`,
                      color: "#fff",
                    }}
                  >
                    {plan?.id === currentPlan?.data?.plan_id ? (
                      <>
                        {currentPlan?.data?.cancelled === true &&
                        moment(moment().format("DD MMM YYYY")) <
                          moment(
                            moment(currentPlan?.data?.expiry).format(
                              "DD MMM YYYY"
                            )
                          )
                          ? `Remove Cancellation Scheduled`
                          : `Cancelled`}
                      </>
                    ) : (
                      `Buy Plan`
                    )}
                  </Button>
               
                */}
                      </CardActions>
                    </Card>
                  </MuiThemeProvider>
                </Grid>
              ))}
            </Grid>
            <ModalBody
              setOpenModal={setOpenModal}
              openModal={openModal}
              open={open}
              setOpen={setOpen}
            />

            <ScheduleCancelModal
              handleClose={handleClose}
              openModal={openCancelSchedule}
              setOpenModal={setOpenCancelSchedule}
              plan_id={plan_id}
              // classes={classes}
            />

            <ChangeModal
              handleClose={handleClose}
              openModal={openChangeModal}
              setOpenModal={setOpenChangeModal}
              plan_id={plan_id}
              // classes={classes}
            />
            <CancleChangeModal
              handleClose={handleClose}
              openModal={openCancelChangeModal}
              setOpenModal={setOpenCancelChangeModal}
              plan_id={plan_id}
              // classes={classes}
            />
          </Container>
        </>
      ) : (
        <div className={classes.rootLinear}>
          <LinearProgress />
        </div>
      )}
      {/*<Container maxWidth="sm" component="main" className={classes.heroContent}>*/}

      {/* Footer */}

      {/* End footer */}
    </React.Fragment>
  );
}
