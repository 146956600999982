import { INIT_SIGN, ADD_SIGNERS } from "./action-types";
import { initSignCall, addSignersCall } from "./manager";

export function initSign(data) {
  return (dispatch) =>
    initSignCall(data)
      .then((result) => {
        dispatch({
          type: INIT_SIGN,
          payload: result.data,
        });
      })
      .catch((e) => {
        // console.log("error");
      });
}

export function addSigners(data) {
  return (dispatch) =>
    addSignersCall(data)
      .then((result) => {
        dispatch({
          type: ADD_SIGNERS,
          payload: result.data,
        });
      })
      .catch((e) => {
        // console.log("error");
      });
}
