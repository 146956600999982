import {
    Box, Button, Card, CardActions, CardContent, IconButton,
    Snackbar, Table, TableBody, TableCell, TableContainer, TableRow, Typography
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBackIosRounded, ArrowForwardIosRounded, HomeRounded, PermIdentityRounded } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { UiActions } from "globalUI";
import { withStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer - 1,
        color: '#fff',
    },
    pdfButton: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        justifyContent: "flex-start",
        textTransform: "none"
    },
    button: {
        marginRight: theme.spacing(3),
    },
    docDimen: {
        position: "relative",
        borderRadius: 4,
        margin: "2rem",
        marginTop: 0,
        overflow: "hidden",
        boxShadow: "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
    },
    navigation: {
        display: "flex",
        justifyContent: "space-between",
        position: "absolute",
        inset: 0,
    },
    pageInfo: {
        position: "absolute",
        top: 0,
        width: "100%",
        textAlign: "center",
        transition: "opacity 200ms ease",
        opacity: 0,
        zIndex: 3,
        "&:hover": {
            opacity: 0.9
        }
    }
}));

const StyledTableCell = withStyles((theme) => ({
    body: props => ({
        fontWeight: props.fontWeight,
        border: `1px solid ${theme.palette.grey[300]}`,
    }),
}))(TableCell);

// Final Success Page

function Reciept({ ...props }) {
    const classes = useStyles();

    const [DocPdf, setDocPdf] = useState();

    const [showSuccess, setShowSuccess] = useState(true)
    const [showStamp, setShowStamp] = useState(false)

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(null);

    useEffect(() => {
        showStamp ?
            setDocPdf(props.createData?.uploadedStamp?.stamp?.[0]) :
            setDocPdf(props.createData.document?.[0])
    }, [showStamp])


    useEffect(() => {
        return () => {
            props.setCreateData({});
            props.setInviteeData({});
        }
    }, [])


    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    return (
        <>
            <Box display="flex" alignItems="flex-start" m={3}>
                <Box mr={4} minWidth={"40vw"}>
                    <Document
                        file={DocPdf}
                        onLoadSuccess={onDocumentLoadSuccess}
                        error={<Typography color="secondary"><br /><br />An Error Occured...</Typography>}
                        loading={<Typography color="primary"><br /><br />Loading ...</Typography>}
                        noData={<Typography color="secondary"><br /><br />No PDF Found</Typography>}
                        renderMode="canvas"
                        className={!!pageNumber && classes.docDimen}
                    >
                        <Page
                            pageNumber={pageNumber ?? 1}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                            loading={<Typography color="primary"><br /><br />Loading Page ...</Typography>}
                        />
                        <Box className={classes.navigation}>
                            <IconButton style={{ borderRadius: 0 }} color="primary"
                                disabled={pageNumber <= 1}
                                onClick={() => setPageNumber(p => p - 1)}>
                                <ArrowBackIosRounded />
                            </IconButton>
                            <IconButton style={{ borderRadius: 0 }} color="primary"
                                disabled={pageNumber >= numPages}
                                onClick={() => setPageNumber(p => p + 1)}>
                                <ArrowForwardIosRounded />
                            </IconButton>
                        </Box>
                        <Box className={classes.pageInfo} p={3} >
                            <Typography color="primary">
                                Page {pageNumber} / {numPages}
                            </Typography>
                        </Box>
                    </Document>
                </Box>
                <Box mx={2} flexGrow={1} minWidth={350}>
                    <Snackbar open={showSuccess} autoHideDuration={2000} onClose={() => setShowSuccess(false)} >
                        <Alert severity="success" onClose={() => setShowSuccess(false)} >
                            Document created successfully
                        </Alert>
                    </Snackbar>
                    <Box display="flex">
                        {props.createData.stampType === "upload" &&
                            <Button color="primary" onClick={() => setShowStamp(p => !p)}>
                                Show {showStamp ? "Document" : "Stamp"}
                            </Button>
                        }
                        <Button color="primary" variant="outlined" component={Link} to="/"
                            style={{ marginLeft: "auto", textTransform: "none" }}
                            startIcon={<HomeRounded />}
                        >
                            Go Home
                        </Button>
                    </Box>
                    <br />
                    <br />
                    <TableContainer>
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <StyledTableCell fontWeight={500} >Document ID</StyledTableCell>
                                    <StyledTableCell>{"sdatvTWEY4"}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell fontWeight={500} >Name</StyledTableCell>
                                    <StyledTableCell>{props.createData?.documentName}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell fontWeight={500} >Folder</StyledTableCell>
                                    <StyledTableCell>{props.createData?.folder}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell fontWeight={500} >Last Activity Date</StyledTableCell>
                                    <StyledTableCell>{Date.now()}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell fontWeight={500} >Status</StyledTableCell>
                                    <StyledTableCell>{"Sent"}</StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                    {props.inviteeData?.invitees?.map((invt, idx) => (
                        <Card key={idx} style={{ margin: "1rem 0", maxWidth: "450px" }}>
                            <Box display="flex" alignItems="center" justifyContent="flex-start">
                                <Box m={2}>
                                    < PermIdentityRounded />
                                </Box>
                                <CardContent>
                                    <Typography gutterBottom variant="body1">{invt?.name}</Typography>
                                    <Typography gutterBottom variant="body2" color="textSecondary">
                                        {invt?.contact}
                                    </Typography>
                                    <Typography gutterBottom variant="caption" color="textSecondary">
                                        Sign: ❌ 	&nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp; Invitation Sent: ✅
                                    </Typography>
                                </CardContent>
                            </Box>
                            <CardActions style={{ background: grey[50] }}>
                                <Button size="small" variant="outlined">Resend Notification</Button>
                                {invt?.isUser &&
                                    <>
                                        <Button size="small" color="primary">Sign</Button>
                                        <Button size="small" color="secondary">Reject</Button>
                                    </>
                                }
                            </CardActions>
                        </Card>
                    ))}
                </Box>
            </Box>

        </>
    )
}


const mapStateToProps = (state) => ({
    createData: state.ui.createData,
    inviteeData: state.ui.inviteData
})

const mapDispatchToProps = {
    setCreateData: UiActions.setCreateData,
    setInviteeData: UiActions.setInviteData,
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Reciept)
);

