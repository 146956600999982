import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  Modal,
  CircularProgress,
  makeStyles,
  Snackbar,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { cancelModal, clearError, successModal, tokenList } from "auth/actions";

import {
  cancelSubscriptionPlan,
  changePlan,
  getCurrentPlanDetails,
} from "getDetails/actions";
import { CANCEL_SCHEDULE_MODAL } from "auth";
import { Alert } from "@material-ui/lab";
import { CHANGE_PLAN } from "getDetails";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

{
  /* <div>
    <ChangeModal
      classes={classes}
      handleClose={handleClose}
      handleChange={handleChange}
    />
  </div> */
}
{
  /* <Body openModal={openModal} closeModal={this.closeModal} /> */
}

const useStyles = makeStyles((theme) => ({
  paper1: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    outline: "none",
    boxShadow: theme.shadows[5],
    borderRadius: "10px",
    padding: "24px 25px 20px 24px",
  },
  modal: {
    backdropFilter: "blur(1px)",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: "#ffff",
    overflowY: "auto",
    outline: "none",
    borderRadius: "10px",
    padding: "24px 30px 20px",
    boxShadow: theme.shadows[5],
    // "& .focus-visible": {
    //   outline: "none",
    // },

    // padding: "1px 19px 1px",
  },
  text: {
    paddingBottom: "32px",
    fontSize: "25px",
    fontWeight: "600",
    lineHeight: "1.5",
  },
  danger: {
    background: "red",
    color: "rgb(255, 255, 255)",
    marginRight: "10px",
    fontSize: "12px",
    fontWeight: "600",
    "&:hover": {
      background: "red",
    },
  },
  change: {
    background: "#4263eb",
      color:"#fff",
    marginRight: "10px",
    fontSize: "12px",
    fontWeight: "600",
    "&:hover": {
      background: "#4263eb",
      color:"#fff"
    },
    
  }
}));

const ChangeModal = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  // const [openModal, setOpenModal] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const { cancelSchedule, cancelModalState } = useSelector(
    (state) => state.modalRed
  );
  const [error, setError] = useState("");
  const { errormesg, status_code, changePlanData } = useSelector(
    (state) => state.planReducer
  );
  const { currentPlan } = useSelector((state) => state.clientData);
  const handleChange = (e) => {
    // dispatch({
    //   type: CHANGE_PLAN,
    //   payload: null,
    // });

    // e.preventDefault();
    // dispatch(cancelModal(true));
    // setOpen(true);
    // dispatch(changePlan(props.planId));
    setOpen(true);

    dispatch(
      changePlan({
        plan_id: props.planId,
      })
    );

    dispatch(getCurrentPlanDetails());
    props.setOpenModal(false);
    // setTimeout(() => {
    // }, 400);
  };

  const handleClose = () => {
    props.setOpenModal(false);
    // props.setOpen(false);
  };

  //   const handleModal = (e) => {
  //     e.preventDefault();
  //     props.setOpenModal(true);
  //     // dispatch(cancelModal(true));
  //   };
  //   console.log("ChangePlan", changePlanData);
  useEffect(() => {
    setError(errormesg);
    // console.log("error",error)
    if (errormesg) {
      setOpen(false);
    }
  }, [errormesg]);
  useEffect(() => {
    let ignore = false;
    // console.log("Hello Changeplan");
    // setOpen(false)
    // console.log("open in changeModal", open);
    // console.log("currentplan",currentPlan?.data?.change_scheduled,currentPlan)
    // console.log("change",changePlanData?.data?.success)

    if (!ignore) {
      if (open) setTimeout(() => dispatch(getCurrentPlanDetails()), 1000);
    }
    // return () => {
      if (
        changePlanData?.data?.success === true
        // currentPlan?.data?.change_scheduled === true
      ) {
        // dispatch(cancelModal(false));
        setOpen(false);
        // console.log("Return", open);
        ignore = true;
      }
    // };
  }, [changePlanData]);
  console.log("Open", open);
  
  return (
    <>
      <Modal
        open={props.openModal}
        onClose={(event, reason) => handleClose(event, reason)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid container>
            <Grid container item justifyContent="center">
              <Typography
                variant="subtitle1"
                color="textPrimary"
                align="center"
                className={classes.text}
              >
                Do you really want to change this plan?
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Button
                variant="text"
                style={{
                  marginRight: "10px",
                  color: "#111",
                  /* font-size: 12px; */
                  fontWeight: "600",
                }}
                onClick={handleClose}
              >
                Dismiss
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.change}
                onClick={(e) => handleChange(e)}
              >
                Change Plan
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>

      {errormesg ? (
        <Snackbar
          open={!!error}
          autoHideDuration={3000}
          onClose={() => {
            setError(null);
            dispatch(clearError());
          }}
        >
          <Alert
            onClose={() => {
              setError(null);
              dispatch(clearError());
            }}
            severity="error"
          >
            {status_code}&nbsp;
            {error}
          </Alert>
        </Snackbar>
      ) : (
        <Modal open={open} className={classes.modal}>
          <div style={modalStyle} className={classes.paper1}>
            <Grid container justifyContent="space-between" >
              <Grid item xs={1}>
                <CircularProgress color="primary" />
                </Grid>
                <Grid item xs></Grid>
              <Grid item xs={10}>
                <Typography variant="body2">
                  Please wait while your subscription plan will be changed
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ChangeModal;
