import {
  Box,
  Button,
  Collapse,
  IconButton,
  Grid,
  Avatar,
  InputLabel,
  Link as LinkUI,
  makeStyles,
  Paper,
  Snackbar,
  TextField,
  createMuiTheme,
  LinearProgress,
  MuiThemeProvider,
  Typography,
  Divider,
} from "@material-ui/core";
import { Home } from "@material-ui/icons";
import { Alert,AlertTitle } from "@material-ui/lab";
import LoginSVG from "assets/login_creative.svg";
import {
  authActions,
  CLEAR_ERROR,
  CLEAR_OTP,
  OTP_SENT_FAILED,
  OTP_SENT_TRUE,
  SET_CLIENT_ID,
} from "auth";
import { clientActions } from "getDetails";

import React, { useEffect, useRef, useState } from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import { useHistory, withRouter } from "react-router";
import { Link, useLocation } from "react-router-dom";

import Logo from "../../../assets/logo-white.png";
import CodeIcon from "@material-ui/icons/Code";
import GoogleLogin from "assets/google_login.png";
import {getGoogleClient, googleLogin, sentOtp, signOutSuccess} from "../../../auth/actions";
import BackgroundHexagon from "assets/background-hexagon.svg";
import LoginGraphic from "assets/home-graphic.svg";
import GoogleLoginButton from "./GoogleLoginComp";
import {chargeebeeUrlApi, googleClientApi, googleLoginCall} from "../../../auth/manager";

const useStyles = makeStyles((theme) => ({
  googleLogin: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ccc",
    boxShadow:
      " 0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
    display: "flex",
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
    },
  },
  alertDiv:{
    position:"absolute",
    top:"10px",
    zIndex:"10000"
  },
  rootLinear: {
    width: "100%",

    margin: "0 auto",
    "& > * + *": {
      marginLeft: theme.spacing(1),
    },
  },
  fancyBg: {
    backgroundColor: "#edf2fe",
    // backgroundImage: " linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)",
    // backgroundImage: " linear-gradient(135deg, #000046 0%, #1CB5E0 100%)",
    // can add custom background for glassmorphism
    // backgroundImage: `url("${BackgroundHexagon}")`,
    // backgroundSize: "cover",
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center",
  },
  buttons: {
    // width: "60%",
    padding: "10px 32px",
    fontFamily: "AvetaBold",
    // backgroundImage:"linear-gradient(135deg, #000046 0%, #1CB5E0 100%)"
    backgroundColor: theme.palette.primary.main,
    borderRadius: "8px",
    fontWeight: "600",
    boxShadow: "0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
"&.MuiButton-containedPrimary:hover":{
      backgroundColor:"#fff",
  color:"#4263eb",
  border:"1px solid #4263eb"

}
  },
  homeBtn: {
    position: "absolute",
    bottom: 0,
    right: 0,
    margin: 10,
  },
  paperRoot: {
    position: "relative",
    width: "70%",
    minHeight: "80vh",
    margin: "20px 0px",
    display: "flex",
    // padding: "4rem",
    // backgroundColor: "rgba( 255, 255, 255, 0.4 )",
    boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: "blur(10px)",
    "-webkit-backdrop-filter": "blur( 10.0px )",
    borderRadius: "10px",
    // border: "1px solid rgba( 255, 255, 255, 0.18 )",
    zIndex: 100,
  },
  onboardRoot: {
    width: "40%",
    minHeight: "70vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "4rem",
    marginLeft: "-4px",
  },
  tokenInput: {
    width: "100%",
    margin: "10px 0 25px",
    borderRadius: 5,
    backgroundColor: "#ffffff66",
  },

  resendButton: {
    marginLeft: "16px",
  },
  cowinBold: {
    color: "#3C4AE4",
  },
  linearGradient: {
    width: "100%",
    position: "absolute",
    height: "100%",
  },
  whiteColor: {
    color: "#fff",
  },
}));

const theme = createMuiTheme({
  palette: {

    action: {

      disabled: '#4263eb'
    }
  },
  overrides: {
    MuiInputBase: {
      root: {
        padding: "6px 0",
      },
    },
  },
});

function Login(props) {
  const { getProductList } = props;

  const classes = useStyles();
  const {googleClientId}=useSelector(state=>state.auth)
  const dispatch = useDispatch();
  // const loginInput = useRef(null);
  const [googleClient,setGoogleClient]=useState("");

  const [loginInput, setLoginInput] = useState("");
  const [otpInput, setOtpInput] = useState("");
  const [sentOtpTimer, setSentOtpTimer] = useState(false);
  // const otpInput = useRef(null);
  const [error, setError] = useState(null);
  const [otp, setOtp] = useState(false);
  const [otpTimer, setOtpTimer] = useState(false);
  const search = useLocation().search;
  const [show, setShow] = useState(false);
  const[alert,showAlert]=useState(false)
  const [erroMsg,setErrorMessage]=useState("")
  const [erroMsg_1,setErrorMessage_1]=useState("")
  const [number, setNumber] = useState(30);
  const [resendNumber, setResendNumber] = useState(30);
  const [counterId,setCounterId]=useState()
  const [successMessage,setSuccessMessage]=useState()


  let resendCounter;
  let otpCounter;
  const history = useHistory();
  useEffect(()=>{
    const name = new URLSearchParams(search).get("plan");
    console.log(name)
    props.getPlanId(name);
    props.chargebeeDetails()
  },[])


  const stopCounter = () => clearInterval(resendCounter);
  const stopCounterOtp = () => clearInterval(otpCounter);

  useEffect(() => {
    if (props.onboardRequired && props.otpVerified) {
      history.push("get-onboard");
    }
  }, [props.onboardRequired, props.otpVerified]);

  const handleSendOTP = async (resend) => {
    dispatch({
      type: CLEAR_ERROR,
    });

    const { sentOtp } = props;
    const inp = loginInput;
    const validEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        inp
      );
    // const validPhone = /^\+?([0-9]{2})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{5})$/.test(inp) || /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(inp)

    let data = validEmail
      ? { email: inp,
          product:"nocodepdf"
    }
      : //   : validPhone
        //   ? { mobile: inp }
        null;
    if (data) {

      await sentOtp(data);
      setShow(true);
      setOtpTimer(true)
    } else {
      setError("Please add a valid Email");
    }

    return;
  };


  const handleResendOTP = async (resend) => {
    dispatch({
      type: CLEAR_ERROR,
    });
    dispatch({
      type: CLEAR_OTP,
      payload: false,
    })




    const { sentOtp } = props;

    await sentOtp({
      email:loginInput,
      product:"nocodepdf"
    })

    setOtpTimer(true)

    return;
  };
  const handleSubmitOTP = async () => {
    const { submitOtp } = props;
    const otp = otpInput;

    await submitOtp({
      otp,
      client_id: props.clientId,
      product:"nocodepdf",
    });
    setShow(true);

    return;
  };

  const onGoogleLogin = () => {
console.log("Callled googleLogin func")

    window.auth2.grantOfflineAccess().then(signInCallback).catch(err=>console.log(err));
  };


  function signInCallback(authResult) {
    console.log("called SigniCalback")
    let out = "";
    console.log("authResult",authResult)

    if (authResult["code"]) {
      out = authResult["code"];
    } else {
      out = "Error, check console";
    }
    sendGoogleLogin(out);
  }
  const sendGoogleLogin = async (code) => {
    console.log(code)
    console.log("called sendGoogleLogin")
    const { googleLogin } = props;
    let payload = {
      code,
      product:"nocodepdf" ,
    };
    await googleLogin(payload);
    return;
  };


  function startTimer(){
    resendCounter = setInterval(() => {

      setResendNumber((prevState) => prevState<=10?`0${prevState-1}`:prevState - 1);

      setCounterId(resendCounter)

    }, 1000);
  }

  useEffect(()=>{
    console.log("OtpSent",props.otpSent)

    if(props.otpSent){
      console.log("OtpSent",props.otpSent)
      setSuccessMessage('OTP has sent in your mail, Please check your mail.')
      startTimer()
      // setOtpTimer(true)
    }

  },[props.otpSent])

  useEffect(() => {
    console.log("Otp Timer true",)
    console.log("resendNumber",resendNumber)


    if (resendNumber === '00') {
      console.log("resendNumber",resendNumber)
      clearInterval(counterId)
      resendCounter=0
      setResendNumber(30)

      setOtpTimer(false);

    }


  }, [resendNumber]);

  useEffect(() => {
    setError(props.errorMsg)
    setShow(false)
    setOtpTimer(false)
    clearInterval(counterId)
    setResendNumber(30)
  }, [props.errorMsg])
  useEffect(() => {
    if (loginInput === "") {
      dispatch({
        type: SET_CLIENT_ID,
        payload: "",
      });

      dispatch({
        type: CLEAR_OTP,
        payload: false,
      });
    }
  }, []);
  
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      className={classes.fancyBg}
    >

      <Snackbar open={!!successMessage} autoHideDuration={3000} onClose={() => {setSuccessMessage(null)
        dispatch({
          type: CLEAR_ERROR,
        });
        dispatch({
          type: CLEAR_OTP,
          payload: false,
        });


      }}>
        <Alert onClose={() => {setSuccessMessage(null)
          dispatch({
            type: CLEAR_ERROR,
          });
          dispatch({
            type: CLEAR_OTP,
            payload: false,
          });
        }} severity='success'>
          {successMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!error}
        autoHideDuration={3000}
        onClose={() => {
          setError(null);
          dispatch({
            type: CLEAR_ERROR,
          });
        }}
      >
        <Alert
          onClose={() => {
            setError(null);
            dispatch({
              type: CLEAR_ERROR,
            });
          }}
          severity="error"
        >
          {error}
        </Alert>
      </Snackbar>


      {alert && (
          <div className={classes.alertDiv} >
            <Alert severity="error" onClose={()=>{showAlert(false)
           setErrorMessage("")
              setErrorMessage_1("")
            }}>
              <AlertTitle>{erroMsg}</AlertTitle>
              {erroMsg_1}
            </Alert>
          </div>


      )}
      <Paper className={classes.paperRoot}>

        {/* <IconButton
          color="secondary"
          className={classes.homeBtn}
          component={Link}
          to="/"
        >
          <Home />
        </IconButton> */}

        <Box
          display="flex"
          justifyContent="center"
          width="50%"
          // p={1}
          style={{
            position: "relative",
            background: "#4263eb",
            borderBottomLeftRadius: "10px",
            borderTopLeftRadius: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/*<Grid container className={classes.linearGradient}></Grid>*/}

          {/*<img src={LoginSVG} width="100%" />*/}

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: 40,
            }}
          >
            <Typography
              className={classes.whiteColor}
              variant={"h4"}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Welcome to
              {/*<span style={{*/}
              {/*  display:"flex",*/}
              {/*  alignItems:"center",*/}
              {/*  justifyContent:"center",*/}
              {/*  marginLeft:10,*/}
              {/*  fontWeight:"bold"*/}
              {/*}}>*/}
              {/*                No*/}
              {/*                <CodeIcon style={{ fontSize: "60" }} className={classes.whiteColor} />*/}
              {/*                Pdf*/}
              {/*</span>*/}
              <img src={Logo} style={{ padding: 4, height: "3.2rem" }} />
            </Typography>

            <br />
            <Divider
              style={{
                height: "3px",
                background: "#fff",
                width: "30%",
              }}
            />
            <br />
            <img src={LoginGraphic} style={{ width: "70%" }} />
            <br />
            <Typography
              variant={"subtitle1"}
              align={"center"}
              className={classes.whiteColor}
            >
              No code Pdf is a 1-click tool to convert
              <br /> any webpage into a PDF.
            </Typography>

            {/*<Typography variant={"subtitle1"} align={"center"} className={classes.whiteColor}>*/}
            {/*  Transform your HTML data into PDF.<br/> Only you have to integrate our API with*/}
            {/*  <br/>your product.*/}
            {/*</Typography>*/}
          </Box>
          {/*<b style={{ marginLeft: 10 }}>No</b>*/}
          {/*<b>Pdf</b>*/}
        </Box>
        <Box width="50%">
           {props.sentOtpLoader && (
            <div className={classes.rootLinear}>
              <LinearProgress />
            </div>
          )}
          <Box
            width="100%"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            py={5}
            px={4}
          >
            <Typography
              variant="h4"
              style={{
                marginBottom: 40,
                display: "flex",
                alignItems: "center",
                fontWeight: "600",
                letterSpacing: "0px",
                color: "#000000",
                opacity: "1",
              }}
            >
              {/*Welcome to <b style={{ marginLeft: 10 }}>No</b>*/}
              {/*<CodeIcon style={{ fontSize: "60" }} color={"primary"} />*/}
              {/*<b>Pdf</b>*/}
              Sign in to Account
            </Typography>

            <form onSubmit={(e) => e.preventDefault()}>
              <InputLabel
                htmlFor="phoneNo"
                style={{ fontWeight: "400", color: "#000" }}
              >
                Enter your Email
              </InputLabel>
              <MuiThemeProvider theme={theme}>
                <TextField
                  size="small"
                  variant="outlined"
                  id="phoneNo"
                  className={classes.tokenInput}
                  // inputRef={loginInput}
                  value={loginInput}
                  name="loginInput"
                  onChange={(e) => {
                    setLoginInput(e.target.value);
                    dispatch({
                      type: SET_CLIENT_ID,
                      payload: "",
                    });
                  }}
                  error={!!error}
                  placeholder={"ex:abc@nocodepdf.com"}
                  autoComplete="on"
                  required
                />
                <Collapse in={!!props.clientId} unmountOnExit>
                  <InputLabel htmlFor="otp">Enter OTP</InputLabel>
                  <TextField
                    size="small"
                    variant="outlined"
                    id="otp"
                    className={classes.tokenInput}
                    autoComplete="off"
                    // inputRef={otpInput}
                    value={otpInput}
                    onChange={(e) => {
                      setOtpInput(e.target.value);
                    }}
                    error={!!error}
                    autoFocus
                    required
                  />
                  {/* <Typography variant="caption" style={{ color: "#444" }}>
                Otp sent! Check your Inbox
              </Typography> */}
                </Collapse>
              </MuiThemeProvider>
              <br />
              {!props.clientId ? (
                <Grid container justifyContent="center">
                  <Button
                    variant="contained"
                    size={"large"}
                    color="primary"
                    onClick={handleSendOTP}
                    className={classes.buttons}
                    type="submit"
                    // disabled={!show?false:true}
                  >
                    Proceed
                  </Button>
                </Grid>
              ) : (
                <Grid container justifyContent={`center`}>
                  <Grid item>
                    <Button
                      variant="contained"
                      size={"large"}
                      // fullWidth
                      color="primary"
                      className={classes.buttons}
                      onClick={handleSubmitOTP}
                      type="submit"

                      // disabled={!show ?false:true}
                    >
                      Log in
                    </Button>
                  </Grid>
<Grid item>
                  <MuiThemeProvider theme={otpTimer?theme:''}>
                    <Button
                        variant="text"
                        size={"large"}
                        color="primary"
                        onClick={() => {
                          let resend = true;
                          handleResendOTP(resend);
                        }}
                        disabled={otpTimer}
                        className={classes.resendButton}

                        // type="submit"
                    >
                      {otpTimer?`00:${resendNumber}`: `Resend OTP`}
                    </Button>
                  </MuiThemeProvider>
                  </Grid>
                </Grid>
              )}




              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ margin: "2px 0" }}
              >
                <Grid
                  item
                  style={{
                    width: "100px",
                    height: "2px",
                    background: "#111111bf",
                  }}
                ></Grid>
                <Grid item>
                  <Typography
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      padding: "0px 4px",
                    }}
                    align={"center"}
                  >
                    OR
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{
                    width: "100px",
                    height: "2px",
                    background: "#111111bf",
                  }}
                ></Grid>
              </Grid>

              <Grid container justifyContent="center">
                <GoogleLoginButton googleClient={googleClient} setErrorMessage={setErrorMessage} setErrorMessage_1={setErrorMessage_1} showAlert={showAlert} />

                {/*<Button*/}
                {/*  variant={"contained"}*/}
                {/*  className={classes.googleLogin}*/}
                {/*  // fullWidth*/}
                {/*  style={{*/}
                {/*    width: "100%",*/}
                {/*    padding: "10px 32px",*/}
                {/*    fontFamily: "AvertaRegular",*/}
                {/*    fontWeight: "600",*/}
                {/*  }}*/}
                {/*  onClick={onGoogleLogin}*/}
                {/*  startIcon={<Avatar src={GoogleLogin} />}*/}
                {/*>*/}
                {/*  Sign in with Google*/}
                {/*</Button>*/}
              </Grid>
              <br />
              <Grid container justifyContent="center">
                <Typography variant="caption" style={{ color: "#444" }}>
                  <em>
                    By clicking proceed account I agree to &nbsp;
                    <LinkUI href={"https://nocodepdf.com/terms-of-service/"} target={"_blank"}>Terms & Conditions</LinkUI>
                  </em>
                </Typography>
              </Grid>
            </form>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
  id: state.auth.id,
  otpSent: state.auth.otpSent,
  statusCode: state.auth.statusCode,
  otpVerified: state.auth.otpVerified,
  onboardRequired: state.auth.onboardRequired,
  phone_number: state.auth.phone_number,
  sentOtpLoader:state.auth.sentOtpLoader,
  email: state.auth.email,
  errorMsg: state.auth.errorMsg,
  fullname: state.auth.fullname,
  clientId: state.auth.clientId,
});

const mapDispatchToProps = {
  sentOtp: authActions.sentOtp,
  submitOtp: authActions.submitOtp,
  googleLogin: authActions.googleLogin,
  chargebeeDetails:authActions.getChargebeeDetails,
  getPlanId: clientActions.getPlanID,
  checkOut: clientActions.checkOut,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
