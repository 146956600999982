import {
  Box,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import { UiActions } from "globalUI";
import MUIDataTable from "mui-datatables";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { DatePicker } from "views/components/date-picker/DatePicker";

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: `0`,
      },
    },
    "& .MuiInputBase-root": {
      fontSize: "0.9rem",
    },
    "& .MuiFormLabel-root": {
      fontSize: "0.9rem",
    },
  },
})(TextField);

const SignHistory = () => {
  const theme = useTheme();

  const changeTableStyle = () =>
    createMuiTheme({
      palette: theme.palette,
      overrides: {
        MUIDataTableToolbar: {
          root: {
            display: "none",
          },
        },
        MUIDataTableBodyCell: {
          root: {
            color: theme.palette.primary.main,
            fontSize: "14px",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            marginLeft: "auto",
            // borderTop: "1px solid #ccc",
            backgroundColor: "#f7f8fb !important",
            color: theme.palette.primary.main,
            fontSize: "14px",
            fontWeight: 600,
          },
        },
        MuiTableRow: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "#fafbfe !important",
            },
            "&:nth-child(odd)": {
              backgroundColor: "#ffffff !important",
            },
          },
          hover: {
            "&:hover": {
              backgroundColor: "rgba(122, 139, 237, 0.1)!important",
            },
          },
        },
        MuiPaper: {
          root: {
            border: `1px solid ${theme.palette.grey[300]}`,
          },
          rounded: {
            borderRadius: 0,
          },
        },
        MuiToolbar: {
          root: {
            position: "static",
            paddingLeft: "15px !important",
          },
        },
        MuiTableCell: {
          paddingCheckbox: {
            display: "none",
          },
          root: {
            "&:not(:nth-last-child(1))": {
              borderRight: `1px solid ${theme.palette.grey[300]}`,
            },
            padding: "10px !important",
          },
          head: {
            padding: "10px !important",
          },
          footer: {
            border: 0,
          },
        },
        MUIDataTablePagination: {
          tableCellContainer: {
            paddingTop: "0 !important",
            paddingBottom: "0 !important",
          },
        },
      },
    });

  const options = {
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20, 50],
    jumpToPage: true,
    pagination: true,
    elevation: 0,
    selectableRows: "none",
    responsive: "simple",
    sort: false,
  };

  const columns = [
    {
      name: "id",
      label: "#",
      options: {
        empty: true,
        customBodyRender: (d, tableMeta) => (
          <Typography variant="subtitle2" color="textPrimary">
            {tableMeta.rowIndex + 1}.{" "}
          </Typography>
        ),
      },
    },
    {
      name: "denomination",
      label: "Transaction",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (d) => (
          <>
            <Typography variant="body1" color="textPrimary">
              {d || "-"}
            </Typography>
          </>
        ),
      },
    },
    {
      name: "state",
      label: "Date",
      options: {
        customBodyRender: (d) => (
          <>
            <Typography variant="body1" color="textPrimary">
              {d || "-"}
            </Typography>
          </>
        ),
      },
    },
    {
      name: "total",
      label: "Remark",
      options: {
        customBodyRender: (d) => (
          <>
            <Typography variant="body1" color="textPrimary">
              {d || "-"}
            </Typography>
          </>
        ),
      },
    },
    {
      name: "used",
      label: "Debit",
      options: {
        customBodyRender: (d) => (
          <>
            <Typography variant="body1" color="textPrimary">
              {d || "-"}
            </Typography>
          </>
        ),
      },
    },
    {
      name: "unused",
      label: "Credit",
      options: {
        customBodyRender: (d) => (
          <>
            <Typography variant="body1" color="textPrimary">
              {d || "-"}
            </Typography>
          </>
        ),
      },
    },
    {
      name: "blocked",
      label: "Balance",
      options: {
        customBodyRender: (d) => (
          <>
            <Typography variant="body1" color="textPrimary">
              {d || "-"}
            </Typography>
          </>
        ),
      },
    },
  ];

  return (
    <>
      <Box mt={3} mb={2} display="flex" justifyContent="space-between">
        <div>
          <Typography variant="h6" color="textPrimary" gutterBottom>
            Current eSign Balance is: 0.00
          </Typography>
          <Typography variant="body1" color="textPrimary" gutterBottom>
            Current Reserved eSign Balance is: 0.00
          </Typography>
        </div>
        <div>
          <DatePicker rightAlign={true} />
        </div>
      </Box>
      <Box my={4}>
        <Box
          my={1}
          mt={-3}
          display="flex"
          justifyContent="flex-end"
          style={{ gap: 20 }}
        >
          <CustomTextField
            variant="outlined"
            size="small"
            style={{ width: 200 }}
            select
            label=" Transaction Type"
            defaultValue="all"
            // value={transactionType}
            // onChange={e => setTransactionType(e.target.value)}
          >
            <MenuItem dense={true} value="all">
              All
            </MenuItem>
            <MenuItem dense={true} value="credit">
              Credit
            </MenuItem>
            <MenuItem dense={true} value="debit">
              Debit
            </MenuItem>
            <MenuItem dense={true} value="reserve">
              Reserve
            </MenuItem>
            <MenuItem dense={true} value="release">
              Release
            </MenuItem>
          </CustomTextField>
          <CustomTextField
            variant="outlined"
            size="small"
            style={{ width: 200 }}
            select
            label=" Balance Type"
            defaultValue="eSign"
            // value={transactionType}
            // onChange={e => setTransactionType(e.target.value)}
          >
            <MenuItem dense={true} value="eSign">
              eSignBalance
            </MenuItem>
            <MenuItem dense={true} value="subscription">
              Subscription Balance
            </MenuItem>
          </CustomTextField>
        </Box>
        <MuiThemeProvider theme={changeTableStyle()}>
          <MUIDataTable
            title={<Typography>eSign History</Typography>}
            data={[]}
            // columns={columns}
            // options={options}
          />
        </MuiThemeProvider>
      </Box>
    </>
  );
};
const mapStateToProps = (state) => ({
  createData: state.ui.createData,
  inviteeData: state.ui.inviteData,
});

const mapDispatchToProps = {
  setCreateData: UiActions.setCreateData,
  setInviteeData: UiActions.setInviteData,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SignHistory)
);
