import {
  Backdrop,
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  FormControlLabel,
  Grid,
  TableBody,
  IconButton,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { CloseRounded } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import { UiActions } from "globalUI";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import FileInput from "views/components/drag-drop";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
    color: "#fff",
  },
  button: {
    marginLeft: theme.spacing(4),
    textTransform: "none",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  body: (props) => ({
    fontWeight: props.fontWeight,
    border: `1px solid ${theme.palette.grey[300]}`,
  }),
}))(TableCell);

function Template({ formMethods, ...props }) {
  const classes = useStyles();

  const [openUploadDrawer, setOpenUploadDrawer] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    control,
    errors,
    setError,
    clearErrors,
    setValue,
  } = formMethods;

  const stampType = watch("stampType");
  const uploadedStamp = watch("uploadedStamp");

  useEffect(() => {
    clearErrors(["uploadedStamp", "signEstamp"]);
  }, [stampType]);

  const handleUploadStamp = (data) => {
    setOpenUploadDrawer(false);
  };

  const cancelStamp = () => {
    // Object.keys(uploadedStamp).forEach((it) => (
    //     setValue(`uploadedStamp.${it}`, null)
    // ))
    setOpenUploadDrawer(false);
  };

  return (
    <>
      <Grid container item xs={5} alignItems="flex-start">
        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom>
            Choose Template *
          </Typography>
          <TextField
            required
            inputRef={register({ required: true })}
            error={!!errors?.template}
            name="template"
            placeholder="choose template"
            variant="outlined"
            autoComplete="off"
            fullWidth
          />
          <br />
          <br />
        </Grid>
        <Grid item xs={10} style={{ margin: "20px 10px" }}>
          <Collapse in={stampType === "upload"}>
            <TableContainer>
              <Typography variant="body2" gutterBottom>
                Uploaded Stamp
              </Typography>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <StyledTableCell fontWeight={500}>State</StyledTableCell>
                    <StyledTableCell>
                      {uploadedStamp?.state || "-"}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell fontWeight={500}>
                      Denomination
                    </StyledTableCell>
                    <StyledTableCell>
                      {uploadedStamp?.denomination || "-"}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell fontWeight={500}>
                      First Party Name
                    </StyledTableCell>
                    <StyledTableCell>
                      {uploadedStamp?.firstPartyName || "-"}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell fontWeight={500}>
                      Second Party Name
                    </StyledTableCell>
                    <StyledTableCell>
                      {uploadedStamp?.secondPartyName || "-"}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell fontWeight={500}>
                      Stamp Serial Number
                    </StyledTableCell>
                    <StyledTableCell>
                      {uploadedStamp?.stampSrNo || "-"}
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={6}
        justifyContent="space-between"
        alignItems="flex-start"
        style={{ paddingLeft: "2rem", marginRight: "-3rem" }}
      >
        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom>
            Name *
          </Typography>
          <TextField
            required
            inputRef={register({ required: true })}
            error={!!errors?.documentName}
            name="documentName"
            placeholder="document name here!"
            variant="outlined"
            autoComplete="off"
            fullWidth
          />
          <br />
          <br />
        </Grid>
        <Grid item xs={6}>
          <Typography color="textPrimary" gutterBottom>
            Internal Reference Number
          </Typography>
          <TextField
            size="small"
            inputRef={register}
            name="refNo"
            placeholder="Internal reference no."
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={5}>
          <Typography color="textPrimary" gutterBottom>
            Select Folder
          </Typography>
          <TextField
            size="small"
            inputRef={register}
            name="folder"
            defaultValue="none"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <br />
          <br />
          <Controller
            as={
              <RadioGroup
                aria-label="stampType"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "2rem",
                  color: grey[800],
                }}
              >
                <FormControlLabel
                  value="use"
                  control={<Radio />}
                  label="Use Stamp"
                />
                <FormControlLabel
                  value="upload"
                  control={<Radio />}
                  label="Upload Stamp"
                />
                <FormControlLabel
                  value="none"
                  control={<Radio />}
                  label="No Stamp"
                />
              </RadioGroup>
            }
            name="stampType"
            control={control}
            defaultValue={"none"}
          />
        </Grid>
        <Grid item xs={5}>
          <br />
          <Collapse in={stampType === "use"}>
            <>
              <Typography color="textPrimary" gutterBottom>
                Select Stamp Series
              </Typography>
              <TextField
                size="small"
                error={!!errors?.signEstamp}
                inputRef={register({ required: stampType === "use" })}
                name="signEstamp"
                variant="outlined"
                fullWidth
              />
            </>
          </Collapse>
          <Collapse in={stampType === "upload"}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenUploadDrawer(true)}
              style={{ textTransform: "none" }}
            >
              Upload Stamp
            </Button>
          </Collapse>
          <br />
          {(errors?.signEstamp || errors?.uploadedStamp) && (
            <Typography variant="caption" color="secondary">
              Stamp is Required
            </Typography>
          )}
          <br />
          <br />
        </Grid>
      </Grid>

      {/* Drawer */}
      <Backdrop
        className={classes.backdrop}
        open={openUploadDrawer}
        onClick={() => setOpenUploadDrawer(false)}
      />
      <Drawer
        anchor="right"
        open={openUploadDrawer}
        variant="persistent"
        classes={{ paperAnchorDockedRight: "no-border-drawer" }}
      >
        <Box
          width={500}
          role="presentation"
          display="flex"
          flexDirection="column"
          height="100%"
        >
          <Box
            position="sticky"
            top={0}
            zIndex={10}
            display="flex"
            flexGrow={"auto"}
            py={1}
            px={2}
            justifyContent="space-between"
            alignItems="center"
            bgcolor="primary.main"
            color="primary.contrastText"
          >
            <Typography variant="h5">Upload Stamp</Typography>
            <IconButton
              onClick={() => setOpenUploadDrawer(false)}
              style={{ color: "white" }}
            >
              <CloseRounded />
            </IconButton>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            p={2}
            style={{ gap: "1.5rem" }}
          >
            <Controller
              render={({ onChange, onBlur, value, name, ref }) => (
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  error={!!errors?.uploadedStamp?.state}
                  onBlur={onBlur}
                  label="State"
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  inputRef={ref}
                />
              )}
              control={control}
              name="uploadedStamp.state"
              rules={{ required: stampType === "upload" }}
              defaultValue={""}
            />
            <Controller
              render={({ onChange, onBlur, value, name, ref }) => (
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  type="number"
                  error={!!errors?.uploadedStamp?.denomination}
                  onBlur={onBlur}
                  label="Denomination"
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  inputRef={ref}
                />
              )}
              control={control}
              name="uploadedStamp.denomination"
              rules={{ required: stampType === "upload" }}
              defaultValue={""}
            />
            <Controller
              render={({ onChange, onBlur, value, name, ref }) => (
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  error={!!errors?.uploadedStamp?.firstPartyName}
                  onBlur={onBlur}
                  label="First Party's Name"
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  inputRef={ref}
                />
              )}
              control={control}
              name="uploadedStamp.firstPartyName"
              rules={{ required: stampType === "upload" }}
              defaultValue={""}
            />
            <Controller
              render={({ onChange, onBlur, value, name, ref }) => (
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  error={!!errors?.uploadedStamp?.secondPartyName}
                  onBlur={onBlur}
                  label="Second Party Name"
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  inputRef={ref}
                />
              )}
              control={control}
              name="uploadedStamp.secondPartyName"
              rules={{ required: stampType === "upload" }}
              defaultValue={""}
            />
            <Controller
              render={({ onChange, onBlur, value, name, ref }) => (
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  error={!!errors?.uploadedStamp?.stampSrNo}
                  onBlur={onBlur}
                  label="Stamp Serial Number"
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  inputRef={ref}
                />
              )}
              control={control}
              name="uploadedStamp.stampSrNo"
              rules={{ required: stampType === "upload" }}
              defaultValue={""}
            />
            <div>
              <Typography variant="body2" gutterBottom>
                {" "}
                Upload Stamp File :{" "}
              </Typography>
              <FileInput
                name="uploadedStamp.stamp"
                control={control}
                required={stampType === "upload"}
                small={true}
                error={!!errors?.uploadedStamp?.stamp}
              />
            </div>
          </Box>
          <Box
            component={Paper}
            square
            variant="outlined"
            position="sticky"
            mt={"auto"}
            bottom={0}
            zIndex={10}
            display="flex"
            flexGrow={"auto"}
            py={2}
            px={3}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              color="primary"
              className={classes.button}
              onClick={cancelStamp}
            >
              {" "}
              Cancel
            </Button>
            <Button
              color="primary"
              className={classes.button}
              variant="contained"
              onClick={handleSubmit(handleUploadStamp)}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}

const mapStateToProps = (state) => ({
  createData: state.ui.createData,
});

const mapDispatchToProps = {
  setCreateData: UiActions.setCreateData,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Template)
);
