import {
  Backdrop,
  Box,
  Button,
  Chip,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { purple, teal } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import {
  AddCircleOutlineRounded,
  CloseRounded,
  DoneAllOutlined,
  GetAppRounded,
  GpsFixedRounded,
  GroupRounded,
  OpenWithRounded,
  RotateLeftRounded,
  TrendingUpRounded,
} from "@material-ui/icons";
import Pagination from "@material-ui/lab/Pagination";
import { withStyles } from "@material-ui/styles";
import signCursor from "assets/sign.png";
import { initSignActions } from "CreateDoc";
import { PDFDocument } from "pdf-lib";
import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { Document, Page, pdfjs } from "react-pdf";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { v4 as uuidv4 } from "uuid";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
    color: "#fff",
  },
  pdfContainer: {
    position: "relative",
    height: "100%",
    // background: "#fff",
  },
  pdfButton: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    justifyContent: "flex-start",
    textTransform: "none",
  },
  button: {
    marginRight: theme.spacing(3),
  },
  docDimen: {
    borderRadius: 4,
    overflow: "hidden",
    minHeight: "100vh",
    boxShadow:
      "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
  },
  docDimenCursor: {
    borderRadius: 4,
    overflow: "hidden",
    minHeight: "100vh",
    boxShadow:
      "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
    cursor: `url(${signCursor}) 20 32, crosshair`,
  },
  pageDiv: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  signBox: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 10px",
    background: "rgba( 249, 249, 255, 0.50 )",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
    backdropFilter: "blur( 4.0px )",
    borderRadius: "1px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
  },
  chipDiv: {
    position: "absolute",
    top: 0,
    // left: 20,
    textAlign: "center",
    padding: "10px 20px",
    transition: "opacity 200ms ease",
    opacity: 0,
    "&:hover": {
      opacity: 0.9,
    },
  },
  stampChip: {
    color: purple[600],
    backgroundColor: purple[50],
    border: `1px solid ${purple[100]}`,
    borderRadius: 2,
  },
  docChip: {
    color: teal[600],
    backgroundColor: teal[50],
    border: `1px solid ${teal[100]}`,
    borderRadius: 2,
  },
}));

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: `0`,
      },
    },
    "& .MuiInputBase-root": {
      fontSize: "0.9rem",
    },
    "& .MuiFormLabel-root": {
      fontSize: "0.9rem",
    },
  },
})(TextField);

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    // boxShadow: theme.shadows[1],
    border: `1px solid ${theme.palette.grey[100]}`,
    fontSize: 11,
    padding: "0.5rem",
  },
}))(Tooltip);

function Finish({ setActiveStep, ...props }) {
  const classes = useStyles();

  // Pdf to render
  const [DocPdf, setDocPdf] = useState("");
  // Total number of pages in pdf
  const [numPages, setNumPages] = useState(null);
  // To cause force rerender
  const [, setCounter] = useState(0); // this is not gibberish, it's important
  // Current page , user is on
  const [pageNumber, setPageNumber] = useState(null);
  // Open sideDrawer to view Invitees List
  const [viewInvitees, setViewInvitees] = useState(false);
  // Allow user to add coordinates
  const [customCoordinates, setCustomCoordinates] = useState(false);
  // state change for feedback display
  // when coordinates are duplicated to other pages
  const [copyCoordinates, setCopyCoordinates] = useState(false);

  //  All sign state
  const [signCords, setSignCords] = useState({});

  const ref = useRef(null);
  const numOfStampPages = useRef(0);

  // merge the selected document + stamp if choosed/uploaded
  useEffect(() => {
    const mergePDF = async (stampURL, mainURL) => {
      const mergedPdf = await PDFDocument.create();

      const stampBytes = await fetch(stampURL).then((res) => res.arrayBuffer());
      const mainBytes = await fetch(mainURL).then((res) => res.arrayBuffer());

      const pdfA = await PDFDocument.load(stampBytes);
      const pdfB = await PDFDocument.load(mainBytes);

      numOfStampPages.current = pdfA.getPageCount();

      const copiedPagesA = await mergedPdf.copyPages(
        pdfA,
        pdfA.getPageIndices()
      );
      copiedPagesA.forEach((page) => mergedPdf.addPage(page));

      const copiedPagesB = await mergedPdf.copyPages(
        pdfB,
        pdfB.getPageIndices()
      );
      copiedPagesB.forEach((page) => mergedPdf.addPage(page));

      const mergedPdfFile = await mergedPdf.save();
      return mergedPdfFile;
    };

    if (
      props.createData.stampType === "upload" &&
      !!props.createData.uploadTab
    ) {
      const A = props.createData.uploadedStamp.stamp?.[0];
      const B = props.createData.document?.[0];
      const stampURL = window.URL.createObjectURL(
        new Blob([A], { type: "application/pdf" })
      );
      const mainURL = window.URL.createObjectURL(
        new Blob([B], { type: "application/pdf" })
      );

      mergePDF(stampURL, mainURL).then((pdf) => {
        let blob = new Blob([pdf], { type: "application/pdf" });
        let url = window.URL.createObjectURL(blob);
        setDocPdf(url);
      });
    } else {
      setDocPdf(props.createData.document?.[0]);
    }
  }, []);

  //  adding event listeners when
  // pdf canvas is rendered and option for custom coordinates is true
  useEffect(() => {
    const cnvs = ref.current?.childNodes[0];

    if (
      cnvs?.classList.contains("react-pdf__Page__canvas") &&
      customCoordinates
    ) {
      cnvs.addEventListener("click", setPosition);
    }

    return () => {
      if (cnvs) {
        cnvs.removeEventListener("click", setPosition);
      }
    };
  }, [ref.current?.childNodes[0], customCoordinates]);

  //  reading coordinates of the click (eventListner)
  const setPosition = (evnt) => {
    if (evnt.target instanceof Element) {
      const PdfLayer = evnt.target;
      if (PdfLayer) {
        const rect = PdfLayer.getBoundingClientRect();
        const x = evnt.clientX - rect.left;
        const y = rect.bottom - evnt.clientY;
        const ptX = Math.abs(Math.floor(x));
        const ptY = Math.abs(Math.floor(y));
        const objKey = `${pageNumber}`;
        setSignCords((prev) => ({
          ...prev,
          [objKey]: {
            ...prev?.[objKey],
            [uuidv4()]: {
              x: ptX,
              y: ptY,
              invitee: `${props?.inviteeData?.invitees[0]?.name}_${props?.inviteeData?.invitees[0]?.contact}`,
            },
          },
        }));
      }
    }
  };

  //  PDF
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const downloadFile = () => {
    if (typeof DocPdf === "string") window.open(DocPdf);
    else {
      let blob = new Blob([DocPdf], { type: "application/pdf" });
      let url = window.URL.createObjectURL(blob);
      window.open(url);
    }
  };

  // Page Navigation
  const changePage = (e, value) => {
    setPageNumber(value);
    e.preventDefault();
  };

  // Remove Selected Sign
  const deleteSign = (signId) => {
    const objKey = `${pageNumber}`;
    setSignCords((prev) => {
      delete prev?.[objKey]?.[signId];
      return {
        ...prev,
      };
    });
  };

  // Remove All sign on current page
  const deleteAllSign = () => {
    const objKey = `${pageNumber}`;
    setSignCords((prev) => ({
      ...prev,
      [objKey]: {},
    }));
  };

  // Change Invitee for the sign
  const selectInvitee = (signId, value) => {
    const objKey = `${pageNumber}`;
    setSignCords((prev) => ({
      ...prev,
      [objKey]: {
        ...prev?.[objKey],
        [signId]: {
          ...prev?.[objKey]?.[signId],
          invitee: value,
        },
      },
    }));
  };

  //  Add Coordinates to parent/main data
  const addNewCords = (e, data, signId) => {
    const objKey = `${pageNumber}`;
    setSignCords((prev) => ({
      ...prev,
      [objKey]: {
        ...prev?.[objKey],
        [signId]: {
          ...prev?.[objKey]?.[signId],
          x: data.x,
          y: -data.y,
        },
      },
    }));
  };

  //  Duplicate current Page sign coordinates to all other pages
  const copyToAllCords = () => {
    setCopyCoordinates(true);

    const objKey = `${pageNumber}`;
    const cords = signCords?.[objKey];

    const keys = Array.from({ length: numPages }, (_, i) => `${i + 1}`);
    let signs = {};

    for (let k of keys) {
      signs = {
        ...signs,
        [k]: { ...cords },
      };
    }

    setSignCords((prev) => ({
      ...signs,
    }));

    setTimeout(() => {
      setCopyCoordinates(false);
    }, 1000);
  };

  // download json file of selected signers and coordinates
  const downloadCords = () => {
    const data = Object.keys(signCords)?.map((k) => ({
      [k]: [...Object.values(signCords?.[k])],
    }));

    const jsonData = JSON.stringify(data, null, 2);

    let blob = new Blob([jsonData], { type: "application/octet-stream" });
    let url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.href = url;
    a.download = "eSignCoordinates.json";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const signDetails = signCords[`${pageNumber}`];

  // converting state to api response/logic
  const getSigners = () => {
    let data = [];

    const positions = (invt) => {
      let obj = {};
      Object.keys(signCords).forEach((curPage) => {
        const signs = Object.values(signCords[`${curPage}`])
          .filter((sign) => sign.invitee === invt)
          .map((sign) => ({ x: sign.x, y: sign.y }));
        if (!!signs.length) {
          obj[`${curPage}`] = signs;
        }
      });
      return obj;
    };

    props.inviteeData.invitees.forEach((signee) => {
      const pos = positions(`${signee.name}_${signee.contact}`);
      const signeeData = {
        config: {
          position: pos,
          auth_mode: "otp",
          reason: signee.moreOptions.reason,
          accept_virtual_sign: signee.signType.virtualSign,
          track_location: signee.moreOptions.track_location,
          accept_selfie: signee.moreOptions.accept_selfie,
          allow_selfie_upload: signee.moreOptions.allow_selfie_upload,
          allow_download: signee.moreOptions.allow_download,
          skip_otp: signee.moreOptions.skip_otp,
        },
        prefill_options: {
          full_name: signee.name,
          mobile_number: signee.contact,
          user_email: signee.contact,
        },
      };
      data.push(signeeData);
    });

    return data;
  };

  // Next Step / Final Submission
  const submitStep = async () => {
    await props.addSigners({
      client_id: props.clientId,
      signers: getSigners(),
    });
    setActiveStep((prev) => prev + 1);
  };

  return (
    <>
      <Grid container justifyContent="center" style={{ margin: "1rem 0" }}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-around">
            <Grid
              container
              item
              xs={8}
              className={classes.pdfContainer}
              justifyContent="center"
            >
              <Document
                file={DocPdf}
                onLoadSuccess={onDocumentLoadSuccess}
                error={
                  <Typography color="secondary">
                    <br />
                    <br />
                    An Error Occured...
                  </Typography>
                }
                loading={
                  <Typography color="primary">
                    <br />
                    <br />
                    Loading ...
                  </Typography>
                }
                noData={
                  DocPdf === "" ? (
                    <Typography color="primary">
                      <br />
                      <br />
                      Merging Stamp and Document ...
                    </Typography>
                  ) : (
                    <Typography color="secondary">
                      <br />
                      <br />
                      No PDF Found
                    </Typography>
                  )
                }
                renderMode="canvas"
                className={
                  !!pageNumber &&
                  (!!customCoordinates
                    ? classes.docDimenCursor
                    : classes.docDimen)
                }
              >
                <Page
                  pageNumber={pageNumber ?? 1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  className={classes.pageDiv}
                  loading={
                    <Typography color="primary">
                      <br />
                      <br />
                      Loading Page ...
                    </Typography>
                  }
                  onLoadSuccess={() => setCounter((prev) => prev + 1)} // this is not gibberish
                  inputRef={ref}
                >
                  {signDetails &&
                    Object.keys(signDetails)?.map((signId, idx) => (
                      <Draggable
                        key={idx}
                        disabled={!customCoordinates}
                        onStop={(e, data) => addNewCords(e, data, signId)}
                        cancel=".noDrag"
                        bounds=".react-pdf__Page__canvas"
                        position={{
                          x: signDetails?.[signId]?.x,
                          y: -signDetails?.[signId]?.y,
                        }}
                      >
                        <Box
                          width={180}
                          height={60}
                          className={classes.signBox}
                          style={{
                            cursor: `${customCoordinates ? "move" : "auto"}`,
                          }}
                        >
                          <IconButton
                            className="noDrag"
                            onClick={() => deleteSign(signId)}
                            color="secondary"
                            size="small"
                          >
                            <CloseRounded fontSize="small" />
                          </IconButton>
                          <div className="noDrag">
                            <CustomTextField
                              variant="outlined"
                              size="small"
                              style={{ width: 120 }}
                              select
                              value={signDetails?.[signId]?.invitee}
                              onChange={(e) =>
                                selectInvitee(signId, e.target.value)
                              }
                            >
                              {props?.inviteeData?.invitees?.map(
                                (invitee, idx) => (
                                  <MenuItem
                                    dense={true}
                                    key={idx}
                                    className="noDrag"
                                    value={`${invitee.name}_${invitee.contact}`}
                                  >
                                    <small>{idx + 1}. </small> &nbsp;{" "}
                                    {invitee.name}
                                  </MenuItem>
                                )
                              )}
                            </CustomTextField>
                          </div>
                        </Box>
                      </Draggable>
                    ))}
                </Page>
              </Document>
              {pageNumber <= numOfStampPages.current ? (
                <div className={classes.chipDiv}>
                  <Chip label={"Stamp"} className={classes.stampChip} />
                </div>
              ) : (
                <div className={classes.chipDiv}>
                  <Chip label={"Document"} className={classes.docChip} />
                </div>
              )}
            </Grid>
            <Grid item xs={3}>
              <Box my={8} style={{ position: "sticky", top: 10 }}>
                <Button
                  fullWidth
                  size="large"
                  className={classes.pdfButton}
                  onClick={() => setViewInvitees(true)}
                  startIcon={<GroupRounded />}
                >
                  &nbsp; View Invitees
                </Button>
                <Button
                  fullWidth
                  size="large"
                  className={classes.pdfButton}
                  onClick={downloadFile}
                  startIcon={<GetAppRounded />}
                >
                  &nbsp; Download PDF
                </Button>
                <LightTooltip
                  title="Set custom eSign position"
                  placement={"left"}
                  enterDelay={600}
                >
                  <Button
                    fullWidth
                    size="large"
                    className={classes.pdfButton}
                    onClick={() => setCustomCoordinates((prev) => !prev)}
                    color={customCoordinates ? "secondary" : "default"}
                    startIcon={<OpenWithRounded />}
                  >
                    &nbsp; Set eSign Coordinates
                  </Button>
                </LightTooltip>
                <LightTooltip
                  title={
                    <Typography variant="caption" color="secondary">
                      This will override all previous cords
                    </Typography>
                  }
                  placement={"left"}
                  enterDelay={0}
                >
                  <Button
                    fullWidth
                    size="large"
                    className={classes.pdfButton}
                    onClick={copyToAllCords}
                    color={copyCoordinates ? "primary" : "default"}
                    startIcon={
                      copyCoordinates ? (
                        <DoneAllOutlined />
                      ) : (
                        <AddCircleOutlineRounded />
                      )
                    }
                  >
                    &nbsp; Copy this to all pages
                  </Button>
                </LightTooltip>
                <LightTooltip
                  title="Download json file"
                  placement={"left"}
                  enterDelay={600}
                >
                  <Button
                    fullWidth
                    size="large"
                    className={classes.pdfButton}
                    onClick={downloadCords}
                    startIcon={<GpsFixedRounded />}
                  >
                    &nbsp; Download Coordinates
                  </Button>
                </LightTooltip>
                <LightTooltip
                  title="Reset this page's coordinates"
                  placement={"left"}
                  enterDelay={600}
                >
                  <Button
                    fullWidth
                    size="large"
                    className={classes.pdfButton}
                    onClick={() => deleteAllSign()}
                    startIcon={<RotateLeftRounded />}
                  >
                    &nbsp; Clear Coordinates
                  </Button>
                </LightTooltip>
                {/* <Box mx={2} my={4}>
                                    <Typography color="textSecondary"> &nbsp; Page Scale: </Typography>
                                    <Box display="flex" alignItems="center" p={0}>
                                        <IconButton disabled={zoom < 0.40} onClick={() => setZoom(prev => prev - 0.1)} color="primary">
                                            <ZoomOutRounded fontSize="small" />
                                        </IconButton> &nbsp;
                                        <Slider
                                            value={zoom}
                                            min={0.3}
                                            step={0.01}
                                            max={2}
                                            getAriaValueText={() => zoom.toFixed(2)}
                                            valueLabelFormat={() => zoom.toFixed(2)}
                                            onChange={(e, val) => setZoom(val)}
                                            valueLabelDisplay="auto"
                                        /> &nbsp;
                                        <IconButton disabled={zoom > 1.90} onClick={() => setZoom(prev => prev + 0.1)} color="primary">
                                            <ZoomInRounded fontSize="small" />
                                        </IconButton>
                                    </Box>
                                </Box> */}
              </Box>
            </Grid>
          </Grid>
          <div className="padding-for-firefox">
            {/* Provide padding required for firefox browser for fixed footer */}
          </div>
        </Grid>
      </Grid>
      {/* Side Drawer */}
      <Backdrop
        className={classes.backdrop}
        open={viewInvitees}
        onClick={() => setViewInvitees(false)}
      />
      <Drawer
        anchor="right"
        variant="persistent"
        classes={{ paperAnchorDockedRight: "no-border-drawer" }}
        open={viewInvitees}
        onClose={() => setViewInvitees(false)}
      >
        <Box width={400} role="presentation">
          <Box
            position="sticky"
            top={0}
            zIndex={10}
            display="flex"
            py={1}
            px={2}
            justifyContent="space-between"
            alignItems="center"
            bgcolor="primary.main"
            color="primary.contrastText"
          >
            <Typography variant="h5">Invitees</Typography>
            <IconButton
              onClick={() => setViewInvitees(false)}
              style={{ color: "white" }}
            >
              <CloseRounded />
            </IconButton>
          </Box>
          <Divider />
          <List>
            {props?.inviteeData?.invitees?.map((invitee, idx) => (
              <div key={idx}>
                <ListItem button>
                  <Box m={1}>
                    <Typography variant="caption" gutterBottom>
                      {idx + 1}.{" "}
                    </Typography>
                  </Box>
                  <Box m={1}>
                    <Typography variant="inherit" gutterBottom>
                      {invitee.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {invitee.contact}
                    </Typography>
                  </Box>
                </ListItem>
                <Divider />
              </div>
            ))}
          </List>
        </Box>
      </Drawer>

      {/* Footer Bar */}
      <Paper square style={{ position: "sticky", bottom: 0 }}>
        <Box
          p={2}
          mx={3}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          maxHeight="5rem"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flex={2}
          >
            <LightTooltip
              interactive
              title={
                <CustomTextField
                  label="Jump To Page"
                  variant="outlined"
                  type="number"
                  size="small"
                  autoFocus
                  input={pageNumber}
                  onChange={(e) => {
                    const p = Math.min(parseInt(e.target.value), numPages);
                    if (p > 0) {
                      setPageNumber(p);
                    }
                  }}
                />
              }
              placement={"top"}
              enterDelay={100}
            >
              <Box mx={4}>
                <IconButton disableRipple>
                  <TrendingUpRounded />
                </IconButton>
              </Box>
            </LightTooltip>
            <Pagination
              color="primary"
              count={numPages}
              page={pageNumber}
              siblingCount={1}
              boundaryCount={1}
              shape={"rounded"}
              onChange={(e, value) => changePage(e, value)}
              showFirstButton
              showLastButton
            />
          </Box>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <Button
              size="large"
              color="primary"
              edge="end"
              onClick={() => setActiveStep((prev) => prev - 1)}
              className={classes.button}
            >
              Back
            </Button>
            <Button
              size="large"
              variant="contained"
              color="primary"
              edge="end"
              onClick={submitStep}
              className={classes.button}
            >
              Finish
            </Button>
          </Box>
        </Box>
      </Paper>
    </>
  );
}

const mapStateToProps = (state) => ({
  createData: state.ui.createData,
  inviteeData: state.ui.inviteData,
  clientId: state.sign.clientId,
});

const mapDispatchToProps = {
  addSigners: initSignActions.addSigners,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Finish));
