import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { authReducer, SIGN_OUT_SUCCESS, modalReducer } from "./auth";
import { loadingBarReducer } from "react-redux-loading-bar";
import history from "./history";
import { UiReducer } from "globalUI/reducer";
import { initSignReducer } from "CreateDoc";
import {
  clientsReducer,
  planReducer,
  planPopReducer,
} from "getDetails/reducer";

const appReducers = combineReducers({
  auth: authReducer,
  modalRed: modalReducer,
  sign: initSignReducer,
  clientData: clientsReducer,
  planPopRed: planPopReducer,
  ui: UiReducer,
  router: connectRouter(history),
  planReducer: planReducer,
  loadingBar: loadingBarReducer,
});
const rootReducer = (state, action) => {
  if (action.type === SIGN_OUT_SUCCESS) {
    return appReducers(undefined, action);
  }

  return appReducers(state, action);
};

export default rootReducer;
