import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
  Edit,
  HomeRounded,
  PermIdentity,
  PermIdentityRounded,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { UiActions } from "globalUI";
import { withStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { clientActions } from "getDetails";
import Avatar from "../../../assets/avatar-1.png";
import { useLocation } from "react-router-dom";
import StatusTag from "views/components/StatusTags";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import GetAppIcon from "@material-ui/icons/GetApp";
import moment from "moment";

const StyledTableCell = withStyles((theme) => ({
  body: (props) => ({
    fontWeight: props.fontWeight,
    border: `1px solid ${theme.palette.grey[300]}`,
  }),
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  contentBox: {
    background: theme.palette.background.paper,
    padding: "50px",
    boxShadow: "0px 0px 50px -20px #ccc",
    borderRadius: "10px",
    marginBottom: "1rem",
  },
  avatar: {
    borderRadius: "50%",
    padding: "10px",
    flex: 1,
    width: "70%",
  },
  wrapper: {
    flex: 10,
  },
}));

function DocumentDetails(props) {
  useEffect(() => {
    props.getClientDetails(props.match.params.clientId);
  }, []);

  const classes = useStyles();

  let user = {
    name: "Akram singh",
    email: "ak23476@gmail.com",
    mobile: "+91 8683621097",
    date: "2021-05-03T18:07:01.560000",
    vaccination_status: "Partially Vaccinated",
    birth_year: "1955",
    gender: "Male",
    photo_id_type: "Aadhaar Card",
    photo_id_number: "********6007",
    vaccine: "COVAXIN",
    dose_1_date: "2021-04-01",
  };

  const getVaccinatedValue = (v_status) => {
    if (v_status.includes("Not")) {
      return 0;
    } else if (v_status.includes("Partially")) {
      return 50;
    } else {
      return 100;
    }
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={11}>
          <Box my={4} display="flex" alignItems="flex-end">
            <PermIdentity /> &nbsp;&nbsp;&nbsp;
            <Typography variant="caption">Your applicant profile</Typography>
          </Box>
        </Grid>
        <Grid item xs={11}>
          <Paper variant="outlined" className={classes.contentBox}>
            <Grid container justifyContent={"space-between"}>
              <Grid item xs={12} md={3}>
                <Box>
                  <img src={Avatar} className={classes.avatar} />
                  <br />
                  {/*    <Button size="small" variant="outlined" color="primary"*/}
                  {/*        style={{ textTransform: "none", margin: "0.6rem 0" }}*/}
                  {/*    >*/}
                  {/*        Change Picture*/}
                  {/*</Button>*/}
                </Box>
              </Grid>
              <Grid item xs={12} md={9}>
                <Box>
                  <Typography variant="h4">{user.name}</Typography>
                  {/*<Typography variant="body2" color="textSecondary">{user.company}</Typography>*/}
                  <br />
                  <Grid container direction={"row"} justifyContent={"center"}>
                    <Grid item xs={6} container justifyContent={"flex-start"}>
                      {/*<MailOutlineIcon color={"secondary"} fontSize={"large"}/>*/}
                      <div style={{ marginLeft: 10 }}>
                        <Typography variant="subtitle1" color="textSecondary">
                          Email
                        </Typography>
                        <Typography variant="body1">
                          {user.email || "-"}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={6} container justifyContent={"flex-start"}>
                      {/*<PhoneIphoneIcon color={"primary"} fontSize={"large"}/>*/}
                      <div>
                        <Typography variant="subtitle1" color="textSecondary">
                          Phone No.
                        </Typography>
                        <Typography variant="body1">
                          {user.mobile || "-"}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container direction={"row"} justifyContent={"center"}>
                    <Grid item xs={6} container justifyContent={"flex-start"}>
                      {/*<PhoneIphoneIcon color={"primary"} fontSize={"large"}/>*/}
                      <div style={{ marginLeft: 10 }}>
                        <Typography variant="subtitle1" color="textSecondary">
                          Vaccine
                        </Typography>
                        <Typography variant="body1" color="textPrimary">
                          {user.vaccine}
                        </Typography>
                        {/*<Typography variant="caption" color="textSecondary">{moment(user.date).format('LTS')}</Typography>*/}
                      </div>
                    </Grid>
                    <Grid item xs={6} container justifyContent={"flex-start"}>
                      {/*<PhoneIphoneIcon color={"primary"} fontSize={"large"}/>*/}
                      <div>
                        <Typography variant="subtitle1" color="textSecondary">
                          Vaccination Status
                        </Typography>
                        <Typography variant="body1">
                          {user.vaccination_status || "-"}
                        </Typography>
                        <LinearProgress
                          style={{ marginTop: 10 }}
                          variant={"determinate"}
                          value={getVaccinatedValue(user.vaccination_status)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container direction={"row"} justifyContent={"center"}>
                    <Grid item xs={6} container justifyContent={"flex-start"}>
                      <div style={{ marginLeft: 10 }}>
                        <Typography variant="subtitle1" color="textSecondary">
                          Birth year
                        </Typography>
                        <Typography variant="body1">
                          {user.birth_year || "-"}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={6} container justifyContent={"flex-start"}>
                      {/*<PhoneIphoneIcon color={"primary"} fontSize={"large"}/>*/}
                      <div>
                        <Typography variant="subtitle1" color="textSecondary">
                          Dose 1 Date
                        </Typography>
                        <Typography variant="body1" color="textPrimary">
                          {moment(user.dose_1_date).format("ll")}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container direction={"row"} justifyContent={"center"}>
                    <Grid item xs={6} container justifyContent={"flex-start"}>
                      <div style={{ marginLeft: 10 }}>
                        <Typography variant="subtitle1" color="textSecondary">
                          Photo ID Type
                        </Typography>
                        <Typography variant="body1">
                          {user.photo_id_type || "-"}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={6} container justifyContent={"flex-start"}>
                      {/*<PhoneIphoneIcon color={"primary"} fontSize={"large"}/>*/}
                      <div>
                        <Typography variant="subtitle1" color="textSecondary">
                          ID Number
                        </Typography>
                        <Typography variant="body1" color="textPrimary">
                          {user.photo_id_number}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container direction={"row"} justifyContent={"center"}>
                    <Grid item xs={6} container justifyContent={"flex-start"}>
                      <div style={{ marginLeft: 10 }}>
                        <Typography variant="subtitle1" color="textSecondary">
                          Gender
                        </Typography>
                        <Typography variant="body1">
                          {user.gender || "-"}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      container
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                    >
                      {/*<PhoneIphoneIcon color={"primary"} fontSize={"large"}/>*/}
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container direction={"row"} justifyContent={"center"}>
                    <Grid item xs={12}>
                      <Button
                        color={"primary"}
                        variant={"contained"}
                        endIcon={<GetAppIcon />}
                        style={{ textTransform: "capitalize" }}
                      >
                        Download Vaccination Certificate
                      </Button>
                    </Grid>
                  </Grid>
                  <br />
                  {/*<Typography variant="subtitle1" color="textSecondary">Default Payment </Typography>*/}
                  {/*<Typography variant="body1">State Bank of India, Debit Card *****1301</Typography>*/}
                  {/*<br />*/}
                  <Divider />
                </Box>
                {/*<Box display="flex" justifyContent="flex-end" mt={1} ml={"auto"}>*/}
                {/*    <Button size="small" color="primary" startIcon={<VpnKeyRounded fontSize="small" />}*/}
                {/*        onClick={() => setChangePasswordDrawer(true)}*/}
                {/*    >*/}
                {/*        Change Password*/}
                {/*</Button>*/}
                {/*</Box>*/}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  createData: state.ui.createData,
  inviteeData: state.ui.inviteData,
  clientDetails: state.clientData.clientDetails,
});

const mapDispatchToProps = {
  setCreateData: UiActions.setCreateData,
  setInviteeData: UiActions.setInviteData,
  getClientDetails: clientActions.getClientDetails,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DocumentDetails)
);
