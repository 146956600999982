import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  Link as MLink,
  Paper,
  TextField,
  Typography,
  MenuItem,
  makeStyles,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Menu,
  InputBase,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentText,
  DialogContent,
  Slide,
  Grow,
  InputLabel,
  Tooltip,
} from "@material-ui/core";
import {
  SwapVertRounded,
  SwapVerticalCircleRounded,
  UnfoldMoreRounded,
  SearchOutlined,
  Search,
  Directions,
  CloseRounded,
  Edit,
  Delete,
} from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import React, { forwardRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import { DatePicker } from "views/components/date-picker/DatePicker";
import DocTable from "views/components/docTable";
import FolderCard from "./FolderCard";
import getUniqueName from "utils/getUnique";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(4),
    textTransform: "none",
  },
  root: {
    marginRight: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    maxWidth: 400,
  },
  searchBtn: {
    margin: 5,
    color: theme.palette.grey[500],
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const MyFolders = ({ ...props }) => {
  const classes = useStyles();

  const [folderName, setFolderName] = useState("New Folder");
  const [rootFolders, setRootFolders] = useState({
    info: {
      id: props.match.params.id,
      name: "",
      date: "",
    },
    folders: [],
    docs: [],
  });

  useEffect(() => {
    const docs =
      Math.random() > 0.5
        ? []
        : [
            {
              id: "341513",
              name: "My_document_for_eSign.pdf",
              docType: "received",
              eSignStatus: "failed",
              folder: "Imp. ",
              owner: "Me",
              createdAt: "2020-01-15T18:08:01.560000",
            },
          ];
    setRootFolders({
      info: {
        id: props.match.params.id,
        name: "",
        date: "",
      },
      folders: [],
      docs,
    });
  }, [props.match.params.id]);

  const hanlderEdit = () => {
    setTimeout(() => {
      let resp = window.prompt("Enter Folder Name", folderName);
      if (!resp?.trim() || resp === folderName) {
        return;
      }
      setFolderName(resp);
    }, 100);
  };

  const createFolder = () => {
    let resp = window.prompt("Enter Folder Name", "New Folder");
    if (!resp?.trim()) {
      return;
    }

    let name = getUniqueName(resp, rootFolders?.folders);

    setRootFolders((prev) => ({
      ...prev,
      folders: [
        {
          name,
          docs: [],
          id: uuidv4().split("-").pop(),
          date: Date.now(),
          folders: [],
        },
        ...prev.folders,
      ],
    }));
  };

  const deleteFolder = (fldrId) => {
    setRootFolders((prev) => ({
      ...prev,
      folders: [...prev.folders.filter((f) => f.id !== fldrId)],
    }));
  };

  // Recursive function to get unique folder name
  const renameFolder = (fldrId, name) => {
    let UniqueName = getUniqueName(name, rootFolders?.folders);

    setRootFolders((prev) => ({
      ...prev,
      folders: [
        ...prev.folders.map((f) => {
          if (f.id === fldrId) {
            return {
              ...f,
              name: UniqueName,
            };
          }
          return f;
        }),
      ],
    }));
  };

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Box my={2} mx={8}>
            <Breadcrumbs itemsAfterCollapse={3} aria-label="breadcrumb">
              <MLink component={Link} color="inherit" to="/">
                Home
              </MLink>
              <MLink component={Link} color="inherit" to="/folders">
                Root
              </MLink>
              {/* <Typography color="textPrimary">Breadcrumb</Typography> */}
            </Breadcrumbs>
          </Box>
          <Divider />
          <br />
        </Grid>
        <Grid
          component={Paper}
          variant="outlined"
          container
          item
          xs={11}
          justifyContent="space-around"
          alignItems="center"
          style={{ marginBottom: 20, padding: 10 }}
        >
          <Grid item xs={4}>
            <Tooltip title="Folder Name">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography>{folderName}</Typography>
                <Divider className={classes.divider} orientation="vertical" />
              </Box>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Tooltip title="Folder ID">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography>{props.match.params.id}</Typography>
                <Divider className={classes.divider} orientation="vertical" />
              </Box>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Tooltip title="Created At">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography>{moment(Date.now()).format("ll")}</Typography>
                <Divider className={classes.divider} orientation="vertical" />
              </Box>
            </Tooltip>
          </Grid>
          <Grid container item xs={1} justifyContent="space-around">
            <Tooltip title="Edit Folder Name">
              <IconButton color={"primary"} onClick={hanlderEdit}>
                <Edit fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Folder">
              <IconButton color="secondary" component={Link} to="/folders">
                <Delete fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={11}>
          <Typography color="textSecondary" gutterBottom>
            {" "}
            &nbsp; Folders
          </Typography>
        </Grid>
        <Grid
          component={Paper}
          variant="outlined"
          container
          item
          xs={11}
          justifyContent="center"
          style={{ marginBottom: 10 }}
        >
          <Grid item xs={12}>
            <Box
              m={3}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box flexGrow={1}>
                <Paper className={classes.root} variant="outlined">
                  <InputBase
                    className={classes.input}
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                  />
                  <Divider className={classes.divider} orientation="vertical" />
                  <Search fontSize="small" className={classes.searchBtn} />
                </Paper>
              </Box>
              <Box
                display="flex"
                flexGrow={1}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  size="small"
                  className={classes.button}
                  startIcon={<SwapVertRounded fontSize="small" />}
                >
                  Name
                </Button>
                <Button
                  size="small"
                  className={classes.button}
                  startIcon={<SwapVertRounded fontSize="small" />}
                >
                  Date
                </Button>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={createFolder}
                >
                  New Folder
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box m={2} display="flex" flexWrap={"wrap"}>
              {!rootFolders?.folders?.length ? (
                <Typography vataint={"caption"}> No Folders!</Typography>
              ) : (
                <>
                  {rootFolders?.folders.map((item) => (
                    <FolderCard
                      key={item.id}
                      name={item.name}
                      id={item.id}
                      date={item.date}
                      deleteFolder={deleteFolder}
                      renameFolder={renameFolder}
                    />
                  ))}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <br />
        </Grid>
        {!!rootFolders?.docs?.length && (
          <>
            <Grid item xs={11}>
              <Typography color="textSecondary" gutterBottom>
                {" "}
                &nbsp; Documents
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <DocTable
                title={
                  <Box display="flex" alignItems="center">
                    <DatePicker />
                  </Box>
                }
                data={rootFolders?.docs}
              />
              <br />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyFolders)
);
