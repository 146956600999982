import { Box, Grid, IconButton, Paper, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { grey, indigo } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { CloseRounded, HelpOutlineRounded } from "@material-ui/icons";
import Logo from "assets/logo.png";
import "draft-js/dist/Draft.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import EditorPage from "./EditorPage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    width: "100%",
    height: "100%",
    gridTemplateRows: "1fr auto",
    gridTemplateColumns: "1fr",
  },
  button: {
    marginLeft: theme.spacing(4),
    textTransform: "none",
  },
  offset: theme.mixins.toolbar,
}));

function CreateTemplate() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <Paper square>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid container item xs={2} justifyContent="center" >
                        <Button component={Link} to="/" color="primary">
                            <img src={Logo} style={{ padding: 8, height: "3.2rem" }} />
                        </Button>
                    </Grid>
                    <Grid container item xs={2} justifyContent="flex-end">
                        <IconButton
                            component={Link} to="/"
                            color="secondary" style={{ marginRight: 20 }}
                        >
                            <CloseRounded fontSize="large" />
                        </IconButton>
                    </Grid>
                </Grid>
            </Paper> */}
      <Box display="flex" justifyContent="center" m={4}>
        <EditorPage />
        <div className="padding-for-firefox">
          {/* Provide padding required for firefox browser for fixed footer */}
        </div>
      </Box>

      <Paper square style={{ position: "sticky", bottom: 0, zIndex: 10 }}>
        <Box
          p={2}
          mx={3}
          display="flex"
          justifyContent="space-between"
          maxHeight="5rem"
        >
          <Box>
            <TextField
              size="small"
              variant="outlined"
              label="Template Name"
              placeholder="Template-FXJSAL"
            />
          </Box>
          <Box>
            <Button
              size="large"
              color="primary"
              startIcon={<HelpOutlineRounded fontSize="small" />}
              className={classes.button}
            >
              Help
            </Button>
            <Button size="large" color="primary" className={classes.button}>
              Generate PDF
            </Button>
            <Button size="large" color="primary" className={classes.button}>
              Preview
            </Button>
            <Button
              size="large"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Paper>
    </div>
  );
}

export default CreateTemplate;
