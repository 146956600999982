import {
  Box,
  Button,
  Fade,
  Grid,
  Paper,
  Slide,
  Typography,
  Avatar, Snackbar,
} from "@material-ui/core";
import { red, deepOrange } from "@material-ui/core/colors";

import { withStyles } from "@material-ui/core/styles";

import {
  ExitToAppRounded,
  HomeRounded,
  PermIdentity,
  PersonRounded,
  SettingsOutlined,
} from "@material-ui/icons";
import React from "react";
import { connect } from "react-redux";
import { Link, matchPath, NavLink, withRouter } from "react-router-dom";
import profile from "../../../assets/profile.svg";
import { authActions, getAuth } from "../../../auth";
import { clientActions } from "getDetails";
import styles from "./header.module.scss";
import AddIcon from "@material-ui/icons/Add";
import { compose } from "redux";
import AvatarCom from "./avatar";
import {Alert} from "@material-ui/lab";

const useStyles = (theme) => ({
  activeNavLink: {
    "&, &:hover": {
      color: theme.palette.primary.main,
      borderBottom: `2px solid  ${theme.palette.primary.main}`,
    },
  },
  buyBtn: {
    fontWeight: "600",
    borderRadius: "10px",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
});

const StyledButton = withStyles((theme) => ({
  root: {
    height: "3.5rem",
    marginRight: "2%",
    marginTop: "0.5rem",
    padding: "0 2%",
    borderRadius: 0,
    fontWeight: 800,
    color: theme.palette.grey[700],
    borderBottom: `2px solid #fff`,
    "&:hover": {
      background: "none",
      borderBottom: `2px solid ${theme.palette.grey[100]}`,
      color: theme.palette.grey[900],
    },
    "& .MuiTouchRipple-root": {
      opacity: 0.2,
      zIndex: -1,
    },
  },
}))(Button);

const GenerateButton = withStyles((theme) => ({
  root: {
    height: "2.5rem",
    margin: "25px 10%",
    padding: "0 2%",
    borderRadius: "8px",
    backgroundColor: "#3f51b5",
    boxShadow: "0px 0px 8px 0px #ccc",
    fontWeight: 800,
    color: "#fff",
    "&:hover": {
      background: "none",
      color: theme.palette.grey[900],
    },
    "& .MuiTouchRipple-root": {
      opacity: 0.2,
      zIndex: -1,
    },
  },
}))(Button);

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      errormesg: "",
      statuscode: "",
    };
  }

  handleOpen = (event) => {
    this.setState({ open: true });
  };
  handleClose = (event) => {
    this.setState({ open: false });
  };

  static getDerivedStateFromProps(nextProps, prevState){
    var newState;
    if(nextProps.errormsg!==prevState.errormesg){
      newState=prevState;
      newState.errormesg=nextProps.errormsg
      newState.statuscode=nextProps.status_code
      return newState
    }
    return prevState
  }

  componentDidMount() {
    this.props.getProfileData();
  }
  // for active navlink css
  checkActive = (_, location) => {
    if (!location) return false;
    const homeURLs = [
      "/home",
      "/documents",
      "/templates",
      "/stamps",
      "/folders",
    ];

    const match = matchPath(location.pathname, {
      path: homeURLs,
      exact: false,
      strict: true,
    });

    return !!match;
  };

  handleLogOut = () => {
    this.props.signOut();
    // this.props.history.push({
    //   pathname: "/",
    // });

    // console.log("SIGNOUT");
  };


  render() {
    const { classes, userName } = this.props;
    const { open,errormesg } = this.state;

    return (
      <Box className={styles.navbar}>

        <Snackbar
            open={!!errormesg}
            autoHideDuration={3000}
            onClose={() => this.setState({errormesg:""})}
        >
          <Alert onClose={() => this.setState({errormsg:""})} severity="error">

            {errormesg}
          </Alert>
        </Snackbar>
        <Grid container justifyContent="center">
          <Grid container item xs={9}>
            <StyledButton
              component={NavLink}
              strict
              to="/home"
              size="large"
              activeClassName={classes.activeNavLink}
              isActive={this.checkActive}
              startIcon={<HomeRounded />}
            >
              Home
            </StyledButton>
            {/*<StyledButton component={NavLink} strict to="/account" size="large"*/}
            {/*    activeClassName={classes.activeNavLink}*/}
            {/*    startIcon={<PersonRounded />}*/}
            {/*>*/}
            {/*    Account*/}
            {/*</StyledButton>*/}
          </Grid>
          <Grid
            container
            item
            xs={2}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              color="primary"
              variant="contained"
              className={classes.buyBtn}
              onClick={() => this.props.history.push("/plan")}
            >
              Buy Credits
            </Button>
            {/*<StyledButton component={NavLink} strict to="/account" size="large"*/}
            {/*    activeClassName={classes.activeNavLink}*/}
            {/*    startIcon={<PersonRounded />}*/}
            {/*>*/}
            {/*    Account*/}
            {/*</StyledButton>*/}
          </Grid>

          <Grid
            container
            item
            xs={1}
            justifyContent={"flex-start"}

          >
            <Box m={"auto"} mr={3}
                 onMouseEnter={this.handleOpen}
                 onMouseLeave={this.handleClose}
            >
              {/* <img src={profile} style={{ width: "2.5rem" }} /> */}
                <AvatarCom />
              <Slide
                in={open}
                unmountOnExit
                timeout={{
                  enter: 300,
                  exit: 600,
                }}
              >
                <div style={{ position: "absolute", top: 0, right: 20 }}>
                  <Fade in={open} timeout={400}>
                    <Box
                      component={Paper}
                      className={styles.paper}
                      p={2}
                      pt={1}
                    >
                      <Box my={1} style={{ width: "100%" }}>
                        <Button
                          fullWidth
                          size="large"
                          className={styles.profileBtns}
                          disabled
                        >
                          <AvatarCom />
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <Typography variant="body1" color="textPrimary">
                            {this.props.profileData?.data?.name}
                          </Typography>
                        </Button>
                      </Box>
                      <Button
                        component={Link}
                        to="/profile"
                        fullWidth
                        size="large"
                        className={styles.profileBtns}
                      >
                        <PermIdentity /> &nbsp;&nbsp;&nbsp;&nbsp;
                        <Typography variant="body1">Profile</Typography>
                      </Button>
                      {/*<Button component={Link} to="/account/settings" fullWidth size="large" className={styles.profileBtns}>*/}
                      {/*    <SettingsOutlined /> &nbsp;&nbsp;&nbsp;&nbsp;*/}
                      {/*    <Typography variant="body1">Settings</Typography>*/}
                      {/*</Button>*/}
                      <br />
                      <Button
                        fullWidth
                        size="large"
                        className={styles.profileBtns}
                        style={{
                          color: this.props.theme.palette.secondary.dark,
                        }}
                        onClick={this.handleLogOut}
                      >
                        <ExitToAppRounded /> &nbsp;&nbsp;&nbsp;&nbsp;
                        <Typography variant="body1">Log Out</Typography>
                      </Button>
                    </Box>
                  </Fade>
                </div>
              </Slide>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profileData: state.clientData.profileData,
    userName: state.clientData.userName,
    errormsg:state.auth.errorMsg,
    status_code: state.auth.statusCode
  };
};

const mapDispatchToProps = {
  signOut: authActions.logout,
  getProfileData: clientActions.getProfileDetails,
};

export default compose(
  withStyles(useStyles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Header);
