import { IconButton, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import { withStyles } from '@material-ui/core/styles';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import CloudUpload from "@material-ui/icons/CloudUpload";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import React from "react";
import Dropzone from "react-dropzone";
import { Controller } from "react-hook-form";
import { withRouter } from "react-router-dom";
import { compose } from 'redux';



const styles = {
  root: {
    backgroundColor: "#fafbfe",
    textAlign: "center",
    cursor: "pointer",
    color: "#333",
    padding: 30,
    // marginTop: 10,
  },
  smallRoot: {
    backgroundColor: "#fafbfe",
    textAlign: "center",
    cursor: "pointer",
    color: "#333",
    padding: "1.2rem",
    // marginTop: 10,
  },
  icon: {
    // marginTop: 10,
    color: "#888888",
    fontSize: "42px",
  },
  smallIcon: {
    color: "#888888",
    fontSize: "1.5rem",
  },
  list: {
    backgroundColor: "#fafbfe",
    // fontSize: 10,
    // outline: "1px solid #333",
  }
}

const FileInput = ({
  classes,  // styles
  control,  // react-hook-form control
  name,     // document name
  small = false,  // small padding/size
  required = false,  //  form validation
  error = null,       // error msg on form validation
  multiple = false,   // allow multiple documents selection
  defaultValue = null  // default upload
}) => {

  const showDoc = (f) => {
    let blob = new Blob([f], { type: f.type })
    let url = window.URL.createObjectURL(blob)

    window.open(url, '_blank')
  }

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={{ required }}
      render={({ onChange, onBlur, value }) => (
        <>
          <Dropzone
            onDrop={onChange}
            multiple={multiple}
            accept="application/pdf"
          >
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
              <>
                <Paper
                  variant="outlined"
                  className={small ? classes.smallRoot : classes.root}
                  {...getRootProps()}
                >
                  <CloudUpload className={small ? classes.smallIcon : classes.icon} />
                  <input {...getInputProps()} name={name} onBlur={onBlur} /><br />
                  {!isDragActive && <Typography variant="body2">{`Drag 'n' drop pdf${!!multiple ? 's' : ''} here, or click to select pdf${!!multiple ? 's' : ''}`}</Typography>}
                  {isDragActive && !isDragReject && <Typography variant="body2">{`Drop pdf${!!multiple ? 's' : ''} here ...`}</Typography>}
                  {isDragReject && <Typography variant="body2" color="secondary">File type not accepted, sorry!</Typography>}
                </Paper>

                {error && <Typography variant="caption" color="secondary">Required field</Typography>}

                <List dense={small}>
                  {value?.map((f, index) => (
                    <ListItem key={index} className={classes.list} component={Paper} variant="outlined"  >
                      <ListItemIcon>
                        <InsertDriveFile color="primary" fontSize={small ? "small" : "default"} />
                      </ListItemIcon>
                      <ListItemText
                        primary={<Typography variant={small ? "body2" : "body1"} color="textPrimary">{f.name}</Typography>}
                        secondary={<Typography variant={small ? "caption" : "subtitle1"} color="textSecondary">{(f.size / 1048576).toFixed(2)} MB</Typography>}
                        disableTypography
                      />
                      <IconButton color="primary"
                        onClick={() => showDoc(f)}
                      >
                        <VisibilityRoundedIcon />
                      </IconButton>
                      {/* enable delete/reset option */}
                      {/* <IconButton color="primary"
                        onClick={() => {
                          onChange(value.filter(val => val !== f));
                        }}
                      >
                        <ClearRoundedIcon />
                      </IconButton> */}
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </Dropzone>
        </>
      )}
    />
  );
};

export default compose(
  withStyles(styles),
  withRouter,
)(FileInput);