import {
    Box, Collapse, FormControlLabel,
    MenuItem, Switch, TextField, Typography
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import { Controller, useWatch } from "react-hook-form";

export default function OptDrawer({ item, idx, control }) {

    // const isMaxAttempts = useWatch({ control, name: `invitees[${idx}].moreOptions.isMaxAttempts` });
    // const companySeal = useWatch({ control, name: `invitees[${idx}].moreOptions.companySeal` });
    // const reqSupDoc = useWatch({ control, name: `invitees[${idx}].moreOptions.reqSupDoc` });
    // const security = useWatch({ control, name: `invitees[${idx}].moreOptions.security` });
    // const urlWebHooks = useWatch({ control, name: `invitees[${idx}].moreOptions.urlWebHooks` });
    // const consentTerms = useWatch({ control, name: `invitees[${idx}].moreOptions.consentTerms` });

    return (
        <>
            {/* old options */}
            <>
                {/* <Box mx={2} my={1}>
                <Controller
                    render={(
                        { onChange, onBlur, value, name, ref },
                        { invalid, isTouched, isDirty }
                    ) => (
                        <FormControlLabel
                            control={<Switch
                                onBlur={onBlur}
                                onChange={(e) => onChange(e.target.checked)}
                                checked={value}
                                inputRef={ref}
                            />}
                            label="Define maximum signing attempts allowed(only for Aadhaar eSign)"
                        />
                    )}
                    control={control}
                    name={`invitees[${idx}].moreOptions.isMaxAttempts`}
                    defaultValue={item?.moreOptions?.isMaxAttempts ?? false}
                />
                <Collapse in={isMaxAttempts}>
                    <Box pl={5} m={1}>
                        <Controller
                            render={(
                                { onChange, onBlur, value, name, ref },
                                { invalid, isTouched, isDirty }
                            ) => (
                                <TextField size="small" variant="outlined" type="number" fullWidth
                                    onBlur={onBlur}
                                    onChange={(e) => onChange(e.target.value)}
                                    value={value}
                                    inputRef={ref}
                                    placeholder="Retry Attempts"
                                    InputProps={{ inputProps: { min: 0 } }}
                                />
                            )}
                            control={control}
                            name={`invitees[${idx}].moreOptions.noOfMaxAttempts`}
                            defaultValue={item?.moreOptions?.noOfMaxAttempts ?? 0}
                        />
                    </Box>
                </Collapse>
            </Box>
            <Divider /> */}
            </>


            <>
                <Box m={2}>
                    <Controller
                        render={(
                            { onChange, onBlur, value, name, ref },
                            { invalid, isTouched, isDirty }
                        ) => (
                            <TextField size="small" variant="outlined" fullWidth
                                onBlur={onBlur}
                                onChange={(e) => onChange(e.target.value)}
                                value={value}
                                inputRef={ref}
                                label="Reason"
                                multiline
                            />
                        )}
                        control={control}
                        name={`invitees[${idx}].moreOptions.reason`}
                        defaultValue={item?.moreOptions?.reason ?? ""}
                    />
                </Box>
                <Box mx={2} my={1}>
                    <Controller
                        render={(
                            { onChange, onBlur, value, name, ref },
                            { invalid, isTouched, isDirty }
                        ) => (
                            <FormControlLabel
                                control={<Switch
                                    onBlur={onBlur}
                                    onChange={(e) => onChange(e.target.checked)}
                                    checked={value}
                                    inputRef={ref}
                                />}
                                label="Track Location"
                            />
                        )}
                        control={control}
                        name={`invitees[${idx}].moreOptions.track_location`}
                        defaultValue={item?.moreOptions?.track_location ?? false}
                    />
                </Box>
                <Divider />
                <Box mx={2} my={1}>
                    <Controller
                        render={(
                            { onChange, onBlur, value, name, ref },
                            { invalid, isTouched, isDirty }
                        ) => (
                            <FormControlLabel
                                control={<Switch
                                    onBlur={onBlur}
                                    onChange={(e) => onChange(e.target.checked)}
                                    checked={value}
                                    inputRef={ref}
                                />}
                                label="Accept Selfie"
                            />
                        )}
                        control={control}
                        name={`invitees[${idx}].moreOptions.accept_selfie`}
                        defaultValue={item?.moreOptions?.accept_selfie ?? false}
                    />
                </Box>
                <Divider />
                <Box mx={2} my={1}>
                    <Controller
                        render={(
                            { onChange, onBlur, value, name, ref },
                            { invalid, isTouched, isDirty }
                        ) => (
                            <FormControlLabel
                                control={<Switch
                                    onBlur={onBlur}
                                    onChange={(e) => onChange(e.target.checked)}
                                    checked={value}
                                    inputRef={ref}
                                />}
                                label="Allow Selfie Upload"
                            />
                        )}
                        control={control}
                        name={`invitees[${idx}].moreOptions.allow_selfie_upload`}
                        defaultValue={item?.moreOptions?.allow_selfie_upload ?? false}
                    />
                </Box>
                <Divider />
                <Box mx={2} my={1}>
                    <Controller
                        render={(
                            { onChange, onBlur, value, name, ref },
                            { invalid, isTouched, isDirty }
                        ) => (
                            <FormControlLabel
                                control={<Switch
                                    onBlur={onBlur}
                                    onChange={(e) => onChange(e.target.checked)}
                                    checked={value}
                                    inputRef={ref}
                                />}
                                label="Allow Download"
                            />
                        )}
                        control={control}
                        name={`invitees[${idx}].moreOptions.allow_download`}
                        defaultValue={item?.moreOptions?.allow_download ?? false}
                    />
                </Box>
                <Divider />
                <Box mx={2} my={1}>
                    <Controller
                        render={(
                            { onChange, onBlur, value, name, ref },
                            { invalid, isTouched, isDirty }
                        ) => (
                            <FormControlLabel
                                control={<Switch
                                    onBlur={onBlur}
                                    onChange={(e) => onChange(e.target.checked)}
                                    checked={value}
                                    inputRef={ref}
                                />}
                                label="Skip Otp"
                            />
                        )}
                        control={control}
                        name={`invitees[${idx}].moreOptions.skip_otp`}
                        defaultValue={item?.moreOptions?.skip_otp ?? false}
                    />
                </Box>
                <Divider />

            </>
            {/* OLD OPTIONS */}

            <>
                {/* 
            <Box mx={2} my={1}>
                <Controller
                    render={(
                        { onChange, onBlur, value, name, ref },
                        { invalid, isTouched, isDirty }
                    ) => (
                        <FormControlLabel
                            control={<Switch
                                onBlur={onBlur}
                                onChange={(e) => onChange(e.target.checked)}
                                checked={value}
                                inputRef={ref}
                            />}
                            label="Don't allow invitee from editing name in signing journey"
                        />
                    )}
                    control={control}
                    name={`invitees[${idx}].moreOptions.editName`}
                    defaultValue={item?.moreOptions?.editName ?? false}
                />
            </Box>
            <Divider />
            <Box mx={2} my={1}>
                <Controller
                    render={(
                        { onChange, onBlur, value, name, ref },
                        { invalid, isTouched, isDirty }
                    ) => (
                        <FormControlLabel
                            control={<Switch
                                onBlur={onBlur}
                                onChange={(e) => onChange(e.target.checked)}
                                checked={value}
                                inputRef={ref}
                            />}
                            label="Don't show invitee name in signature appearance"
                        />
                    )}
                    control={control}
                    name={`invitees[${idx}].moreOptions.showName`}
                    defaultValue={item?.moreOptions?.showName ?? false}
                />
            </Box>
            <Divider />
            <Box mx={2} my={1}>
                <Controller
                    render={(
                        { onChange, onBlur, value, name, ref },
                        { invalid, isTouched, isDirty }
                    ) => (
                        <FormControlLabel
                            control={<Switch
                                onBlur={onBlur}
                                onChange={(e) => onChange(e.target.checked)}
                                checked={value}
                                inputRef={ref}
                            />}
                            label="Require company seal from invitee"
                        />
                    )}
                    control={control}
                    name={`invitees[${idx}].moreOptions.companySeal`}
                    defaultValue={item?.moreOptions?.companySeal ?? false}
                />
                <Collapse in={companySeal}>
                    <>
                        <Box pl={5} m={1}>
                            <Controller
                                as={<TextField size="small" variant="outlined" fullWidth
                                    placeholder="Set organisation name"
                                />}
                                control={control}
                                name={`invitees[${idx}].moreOptions.organisationName`}
                                defaultValue={item?.moreOptions?.organisationName ?? ""}
                            />
                        </Box>
                        <Box pl={5} m={1}>
                            <Typography variant="subtitle1">Select Seal type</Typography>
                            <Controller
                                as={<TextField size="small" variant="outlined" fullWidth select>
                                    <MenuItem value="Authorised"> Authorised signatory seal </MenuItem>
                                    <MenuItem value="Director"> Director seal </MenuItem>
                                </TextField>}
                                control={control}
                                name={`invitees[${idx}].moreOptions.sealType`}
                                defaultValue={item?.moreOptions?.sealType ?? "Director"}
                            />
                        </Box>
                    </>
                </Collapse>
            </Box>
            <Divider />
            <Box mx={2} my={1}>
                <Controller
                    render={(
                        { onChange, onBlur, value, name, ref },
                        { invalid, isTouched, isDirty }
                    ) => (
                        <FormControlLabel
                            control={<Switch
                                onBlur={onBlur}
                                onChange={(e) => onChange(e.target.checked)}
                                checked={value}
                                inputRef={ref}
                            />}
                            label="Require invitee to upload supporting documents"
                        />
                    )}
                    control={control}
                    name={`invitees[${idx}].moreOptions.reqSupDoc`}
                    defaultValue={item?.moreOptions?.reqSupDoc ?? false}
                />
                <Collapse in={reqSupDoc}>
                    <Box pl={5} m={1}>
                        <Controller
                            as={<TextField size="small" variant="outlined" fullWidth
                                placeholder="Documents to be uploaded"
                            />}
                            control={control}
                            name={`invitees[${idx}].moreOptions.supportingDocuments`}
                            defaultValue={item?.moreOptions?.supportingDocuments ?? ""}
                        />
                    </Box>
                </Collapse>
            </Box>
            <Divider />
            <Box mx={2} my={1}>
                <Controller
                    render={(
                        { onChange, onBlur, value, name, ref },
                        { invalid, isTouched, isDirty }
                    ) => (
                        <FormControlLabel
                            control={<Switch
                                onBlur={onBlur}
                                onChange={(e) => onChange(e.target.checked)}
                                checked={value}
                                inputRef={ref}
                            />}
                            label="Enable security features"
                        />
                    )}
                    control={control}
                    name={`invitees[${idx}].moreOptions.security`}
                    defaultValue={item?.moreOptions?.security ?? false}
                />
                <Collapse in={security}>
                    <>
                        <Box pl={5} m={1}>
                            <Controller
                                render={(
                                    { onChange, onBlur, value, name, ref },
                                    { invalid, isTouched, isDirty }
                                ) => (
                                    <FormControlLabel
                                        control={<Switch
                                            onBlur={onBlur}
                                            onChange={(e) => onChange(e.target.checked)}
                                            checked={value}
                                            inputRef={ref}
                                        />}
                                        label={<Typography variant="body2">Require 'Two factor authentication' before document preview</Typography>}
                                    />
                                )}
                                control={control}
                                name={`invitees[${idx}].moreOptions.twoFactor`}
                                defaultValue={item?.moreOptions?.twoFactor ?? false}
                            />
                        </Box>
                        <Box pl={5} m={1}>
                            <Controller
                                render={(
                                    { onChange, onBlur, value, name, ref },
                                    { invalid, isTouched, isDirty }
                                ) => (
                                    <FormControlLabel
                                        control={<Switch
                                            onBlur={onBlur}
                                            onChange={(e) => onChange(e.target.checked)}
                                            checked={value}
                                            inputRef={ref}
                                        />}
                                        label={<Typography variant="body2">Send document download link with all OTP email and messages</Typography>}
                                    />
                                )}
                                control={control}
                                name={`invitees[${idx}].moreOptions.sendDownloadLink`}
                                defaultValue={item?.moreOptions?.sendDownloadLink ?? false}
                            />
                        </Box>
                        <Box pl={5} m={1}>
                            <Controller
                                render={(
                                    { onChange, onBlur, value, name, ref },
                                    { invalid, isTouched, isDirty }
                                ) => (
                                    <FormControlLabel
                                        control={<Switch
                                            onBlur={onBlur}
                                            onChange={(e) => onChange(e.target.checked)}
                                            checked={value}
                                            inputRef={ref}
                                        />}
                                        label={<Typography variant="body2">Capture GPS location</Typography>}
                                    />
                                )}
                                control={control}
                                name={`invitees[${idx}].moreOptions.captureGPS`}
                                defaultValue={item?.moreOptions?.captureGPS ?? false}
                            />
                        </Box>
                        <Box pl={5} m={1}>
                            <Controller
                                render={(
                                    { onChange, onBlur, value, name, ref },
                                    { invalid, isTouched, isDirty }
                                ) => (
                                    <FormControlLabel
                                        control={<Switch
                                            onBlur={onBlur}
                                            onChange={(e) => onChange(e.target.checked)}
                                            checked={value}
                                            inputRef={ref}
                                        />}
                                        label={<Typography variant="body2">Capture photo</Typography>}
                                    />
                                )}
                                control={control}
                                name={`invitees[${idx}].moreOptions.capturePhoto`}
                                defaultValue={item?.moreOptions?.capturePhoto ?? false}
                            />
                        </Box>
                    </>
                </Collapse>
            </Box>
            <Divider />
            <Box mx={2} my={1}>
                <Controller
                    render={(
                        { onChange, onBlur, value, name, ref },
                        { invalid, isTouched, isDirty }
                    ) => (
                        <FormControlLabel
                            control={<Switch
                                onBlur={onBlur}
                                onChange={(e) => onChange(e.target.checked)}
                                checked={value}
                                inputRef={ref}
                            />}
                            label="Send email notification"
                        />
                    )}
                    control={control}
                    name={`invitees[${idx}].moreOptions.emailNotif`}
                    defaultValue={item?.moreOptions?.emailNotif ?? true}
                />
            </Box>
            <Divider />
            <Box mx={2} my={1}>
                <Controller
                    render={(
                        { onChange, onBlur, value, name, ref },
                        { invalid, isTouched, isDirty }
                    ) => (
                        <FormControlLabel
                            control={<Switch
                                onBlur={onBlur}
                                onChange={(e) => onChange(e.target.checked)}
                                checked={value}
                                inputRef={ref}
                            />}
                            label=" Send phone notification"
                        />
                    )}
                    control={control}
                    name={`invitees[${idx}].moreOptions.phoneNotif`}
                    defaultValue={item?.moreOptions?.phoneNotif ?? true}
                />
            </Box>
            <Divider />
            <Box mx={2} my={1}>
                <Controller
                    render={(
                        { onChange, onBlur, value, name, ref },
                        { invalid, isTouched, isDirty }
                    ) => (
                        <FormControlLabel
                            control={<Switch
                                onBlur={onBlur}
                                onChange={(e) => onChange(e.target.checked)}
                                checked={value}
                                inputRef={ref}
                            />}
                            label="Add custom URLs and webhooks"
                        />
                    )}
                    control={control}
                    name={`invitees[${idx}].moreOptions.urlWebHooks`}
                    defaultValue={item?.moreOptions?.urlWebHooks ?? false}
                />
                <Collapse in={urlWebHooks}>
                    <>
                        <Box pl={5} m={1}>
                            <Controller
                                as={<TextField size="small" variant="outlined" fullWidth
                                    placeholder="Base Url"
                                />}
                                control={control}
                                name={`invitees[${idx}].moreOptions.baseUrl`}
                                defaultValue={item?.moreOptions?.baseUrl ?? ""}
                            />
                        </Box>
                        <Box pl={5} m={1}>
                            <Controller
                                as={<TextField size="small" variant="outlined" fullWidth
                                    placeholder="Redirect Url"
                                />}
                                control={control}
                                name={`invitees[${idx}].moreOptions.redirectUrl`}
                                defaultValue={item?.moreOptions?.redirectUrl ?? ""}
                            />
                        </Box>
                        <Box pl={5} m={1}>
                            <Controller
                                as={<TextField size="small" variant="outlined" fullWidth
                                    placeholder="Webhook "
                                />}
                                control={control}
                                name={`invitees[${idx}].moreOptions.webhook`}
                                defaultValue={item?.moreOptions?.webhook ?? ""}
                            />
                        </Box>
                        <Box pl={5} m={1}>
                            <Controller
                                as={<TextField size="small" variant="outlined" fullWidth
                                    placeholder="Error Webhook"
                                />}
                                control={control}
                                name={`invitees[${idx}].moreOptions.erroWebhook`}
                                defaultValue={item?.moreOptions?.erroWebhook ?? ""}
                            />
                        </Box>
                    </>
                </Collapse>
            </Box>
            <Divider />
            <Box mx={2} my={1}>
                <Controller
                    render={(
                        { onChange, onBlur, value, name, ref },
                        { invalid, isTouched, isDirty }
                    ) => (
                        <FormControlLabel
                            control={<Switch
                                onBlur={onBlur}
                                onChange={(e) => onChange(e.target.checked)}
                                checked={value}
                                inputRef={ref}
                            />}
                            label="Set additional consent terms"
                        />
                    )}
                    control={control}
                    name={`invitees[${idx}].moreOptions.consentTerms`}
                    defaultValue={item?.moreOptions?.consentTerms ?? false}
                />
                <Collapse in={consentTerms}>
                    <Box pl={5} m={1}>
                        <Controller
                            as={<TextField size="small" variant="outlined" fullWidth multiline rows={3} rowsMax={3}
                                placeholder="Custom Consent"
                            />}
                            control={control}
                            name={`invitees[${idx}].moreOptions.customConsent`}
                            defaultValue={item?.moreOptions?.customConsent ?? ""}
                        />
                    </Box>
                </Collapse>
            </Box>
            <Divider /> */}
            </>
        </>
    )
}



