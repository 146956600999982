import {
  CANCEL_MODAL,
  CANCEL_SCHEDULE_MODAL,
  GENERATE_TOKEN_FAILURE,
  MODAL_STATE,
  REVOKE_TOKEN_FAIURE,
  SUCCESS_MODAL_STATE,
  TOKEN_LIST_FAILURE,
  CANCEL_CHANGE_MODAL,
  CHANGE_PLAN_MODAL, SET_DEMO_API_REQUEST_INITIATED, SET_DEMO_API_REQUEST_FAILURE, SET_DEMO_API_REQUEST_SUCCESS,
  SET_CLIENT_ID, FIRST_TIME_ONBOARD, CHARGEBEE_DETAILS, GOOGLE_CLIENT_ID, SIGN_OUT_FAILURE,
} from "auth";
import {
  CALL_STARTED,
  FIRST_TIME,
  GET_DETAILS,
  INIT_AUTH,
  INIT_TOKEN,
  INIT_TOKEN_FAILURE,
  INIT_TOKEN_SUCCESS,
  INNER_TOGGLE_DRAWER,
  OTP_SENT_FAILED,
  OTP_SENT_TRUE,
  OTP_SUBMIT_FAILED,
  OTP_SUBMIT_SUCCESS,
  OTP_VERIFIED_TRUE,
  OUTER_TOGGLE_DRAWER,
  REFRESH_TOKEN_FAILED,
  REFRESH_TOKEN_SUCCESS,
  SET_EMAIL,
  SET_FULL_NAME,
  SET_PHONE_NUMBER,
  SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS,
  SUBMIT_DETAILS,
  TOKEN_LIST,
  IS_LOADER,
  CLEAR_ERROR_MESSAGE,
  CLEAR_STATUS_CODE,
  CLEAR_ERROR,
  CLEAR_OTP

} from "./action-types";
import localforage from "localforage";


export function modalState() {
  return {
    openModal: false,
    successModal: false,
    cancelModalState: false,
    cancelSchedule: false,
  };
}

export function modalReducer(state = new modalState(), { payload, type }) {
  switch (type) {
    case MODAL_STATE:
      return {
        ...state,
        openModal: payload,
      };
    case SUCCESS_MODAL_STATE:
      return {
        ...state,
        successModalOpen: payload,
      };
    case CANCEL_MODAL:
      return {
        ...state,
        cancelModalState: payload,
      };
    case CANCEL_SCHEDULE_MODAL:
      return {
        ...state,
        cancelSchedule: payload,
      };
    case CHANGE_PLAN_MODAL:
      return {
        ...state,
        changePlanModal: payload,
      };
    case CANCEL_CHANGE_MODAL: {
      return {
        ...state,
        cancelChangeModal: payload,
      };
    }
      case SIGN_OUT_SUCCESS:return new modalState()

    default:
      return state;
  }
}

export function AuthState() {
  return {
    authenticated: false,
    id: null,
    clientId: "",
    otpSent: false,
    otpVerified: false,
    onboarded:false,
    statusCode: "",
    onboardRequired: false, // onboard = is new user (i.e sign in required?)
    firstOnboard:false,
    phone_number: "",
    email: "",
    fullname: "",
    errorMsg: "",
   
    accessToken: "",
    mca_credentials: false,
    innerToggleDrawer: false,
    outerToggleDrawer: true,
    initLoading: false,
    generatedLink: "",
    tokenLists: [],
    loader: false,
    chargebeeDetails:null,
    googleClientId:"",
    sentOtpLoader:false,
    
    responseFromDemoAPI:{},
    demoAPILoading:false,
  };
}



export function authReducer(state = new AuthState(), { payload, type }) {
  switch (type) {

    case CHARGEBEE_DETAILS:return{
      ...state,
      chargebeeDetails: payload
    }
    case GOOGLE_CLIENT_ID:
      // console.log(payload)
      return{

      ...state,
      googleClientId: payload?.data?.gmail_client_id
    }
    case CLEAR_ERROR: return {
      ...state,
      errorMsg: "",
      statusCode:""
    }

    case SIGN_OUT_FAILURE:
      return {
        ...state,
        statusCode: payload ? payload.data.status_code : false,
        errorMsg: payload ? payload.data.message : ""
      }

    case IS_LOADER:
      return {
        ...state,
        loader: payload,
      };

    case SET_DEMO_API_REQUEST_INITIATED:
      return {
        ...state,
        demoAPILoading: true,
        responseFromDemoAPI: {},
        errorMsg: "",
        statusCode: ""
      }
    case SET_DEMO_API_REQUEST_FAILURE:
      return {
        ...state,
        demoAPILoading: false,
        responseFromDemoAPI: {},
        statusCode: payload ? payload.data.status_code : false,
        errorMsg: payload ? payload.data.message : "",
      }
    case SET_DEMO_API_REQUEST_SUCCESS:
      return {
        ...state,
        demoAPILoading: false,
        responseFromDemoAPI: payload?.data ? payload?.data : {},
        statusCode: "",
        errorMsg: ""
      }
    case CLEAR_STATUS_CODE:
      return {
        ...state,
        statusCode: payload,
      };
    case CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: payload,
      };

    case INIT_AUTH:
    case SIGN_IN_SUCCESS:
      // console.log("Onboard after refresh",!payload.data.onboard_required)
      return {
        ...state,
        authenticated: !payload.data.onboard_required,
        sentOtpLoader: false
        // authenticated: true,
        // id: payload ? payload.uid : null
      };
    case GET_DETAILS:
 
        
      return {
        ...state,
        fullname: payload ? payload.data.name : null,
        email: payload ? payload.data.email : null,
        phone_number: payload ? payload.data.mobile : null,
        onboardRequired:payload.data?.onboard_required,
        authenticated:payload?.data?.onboarded,
        onboarded:payload.data.onboarded

          
      };
    case SIGN_OUT_SUCCESS:
      return new AuthState();

    case OTP_SENT_TRUE:
      return {
        ...state,
        otpSent: payload ? payload.data.otp_sent : false,
        clientId: payload ? payload.data.client_id : "",
        sentOtpLoader: false,
        errorMsg: "",
        statusCode: false,
      };
    case OTP_SENT_FAILED:
      return {
        ...state,
        statusCode: payload ? payload.data.status_code : false,
        errorMsg: payload ? payload.data.message : "",
        sentOtpLoader: false
      };
    case OTP_SUBMIT_SUCCESS:
      return {
        ...state,
        otpVerified: payload ? payload.data.verified : false,
        onboardRequired: payload ? payload.data.onboard_required : false,
        accessToken: payload ? payload.data.access_token : "",
        errorMsg: "",
        statusCode: false,
        sentOtpLoader: false
      };
    case CALL_STARTED:
      return {
        ...state,
        errorMsg: "",
        statusCode: false,
        sentOtpLoader: true
      };
    case SET_CLIENT_ID: return {
      ...state,
      clientId:payload,
    }
    case CLEAR_OTP: return {
      ...state,
      otpSent:payload
    }
    case OTP_SUBMIT_FAILED:
      return {
        ...state,
        otpVerified: false,
        otpSent:false,

        statusCode: payload ? payload.data.status_code : false,
        errorMsg: payload ? payload.data.message : "",
        sentOtpLoader: false
      };
    case OTP_VERIFIED_TRUE:
      return {
        ...state,
        otpVerified: payload ? payload.otpVerified : "",
      };
    case SUBMIT_DETAILS:
      return {
        ...state,
        authenticated: payload ? payload.data.success : "",
        firstTime: payload ? payload.firstTime : false,
        errorMsg: "",
        statusCode: false,
      };

    case REFRESH_TOKEN_SUCCESS:

      return {
        ...state,
        accessToken: payload ? payload.data.access_token : "",
        errorMsg: "",
        statusCode: false,
      };
    case REFRESH_TOKEN_FAILED:
      return {
        ...state,
        // accessToken : payload ? payload.data.data.access_token : "",
        statusCode: payload ? payload.data.status_code : false,
        errorMsg: payload ? payload.data.message : "",
        authenticated: false,
      };
    case GENERATE_TOKEN_FAILURE:
      return {
        ...state,
        statusCode: payload ? payload.data.status_code : false,
        errorMsg: payload ? payload.data.message : "",
      };
    case SET_PHONE_NUMBER:
      return {
        ...state,
        phone_number: payload,
      };

   
    case SET_FULL_NAME:
      return {
        ...state,
        fullname: payload,
      };
    case SET_EMAIL:
      return {
        ...state,
        email: payload,
      };

    case INNER_TOGGLE_DRAWER:
      return {
        ...state,
        innerToggleDrawer: payload,
        // outerToggleDrawer:!payload
      };
    case OUTER_TOGGLE_DRAWER:
      return {
        ...state,
        outerToggleDrawer: payload,
      };
    case INIT_TOKEN:
      return {
        ...state,
        initLoading: true,
        generatedLink: "",
        errorMsg: "",
      };
    
    case INIT_TOKEN_SUCCESS:
      return {
        ...state,
        initLoading: false,
        generatedLink: payload.data.url,
        errorMsg: "",
      };
    case INIT_TOKEN_FAILURE:
      return {
        ...state,
        initLoading: false,
        generatedLink: "",
        errorMsg: payload.data.message,
      };
    case TOKEN_LIST:
      return {
        ...state,
        tokenLists: payload.data,
      };
    case TOKEN_LIST_FAILURE:
      return {
        ...state,
        errorMsg: payload ? payload.data.message : "",
        statusCode: payload ? payload.data.status_code : false,
      };
    case REVOKE_TOKEN_FAIURE:
      return {
        ...state,
        errorMsg: payload ? payload.data.message : "",
        statusCode: payload ? payload.data.status_code : false,
      };
    case GENERATE_TOKEN_FAILURE:
      return {
        ...state,
        errorMsg: payload ? payload.data.message : "",
        statusCode: payload ? payload.data.status_code : false,
      };
    default:
      return state;
  }
}
