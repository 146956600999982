import {
  Box,
  Button,
  Divider,
  InputLabel,
  makeStyles,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { UiActions } from "globalUI";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { withRouter } from "react-router";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(2),
    textTransform: "none",
  },
  darkText: {
    color: theme.palette.grey[700],
  },
}));

const Branding = () => {
  const classes = useStyles();

  const [successAlert, setSuccessAlert] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    control,
    errors,
    setError,
    clearErrors,
    getValues,
  } = useForm({
    defaultValues: {},
    mode: "onBlur",
  });

  const brandLogo = watch("brandLogo");

  const onSubmit = (data) => {
    // console.log(data);
    setSuccessAlert(true);
  };

  return (
    <>
      <Snackbar
        open={successAlert}
        autoHideDuration={3000}
        onClose={() => setSuccessAlert(false)}
      >
        <Alert onClose={() => setSuccessAlert(false)} severity="success">
          User info updated successfully.
        </Alert>
      </Snackbar>
      <Box mt={3} mb={2}>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Manage the Branding for your Signing Pages here!
        </Typography>
      </Box>
      <Divider />
      <Box
        my={2}
        width={"50%"}
        display="flex"
        flexDirection="column"
        style={{ gap: 10 }}
      >
        <InputLabel className={classes.darkText} htmlFor="brandName">
          {" "}
          <br />
          Brand Name
        </InputLabel>
        <Controller
          render={(
            { onChange, onBlur, value, name, ref },
            { invalid, isTouched, isDirty }
          ) => (
            <TextField
              size="small"
              variant="outlined"
              id="brandName"
              error={!!errors?.brandName}
              onBlur={onBlur}
              onChange={(e) => onChange(e.target.value)}
              value={value}
              inputRef={ref}
              placeholder="Brand Name"
            />
          )}
          control={control}
          name="brandName"
          defaultValue={""}
        />
        <InputLabel className={classes.darkText} htmlFor="brandColor">
          <br />
          Brand Color <small>(Enter value in '#ffffff' format) </small>
        </InputLabel>
        <Controller
          render={(
            { onChange, onBlur, value, name, ref },
            { invalid, isTouched, isDirty }
          ) => (
            <TextField
              size="small"
              variant="outlined"
              id="brandColor"
              error={!!errors?.brandColor}
              onBlur={onBlur}
              onChange={(e) => onChange(e.target.value)}
              value={value}
              inputRef={ref}
              placeholder="Brand Color"
            />
          )}
          control={control}
          name="brandColor"
          defaultValue={""}
        />
        <InputLabel className={classes.darkText}>
          <br />
          Brand Logo
        </InputLabel>
        <Controller
          render={(
            { onChange, onBlur, value, name, ref },
            { invalid, isTouched, isDirty }
          ) => (
            <>
              <InputLabel
                htmlFor="brandLogo"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Box
                  p={1}
                  my={1}
                  mr={3}
                  width={"fit-content"}
                  fontSize={14}
                  style={{ cursor: "pointer" }}
                  border={1}
                  borderColor="primary.main"
                  borderRadius={2}
                  color="primary.main"
                >
                  Upload Logo
                </Box>
                <Typography>{brandLogo?.name}</Typography>
              </InputLabel>
              <input
                id="brandLogo"
                type="file"
                accept="image/x-png,image/jpeg"
                onBlur={onBlur}
                onChange={(e) => onChange(e.target.files[0])}
                style={{ display: "none" }}
              />
            </>
          )}
          control={control}
          name="brandLogo"
          defaultValue={""}
        />
      </Box>
      <Divider />
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={handleSubmit(onSubmit)}
      >
        Save
      </Button>
    </>
  );
};

const mapStateToProps = (state) => ({
  createData: state.ui.createData,
  inviteeData: state.ui.inviteData,
});

const mapDispatchToProps = {
  setCreateData: UiActions.setCreateData,
  setInviteeData: UiActions.setInviteData,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Branding)
);
