import {
    SET_CREATE_DATA,
    SET_INVITE_DATA,
} from './action-types';

export function setCreateData(data) {
    return {
        type: SET_CREATE_DATA,
        payload: data
    };
}

export function setInviteData(data) {
    return {
        type: SET_INVITE_DATA,
        payload: data
    };
}
