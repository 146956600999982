import React, { useState, useEffect } from "react";
import {
    Box,
    Grid,
    TextField,
    InputLabel,
    MuiThemeProvider,
    makeStyles,
    createMuiTheme,
    Button,
    Typography,
} from "@material-ui/core";
import { useHistory } from "react-router";
import InfoIcon from "@material-ui/icons/Info"
import { authActions } from "auth";
import { connect, useDispatch, useSelector } from "react-redux";
import { generateToken, tokenList } from "auth/actions";
import {Clear} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(4),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1, 4, 1, 4),

        textTransform: "none",
        fontSize: "16px",
        borderRadius: "8px",
        boxShadow: "0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
        "&:hover": {
            background:theme.palette.primary.main
        }
    },
    darkText: {
        color: theme.palette.grey[700],
        padding: theme.spacing(1, 0, 1, 0),
    },
    subscribed: {
        fontSize: "32px",
        fontWeight: "bold",
        lineHeight: 3,
    },
    unsubscribe: {
        fontSize: "20px",
        fontWeight: "bold",
        paddingTop: "16px",
        lineHeight: 1,
    },
    paper: {
        position: "absolute",
        width: 500,
        backgroundColor: theme.palette.background.paper,
        borderRadius: "10px",
        boxShadow: theme.shadows[5],
        padding: "10px 22px 10px",
    },
    clear:{
        color:"#111",
        cursor:"pointer"
    }
}));
const GenerateTokenNotification = (props) => {
    const getMuiTheme = () =>
        createMuiTheme((theme)=>({
            overrides: {
                MuiSvgIcon: {
                    fontSizeLarge: "4.1875rem",
                },
                MUIDataTableToolbar: {
                    actions: {
                        display: "none",
                    },
                },
                MuiTableCell: {
                    root: {
                        padding: "6px",
                    },
                },
                MuiPaper: {
                    root: {
                        width: "100%",
                        marginTop: "20px",
                    },
                },
                MuiInputBase: {
                    formControl: {
                        "&:hover": {
                            outline: "none",
                        },
                    },
                },
                MuiButtonBase: {
                    root: {
                        backgroundColor: "rgb(63, 81, 181)",

                        fontWeight: "500",
                        fontSize: ".875rem",

                        lineHeight: "1",
                    },
                },
                MuiButton: {
                    root: {
                        textTransform: "capitalize",
                        margin: "1rem 0",
                        "&:hover": {
                            backgroundColor: "rgb(63, 81, 181)",
                        },
                    },

                    text: {
                        color: "#fff",
                    },
                },
            },
        }));

    const { tokenLists } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const classes = useStyles();

    const [copySuccess, setCopySuccess] = useState("");
    const history = useHistory();

    // const [copied, setCopied] = useState(false);
    const { currentPlan } = useSelector((state) => state.clientData);


    const { openModal } = useSelector((state) => state.modalRed);
    const [successAlert, setSuccessAlert] = useState(false);
    const [successRevoke, setSuccessRevoke] = useState(false);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [apiGenerated, setApiGenerated] = useState(false);
    const [error, setError] = useState(false);
    const [tokenName, setTokenName] = useState("");
    const [open, setOpen] = useState(false);

    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const handleClose = (e) => {
        e.preventDefault();
props.setNotification(false)





        // console.log(props.tokenList);
    };





    return (
        <div style={modalStyle} className={classes.paper}>
            <Grid container justifyContent={"flex-end"}>
                <Clear className={classes.clear} onClick={handleClose}/>
            </Grid>
            <Grid container justifyContent="center" alignItems={"center"} >

                {tokenLists?.length===0 && (
                    <InfoIcon
color={"primary"}
                        className={classes.icon}
                        fontSize="large"
                        style={{ fontSize: "4.51875rem" }}
                    />
                )}

                <Typography
                    variant="h6"
                    color="textPrimary"
                    align={"center"}
                    gutterBottom


                >
                    You don't have any API key now, Please generate.
                </Typography>
            </Grid>

        </div>
    );
};


// const mapDispatchToProps = {
//   generateToken: authActions.generateToken,
//   tokenListCall: authActions.tokenList,
//   revokeToken: authActions.callRevokeToken,
// };

export default GenerateTokenNotification;
