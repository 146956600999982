import {
  Box,
  Button,
  Divider,
  InputLabel,
  makeStyles,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { UiActions } from "globalUI";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { withRouter } from "react-router";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(2),
    textTransform: "none",
  },
  darkText: {
    color: theme.palette.grey[700],
  },
}));

const BalanceThreshold = () => {
  const classes = useStyles();

  const [successAlert, setSuccessAlert] = useState(false);

  const { handleSubmit, watch, control, setValue, errors } = useForm({
    defaultValues: {},
    mode: "onBlur",
  });

  const validatePercentage = (e) => {
    if (e === "") {
      return true;
    }
    return parseInt(e) >= 0 && parseInt(e) <= 100;
  };

  const onSubmit = (data) => {
    // console.log(data);
    setSuccessAlert(true);
  };

  return (
    <>
      <Snackbar
        open={successAlert}
        autoHideDuration={3000}
        onClose={() => setSuccessAlert(false)}
      >
        <Alert onClose={() => setSuccessAlert(false)} severity="success">
          Wallet details updated successfully.
        </Alert>
      </Snackbar>
      <Box mt={3} mb={2}>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Manage the minimum threshold for eSign Credits and Stamp notification
          here!
        </Typography>
      </Box>
      <Divider light={true} />
      <Box
        my={4}
        width={"50%"}
        display="flex"
        flexDirection="column"
        style={{ gap: 10 }}
      >
        <InputLabel className={classes.darkText} htmlFor="eSignCredits">
          {" "}
          Minimum % of eSign Credits from last purchase
        </InputLabel>
        <Controller
          render={({ onChange, onBlur, value, ref }) => (
            <TextField
              size="small"
              variant="outlined"
              type="number"
              id="eSignCredits"
              error={!!errors.eSignCredits}
              helperText={
                !!errors.eSignCredits && "Accepted value of range 0-100."
              }
              onBlur={onBlur}
              onChange={(e) => onChange(e.target.value)}
              value={value}
              inputRef={ref}
              placeholder="Enter minimum eSign credits"
              InputProps={{ inputProps: { min: 0, max: 100 } }}
            />
          )}
          control={control}
          name={`eSignCredits`}
          rules={{ validate: validatePercentage }}
          defaultValue={""}
        />
        <InputLabel className={classes.darkText} htmlFor="eStamps">
          <br />
          Minimum % of Stamps from last purchase
        </InputLabel>
        <Controller
          render={({ onChange, onBlur, value, ref }) => (
            <TextField
              size="small"
              variant="outlined"
              type="number"
              id="eStamps"
              error={!!errors.eStamps}
              helperText={!!errors.eStamps && "Accepted value of range 0-100."}
              onBlur={onBlur}
              onChange={(e) => onChange(e.target.value)}
              value={value}
              inputRef={ref}
              placeholder="Enter minimum eStamps"
              InputProps={{ inputProps: { min: 0, max: 100 } }}
            />
          )}
          control={control}
          name={`eStamps`}
          rules={{ validate: validatePercentage }}
          defaultValue={""}
        />
      </Box>
      <Divider />
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={handleSubmit(onSubmit)}
      >
        Save
      </Button>
    </>
  );
};

const mapStateToProps = (state) => ({
  createData: state.ui.createData,
  inviteeData: state.ui.inviteData,
});

const mapDispatchToProps = {
  setCreateData: UiActions.setCreateData,
  setInviteeData: UiActions.setInviteData,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BalanceThreshold)
);
