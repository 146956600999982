import React from "react";
import { Chip, makeStyles } from '@material-ui/core';
import classNames from "classnames";

import { purple, red, green, teal, indigo, orange } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    chip: {
        fontWeight: 600,
        letterSpacing: "0.15ch"
    },
    chipPurple: props => ({
        color: purple[props.dark],
        backgroundColor: purple[props.light],
        border: `1px solid ${purple[100]}`
    }),
    chipGreen: props => ({
        color: green[props.dark],
        backgroundColor: green[props.light],
        border: `1px solid ${green[100]}`
    }),
    chipIndigo: props => ({
        color: indigo[props.dark],
        backgroundColor: indigo[props.light],
        border: `1px solid ${indigo[100]}`
    }),
    chipTeal: props => ({
        color: teal[props.dark],
        backgroundColor: teal[props.light],
        border: `1px solid ${teal[100]}`
    }),
    chipOrange: props => ({
        color: orange[props.dark],
        backgroundColor: orange[props.light],
        border: `1px solid ${orange[100]}`
    }),
    chipRed: props => ({
        color: red[props.dark],
        backgroundColor: red[props.light],
        border: `1px solid ${red[100]}`
    }),
}));



export default function StatusTag({ ...props }) {
    const classes = useStyles({ dark: 800, light: 50 });

    // color code for specific status
    const getColor = (val) => {
        switch (val) {
            case ("actionRequired"):
                return classes.chipPurple
            case ("waitingForOthers"):
            case ("sent"):
                return classes.chipTeal
            case("pending"):
            case ("expiringSoon"):
            case ("received"):
                return classes.chipOrange
            case ("completed"):
            case ("signIn"):
                return classes.chipGreen
            case ("failed"):
            case ("expired"):
            case ("signOut"):
                return classes.chipRed
            default:
                return classes.chipIndigo
        }
    }

    // get label from api key
    const getLabel = (val) => {
        const result = val?.replace(/([A-Z])/g, " $1");
        return result?.charAt(0).toUpperCase() + result?.slice(1);
    }


    return (
        <Chip
            className={classNames(classes.chip, getColor(props.value))}
            label={getLabel(props.value) ?? ""}
        />
    )
}
