import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  Link as MLink,
  Paper,
  TextField,
  Typography,
  MenuItem,
  makeStyles,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Menu,
  InputBase,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentText,
  DialogContent,
  Slide,
  Grow,
  InputLabel,
} from "@material-ui/core";
import {
  SwapVertRounded,
  SwapVerticalCircleRounded,
  UnfoldMoreRounded,
  SearchOutlined,
  Search,
  Directions,
  CloseRounded,
} from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import React, { forwardRef, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { DatePicker } from "views/components/date-picker/DatePicker";
import DocTable from "views/components/docTable";
import FolderCard from "./FolderCard";
import getUniqueName from "utils/getUnique";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(4),
    textTransform: "none",
  },
  root: {
    marginRight: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    maxWidth: 400,
  },
  searchBtn: {
    margin: 5,
    color: theme.palette.grey[500],
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const RootFolders = () => {
  const classes = useStyles();

  const [rootFolders, setRootFolders] = useState([
    // { id: "asf9qqmids", name: "TstFolder 1", date: "2020-11-03T18:07:01.560000", docs: [], folders: [] },
    // { id: "239n20jd0e", name: "TstFolder 2", date: "2020-05-18T18:07:01.560000", docs: [], folders: [] },
  ]);

  const createFolder = () => {
    let resp = window.prompt("Enter Folder Name", "New Folder");
    if (!resp?.trim()) {
      return;
    }

    let name = getUniqueName(resp, rootFolders);

    setRootFolders((prev) => [
      {
        name,
        id: uuidv4().split("-").pop(),
        date: Date.now(),
        docs: [],
        folders: [],
      },
      ...prev,
    ]);
  };

  const deleteFolder = (fldrId) => {
    setRootFolders((prev) => [...prev.filter((f) => f.id !== fldrId)]);
  };

  const renameFolder = (fldrId, name) => {
    setRootFolders((prev) => [
      ...prev.map((f) => {
        if (f.id === fldrId) {
          return {
            ...f,
            name: getUniqueName(name, rootFolders),
          };
        }
        return f;
      }),
    ]);
  };

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Box my={2} mx={8}>
            <Breadcrumbs itemsAfterCollapse={3} aria-label="breadcrumb">
              <MLink component={Link} color="inherit" to="/">
                Home
              </MLink>
              <MLink component={Link} color="inherit" to="/folders">
                Root
              </MLink>
            </Breadcrumbs>
          </Box>
          <Divider />
          <br />
        </Grid>
        <Grid
          component={Paper}
          variant="outlined"
          container
          item
          xs={11}
          justifyContent="center"
          style={{ marginBottom: 10 }}
        >
          <Grid item xs={12}>
            <Box
              m={3}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box flexGrow={1}>
                <Paper className={classes.root} variant="outlined">
                  <InputBase
                    className={classes.input}
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                  />
                  <Divider className={classes.divider} orientation="vertical" />
                  <Search fontSize="small" className={classes.searchBtn} />
                </Paper>
              </Box>
              <Box
                display="flex"
                flexGrow={1}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  size="small"
                  className={classes.button}
                  startIcon={<SwapVertRounded fontSize="small" />}
                >
                  Name
                </Button>
                <Button
                  size="small"
                  className={classes.button}
                  startIcon={<SwapVertRounded fontSize="small" />}
                >
                  Date
                </Button>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={createFolder}
                >
                  New Folder
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box m={2} display="flex" flexWrap={"wrap"}>
              {!rootFolders?.length ? (
                <Typography vataint={"caption"}> No Folders!</Typography>
              ) : (
                <>
                  {rootFolders.map((item) => (
                    <FolderCard
                      key={item.id}
                      name={item.name}
                      id={item.id}
                      date={item.date}
                      deleteFolder={deleteFolder}
                      renameFolder={renameFolder}
                    />
                  ))}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RootFolders)
);
