import React,{useEffect,useState} from "react"
import {GoogleLogin,GoogleLoginResponseOffline} from "react-google-login"
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {googleClientApi} from "../../../auth/manager";
import setAuthorizationToken from "../../../utils/axios-instance";
import {SIGN_IN_SUCCESS} from "../../../auth";
import Icon from "./Icon"
import {Button,Avatar} from "@material-ui/core";
import GoogleLoginImage from "assets/google_login.png";
import {googleLogin} from "../../../auth/actions";
const useStyles=makeStyles((theme)=>({
    googleLogin:{
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #ccc",
        boxShadow:
            " 0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
        display: "flex",
        "&:hover": {
            backgroundColor: theme.palette.background.paper,
        },
    }
}))
function GoogleLoginButton({googleClient,setErrorMessage,setErrorMessage_1,showAlert}){
    const classes=useStyles()


    const [cookieDisabled ,setCookieDisabled]= useState(false)


const dispatch=useDispatch()

    const onSuccess=(response)=>{

        // console.log(response)
        // alert("Hello")
        // const token=await response.tokenId
        const code=response.code
        // console.log(code)

if(code){
     dispatch(googleLogin({
        code,
         product:"nocodepdf" ,
    }))
}}
    const onFailure=(response)=>{
        console.log(response)
        if(response.error==="idpiframe_initialization_failed"){
            setCookieDisabled(!cookieDisabled)
        }

        if(response.error == "popup_closed_by_user" && cookieDisabled){
            setErrorMessage("Cookies are not enabled in current environment.")
            setErrorMessage_1("Go to your first page of incognito, you can see in the bottom Block third-party cookies turn that off")
            showAlert(true)
        }
    }



    return (
        <div>

            <GoogleLogin
                className={classes.googleLoginBtn}
                clientId={googleClient?googleClient:`709056701788-6g2h2m8q3n7nd8g2589m726o2k9oa9kf.apps.googleusercontent.com`}
                // buttonText={"SignIn with Google"}

                render={renderProps => (
                    <Button
                  variant={"contained"}
                  className={classes.googleLogin}
                  // fullWidth
                  style={{
                    width: "100%",
                    padding: "10px 32px",
                    fontFamily: "AvertaRegular",
                    fontWeight: "600",
                  }}
                  onClick={()=>{

                      renderProps.onClick()
                  }}
                  startIcon={<Avatar src={GoogleLoginImage} />}
                >
                  Sign in with Google
                </Button>
                )}
                onSuccess={onSuccess}
                onFailure={onFailure}
                jsSrc={"https://apis.google.com/js/api.js"}
// redirectUri={"http://localhost:3001"}
responseType={"code"}
                accessType={"offline"}
                cookiePolicy={'single_host_origin'}
                isSignedIn={true}

            />
        </div>
    )
}

export default GoogleLoginButton