import {
    SET_CREATE_DATA,
    GET_CREATE_DATA,
    SET_INVITE_DATA,
    GET_INVITE_DATA
} from './action-types';


export function UiState() {
    return {
        createData: {},
        inviteData: {},
        account: {},
    }
}

export function UiReducer(state = new UiState(), { payload, type }) {
    switch (type) {
        case SET_CREATE_DATA:
            return {
                ...state,
                createData: payload
            };
        case SET_INVITE_DATA:
            return {
                ...state,
                inviteData: payload
            };

        default:
            return state;
    }
}
