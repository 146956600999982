import { routerMiddleware } from "connected-react-router";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import history from "./history";
import reducers from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";

export default (initialState = {}) => {
  let middleware = applyMiddleware(thunk, routerMiddleware(history));

  const store = createStore(
    reducers,
    initialState,
    composeWithDevTools(middleware)
  );

  if (module.hot) {
    module.hot.accept("./reducers", () => {
      store.replaceReducer(require("./reducers").default);
    });
  }

  return store;
};
