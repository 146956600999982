import axios from "axios";
import { axiosinstance } from "../utils/axios-instance";

export function getClientDetailsCall(client_id) {
  return axiosinstance.get(`api/v1/esign/realm/details/${client_id}`);
}

export function initSignCall(data) {
  // console.log("initSignCall :",  data)
  return axiosinstance.post("api/v1/esign/realm/init-esign", data);
}

export function addSignersCall(data) {
  // console.log("addSignerCall :", data)
  return axiosinstance.post("api/v1/esign/realm/add-signers", data);
}
