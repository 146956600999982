import setAuthorizationToken from "../utils/axios-instance";
import {
    getDetailsSuccessfully, getGoogleClient, refreshTokenFailed, refreshTokenSuccessfully,
    signOutSuccess
} from "./actions";


import { refreshTokenCall, getDetailsCall } from "./manager";
import {FIRST_TIME_ONBOARD} from "./action-types";




//  Verify Auth / presit login info on reload 
export const initAuth = async (dispatch) => {

    try {
        // console.log(window.location.pathname)
        // if(window.location.pathname==="/get-onboard"){


            const responseRefreshToken = await refreshTokenCall()
            dispatch(refreshTokenSuccessfully(responseRefreshToken.data))
            // dispatch({
            //     type:FIRST_TIME_ONBOARD,
            //     payload:true
            // })
            setAuthorizationToken(responseRefreshToken.data.data.access_token)
        

        
            // const responseRefreshToken = await refreshTokenCall()
            // dispatch(refreshTokenSuccessfully(responseRefreshToken.data))
            // setAuthorizationToken(responseRefreshToken.data.data.access_token)
            const responseClientDetails = await getDetailsCall()
            dispatch(getDetailsSuccessfully(responseClientDetails.data))

            return responseClientDetails
        

    } catch (e) {
        dispatch(refreshTokenFailed(e.response))
        dispatch(signOutSuccess())
        return e
    }



    // return dispatch(refreshToken())
    //     .then(result =>{
    //         dispatch(refreshTokenSuccessfully(result.data))
    //         setAuthorizationToken(result.data.data.access_token);
    //         dispatch(getDetails())
    //     })
    //     .catch(e=>{
    //         dispatch(refreshTokenFailed(e.response))
    //         dispatch(signOutSuccess())
    //     })
}