export const INIT_AUTH = "INIT_AUTH";

export const SIGN_IN_ERROR = "SIGN_IN_ERROR";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";

export const SIGN_OUT_FAILURE="SIGN_OUT_FAILURE";
export const SET_PHONE_NUMBER = "SET_PHONE_NUMBER";
export const SET_FULL_NAME = "SET_FULL_NAME";
export const SET_EMAIL = "SET_EMAIL";

export const SET_DEMO_API_REQUEST_INITIATED = "SET_DEMO_API_REQUEST_INITIATED"
export const SET_DEMO_API_REQUEST_FAILURE = "SET_DEMO_API_REQUEST_FAILURE"
export const SET_DEMO_API_REQUEST_SUCCESS = "SET_DEMO_API_REQUEST_SUCCESS"



export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";

export const REGISTER_INIT = "REGISTER_INIT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const FORGET_INIT = "FORGET_INIT";
export const FORGET_SUCCESS = "FORGET_SUCCESS";
export const FORGET_FAILURE = "FORGET_FAILURE";

export const CONFIRM_PASSWORD_INIT = "CONFIRM_PASSWORD_INIT";
export const CONFIRM_PASSWORD_SUCCESS = "CONFIRM_PASSWORD_SUCCESS";
export const CONFIRM_PASSWORD_FAILURE = "CONFIRM_PASSWORD_FAILURE";
export const CONFIRM_PASSWORD_NO_TOKEN = "CONFIRM_PASSWORD_NO_TOKEN";

export const CONFIRM_USER_INIT = "CONFIRM_USER_INIT";
export const CONFIRM_USER_SUCCESS = "CONFIRM_USER_SUCCESS";
export const CONFIRM_USER_FAILURE = "CONFIRM_USER_FAILURE";
export const CONFIRM_USER_NO_TOKEN = "CONFIRM_USER_NO_TOKEN";

export const CONFIRM_EMAIL_INIT = "CONFIRM_EMAIL_INIT";
export const CONFIRM_EMAIL_SUCCESS = "CONFIRM_EMAIL_SUCCESS";
export const CONFIRM_EMAIL_FAILURE = "CONFIRM_EMAIL_FAILURE";
export const CONFIRM_EMAIL_NO_TOKEN = "CONFIRM_EMAIL_NO_TOKEN";
export const INNER_TOGGLE_DRAWER = "INNER_TOGGLE_DRAWER";
export const OUTER_TOGGLE_DRAWER = "OUTER_TOGGLE_DRAWER";

export const GET_RAZORPAY_ORDER_ID = "GET_RAZORPAY_ORDER_ID";

export const OTP_SENT_TRUE = "OTP_SENT_TRUE";
export const OTP_SENT_FAILED = "OTP_SENT_FAILED";
export const OTP_SUBMIT_SUCCESS = "OTP_SUBMIT_SUCCESS";
export const OTP_SUBMIT_FAILED = "OTP_SUBMIT_FAILED";
export const OTP_VERIFIED_TRUE = "OTP_VERIFIED_TRUE";
export const SUBMIT_DETAILS = "SUBMIT_DETAILS";
export const GET_DETAILS = "GET_DETAILS";
export const FIRST_TIME = "FIRST_TIME";
export const FIRST_TIME_ONBOARD="FIRST_TIME_ONBOARD"

export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILED = "REFRESH_TOKEN_FAILED";

export const INIT_TOKEN = "INIT_TOKEN";
export const INIT_TOKEN_SUCCESS = "INIT_TOKEN_SUCCESS";
export const INIT_TOKEN_FAILURE = "INIT_TOKEN_FAILURE";

export const TOKEN_LIST = "TOKEN_LIST";
export const TOKEN_LIST_FAILURE = "TOKEN_LIST_FAILURE";
export const GENERATE_TOKEN = "GENERATE_TOKEN";
export const GENERATE_TOKEN_FAILURE = "GENERATE_TOKEN_FAILURE";
export const REVOKE_TOKEN = "REVOKE_TOKEN";
export const REVOKE_TOKEN_FAIURE = "REVOKE_TOKEN_FAILURE";

export const CALL_STARTED = "CALL_STARTED";
export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE";
export const CLEAR_STATUS_CODE = "CLEAR_STATUS_CODE";

export const MODAL_STATE = "MODAL_STATE";
export const SUCCESS_MODAL_STATE = "SUCCESS_MODAL_STATE";
export const IS_LOADER = "IS_LOADER";

export const CANCEL_MODAL = "CANCEL_MODAL";
export const CANCEL_SCHEDULE_MODAL = "CANCEL_SCHEDULE_MODAL";

export const CHANGE_PLAN_MODAL = "CHANGE_PLAN_MODAL";
export const CANCEL_CHANGE_MODAL = "CANCEL_CHANGE_MODAL";

export const CLEAR_ERROR = "CLEAR_ERROR";
export const SET_CLIENT_ID = "SET_CLIENT_ID"
export const CLEAR_OTP="CLEAR_OTP"

export const GOOGLE_CLIENT_ID="GOOGLE_CLIENT_ID";
export const CHARGEBEE_DETAILS="CHARGEBEE_DETAILS";