import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
// import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import moment from "moment";
import { useSelector } from "react-redux";
import {createMuiTheme, MuiThemeProvider, Paper, Typography} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import {MonetizationOnRounded} from "@material-ui/icons";

// const StyledTableCell = withStyles((theme) => ({
//   head: {
//     backgroundColor: "lightgray",
//     color: "gray",
//     padding: "2px",
//   },
//   body: {
//     fontSize: 12,
//     padding: "4px",
//   },
// }))(TableCell);

// const StyledTableRow = withStyles((theme) => ({
//   //   root: {
//   //     "&:nth-of-type(odd)": {
//   //       backgroundColor: theme.palette.action.hover,
//   //     },
//   //   },
// }))(TableRow);

// const useStyles = makeStyles({
//   table: {
//     minWidth: 900,
//   },
// });
const useStyles = makeStyles((theme) => ({
  paper: {
    width: "90%",
    margin: "40px auto",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
  },
  customHeading:{
    color:"#000",
    display:"flex",
    margin:"50px 0px 0px 55px",
    alignItems:"center"
  },
  customIcon:{
    fontSize: "35px",
    marginRight: "20px"
  },
}))
export default function Payment() {

  const classes = useStyles();
  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableToolbar: {
          actions: {
            display: "none",
          },
          root: {
            // backgroundColor: "#0084ec",
            // color: "#fff",
          },
        },
        MuiTableCell: {
          root: {
            padding: "6px",
          },
          body: {
            textAlign: "center",
          },
        },
        MuiPaper: {
          root: {
            width: "80%",
            margin: "20px auto",
          },
        },
        MuiInputBase: {
          formControl: {
            "&:hover": {
              outline: "none",
            },
          },
        },
        MuiPaper: {
          elevation4: {
            boxShadow: "none",
          },
          outlined: {
            border:"none",
            borderRight:"1px solid rgba(224, 224, 224, 1)",
            borderLeft:"1px solid rgba(224, 224, 224, 1)",
            borderTop:"1px solid rgba(224, 224, 224, 1)",
          },
        },
        MuiButton: {
          contained: {
            backgroundColor: "rgb(63, 81, 181)",
            color: "#fff",
            padding: ".5rem",

            fontWeight: "500",
            fontSize: ".875rem",

            lineHeight: "1",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "rgb(63, 81, 181)",
            },
          },
        },
        MUIDataTableHeadCell: {
          contentWrapper: {
            justifyContent: "center",
          },
          data: {
            fontWeight: "700",
          },
        },
        // MuiTableCell: {
        //   root: {
        //     "&:last-child": {
        //       // width: "10ch",
        //     },
        //   },
        // },
      },
    });

  // const classes = useStyles();
  const { billingDetail } = useSelector((state) => state.clientData);

  const columns = [
    "S.No",
    "Date",
    // "Valid Till",
    "Status",
    "Payment Method",
    "Amount",
  ];
  const rows = billingDetail?.data?.map((billing, index) => [
    index + 1,
    moment(billing?.created_at).format("YYYY-MM-DD HH:mm A"),
    // billing?.validTill,
    <Chip
      label={billing?.failed === false ? "Completed" : "Failed"}
      style={{
        backgroundColor: `${billing?.failed === false ? "#00D97C" : "#FE4F4F"}`,
        color: "#fff",
        fontSize: "13px",
      }}
    />,
    billing?.payment_method,
    <>&#36;{billing?.amount}</>,
  ]);

  return (
    <>
      <MuiThemeProvider theme={getMuiTheme()}>
        <Typography variant={"h4"} className={classes.customHeading}>
          <MonetizationOnRounded className={classes.customIcon}/>Payment History</Typography>
        <Paper className={classes.paper} variant="outlined" elevation={0}>
          <MUIDataTable
            title="Your recent transactions will show here"
            data={rows}
            columns={columns}
            options={{
              selectableRows: false, // <===== will turn off checkboxes in rows
              textLabels: {
                body: {
                  noMatch:<span>Sorry, no transactional record found</span>
                }
              }
            }}
          ></MUIDataTable>
        </Paper>
      </MuiThemeProvider>
    </>
  );
}
