import React from "react";
import { Tooltip } from "@material-ui/core";

function Tools({ data, title }) {
  return (
    <Tooltip title={title}>
      <div>{data}</div>
    </Tooltip>
  );
}

export default Tools;
