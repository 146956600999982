import {
  CANCEL_MODAL, CHARGEBEE_DETAILS, FIRST_TIME_ONBOARD,
  GENERATE_TOKEN_FAILURE, GOOGLE_CLIENT_ID,
  IS_LOADER,
    SIGN_OUT_FAILURE,
  MODAL_STATE,
  REVOKE_TOKEN_FAIURE, SET_DEMO_API_REQUEST_FAILURE, SET_DEMO_API_REQUEST_INITIATED, SET_DEMO_API_REQUEST_SUCCESS,
} from "auth";
import localForage from "localforage";
import setAuthorizationToken from "../utils/axios-instance";
import {
  CALL_STARTED,
  CONFIRM_EMAIL_FAILURE,
  CONFIRM_EMAIL_INIT,
  CONFIRM_EMAIL_NO_TOKEN,
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_PASSWORD_FAILURE,
  CONFIRM_PASSWORD_INIT,
  CONFIRM_PASSWORD_NO_TOKEN,
  CONFIRM_PASSWORD_SUCCESS,
  CONFIRM_USER_FAILURE,
  CONFIRM_USER_INIT,
  CONFIRM_USER_NO_TOKEN,
  CONFIRM_USER_SUCCESS,
  FIRST_TIME,
  FORGET_FAILURE,
  FORGET_INIT,
  FORGET_SUCCESS,
  GENERATE_TOKEN,
  GET_DETAILS,
  INIT_AUTH,
  INIT_TOKEN,
  INIT_TOKEN_FAILURE,
  INIT_TOKEN_SUCCESS,
  INNER_TOGGLE_DRAWER,
  OTP_SENT_FAILED,
  OTP_SENT_TRUE,
  OTP_SUBMIT_FAILED,
  OTP_SUBMIT_SUCCESS,
  OTP_VERIFIED_TRUE,
  OUTER_TOGGLE_DRAWER,
  REFRESH_TOKEN_FAILED,
  REFRESH_TOKEN_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_INIT,
  REGISTER_SUCCESS,
  REVOKE_TOKEN,
  SET_EMAIL,
  SET_FULL_NAME,
  SET_PHONE_NUMBER,
  SIGN_IN_ERROR,
  SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS,
  SUBMIT_DETAILS,
  TOKEN_LIST,
  TOKEN_LIST_FAILURE,
  CLEAR_ERROR_MESSAGE,
  CLEAR_STATUS_CODE,
  SUCCESS_MODAL_STATE,
} from "./action-types";
import {
  confirmEmailCall,
  confirmPasswordCall,
  confirmUserCall,
  forget,
  getDetailsCall,
  logoutCall,
  refreshTokenCall,
  register,
  sentOtpCall,
  signIn,
  submitUserDetailsCall,
  submitOtpCall,
  initTokenCall,
  verifyOtpCall,
  googleLoginCall,
  getListToken,
  generateServerToken,
  revokeToken, sendDemoApiRequestCall, googleClientApi, chargeebeeUrlApi,
} from "./manager";

export function getGoogleClient(){
  return dispatch=>googleClientApi().then(res=>{
    dispatch({
      type:GOOGLE_CLIENT_ID,
      payload:res.data
    })
  }).catch(err=>console.log(err.response))
}

export function getChargebeeDetails(){
  return dispatch=>chargeebeeUrlApi().then(res=>{
    dispatch({
      type:CHARGEBEE_DETAILS,
      payload:res.data
    })
  }).catch(err=>console.log(err.response))
}

export function changeModal(value) {
  return (dispatch) => {
    dispatch({
      type: MODAL_STATE,
      payload: value,
    });
  };
}

function authenticate(username, password) {
  return (dispatch) => {
    signIn(username, password)
      .then((result) => {
        localForage
          .setItem("person", !!result.data ? result.data : null)
          .then(() => setAuthorizationToken(result.data.token))
          .then(() => setTimeout(dispatch(signInSuccess(result.data)), 1000));
      })
      .catch((error) => dispatch(signInError(error)));
  };
}

export function initAuth(user) {
  return {
    type: INIT_AUTH,
    payload: user,
  };
}

export function sentOtp(obj) {
  return (dispatch) => {
    dispatch(callStarted());
    sentOtpCall(obj)
      .then((result) => {
        // console.log(result);
        dispatch(otpSentSuccessfully(result.data));
      })
      .catch((e) => {
        dispatch(otpSentFailed(e.response));
      });
  };
}

export function otpSentSuccessfully(payload) {
  return {
    type: OTP_SENT_TRUE,
    payload: payload,
  };
}
export function otpSentFailed(payload) {
  return {
    type: OTP_SENT_FAILED,
    payload: payload,
  };
}

export function signInError(error) {
  return {
    type: SIGN_IN_ERROR,
    payload: error,
  };
}

export function signInSuccess(result) {
  return {
    type: SIGN_IN_SUCCESS,
    payload: result,
  };
}
export function setPhoneNumber(phone) {
  return {
    type: SET_PHONE_NUMBER,
    payload: phone,
  };
}
export function setEmail(phone) {
  return {
    type: SET_EMAIL,
    payload: phone,
  };
}
export function setFullname(phone) {
  return {
    type: SET_FULL_NAME,
    payload: phone,
  };
}

export function signInWithGoogle(username, password) {
  return authenticate(username, password);
}

export function sendDemoApiRequest(obj){
  return (dispatch)=>{
    dispatch(sendDemoRequestInitiated())
    sendDemoApiRequestCall(obj)
        .then((result)=>dispatch(sendDemoRequestSuccess(result?.data)))
        .catch((e)=>dispatch(sendDemoRequestFailure(e.response)))
  }
}

export function sendDemoRequestInitiated(){
  return{
    type:SET_DEMO_API_REQUEST_INITIATED
  }
}

export function sendDemoRequestSuccess(payload){
  return{
    type:SET_DEMO_API_REQUEST_SUCCESS,
    payload
  }
}

export function sendDemoRequestFailure(payload){
  return{
    type:SET_DEMO_API_REQUEST_FAILURE,
    payload
  }
}


export function submitOtp(obj) {
  return (dispatch) => {
    dispatch(callStarted());
    submitOtpCall(obj)
      .then((result) => {
        dispatch(otpSubmitSuccessfully(result.data));
        localForage
          .setItem("person", !!result.data ? result.data.data : null)
          .then(() => setAuthorizationToken(result.data.data.access_token))
          .then(() => {
            // console.log("OnboardRequired",result?.data?.data?.onboard_required)
            if(!result?.data?.data?.onboard_required) {
              dispatch(signInSuccess(result?.data))
            }
            else{
              dispatch({
                type:FIRST_TIME_ONBOARD,
                payload:true
              })
            }
          })
          .catch((e) => dispatch(otpSubmitFailed(e.response)));
      })
      .catch((e) => dispatch(otpSubmitFailed(e.response)));
  };
}

export function otpSubmitSuccessfully(payload) {
  return {
    type: OTP_SUBMIT_SUCCESS,
    payload: payload,
  };
}

export function otpSubmitFailed(payload) {
  // console.log(payload);
  return {
    type: OTP_SUBMIT_FAILED,
    payload: payload,
  };
}

export function googleLogin(obj) {
  return (dispatch) => {
    dispatch(callStarted());
    googleLoginCall(obj)
      .then((result) => {
        dispatch(otpSubmitSuccessfully(result.data));
        localForage
          .setItem("person", !!result.data ? result.data.data : null)
            .then(() => setAuthorizationToken(result.data.data.access_token))
          .then(() => dispatch(signInSuccess(result.data)))
          .catch((e) => dispatch(otpSubmitFailed(e.response)));
      })
      .catch((e) => dispatch(otpSubmitFailed(e.response)));
  };
}

export function callStarted() {
  return {
    type: CALL_STARTED,
    payload: null,
  };
}

export function refreshToken() {
  return (dispatch) =>
    refreshTokenCall()
      .then((result) => {
        setAuthorizationToken(result.data.data.access_token);
        dispatch(refreshTokenSuccessfully(result.data));
      })
      .catch((e) => {
        // dispatch(refreshTokenFailed(e.response))
        dispatch(logout());
      });
}

export function refreshTokenSuccessfully(payload) {
  return {
    type: REFRESH_TOKEN_SUCCESS,
    payload: payload,
  };
}
export function refreshTokenFailed(payload) {
  return {
    type: REFRESH_TOKEN_FAILED,
    payload: payload,
  };
}

export function verifyOtp(otp) {
  return (dispatch) =>
    verifyOtpCall(otp)
      .then((result) => {
        dispatch(otpVerifiedSuccessfully(result.data));
      })
      .catch((e) => {});
}

export function otpVerifiedSuccessfully(payload) {
  return {
    type: OTP_VERIFIED_TRUE,
    payload: payload,
  };
}

export function submitUserDetails(obj) {
  return (dispatch) =>
    submitUserDetailsCall(obj)
      .then((result) => {
        setTimeout(dispatch(submitDetailsSuccessfully(result.data)), 1000);
dispatch({
  type:FIRST_TIME_ONBOARD,
  payload:false
})
      })
      .catch((e) => {
        dispatch(otpSentFailed(e.response));
      });
}

export function getDetails() {
  return (dispatch) =>
    getDetailsCall()
      .then((result) => {
        dispatch(getDetailsSuccessfully(result.data));
      })
      .catch((e) => {
        // console.log("error");
      });
}

export function getDetailsSuccessfully(payload) {
  console.log("Payload",payload)
  return {
    type: GET_DETAILS,
    payload: payload,
  };
}

export function submitDetailsSuccessfully(payload) {
  // console.log(payload)
  return {
    type: SUBMIT_DETAILS,
    payload: payload,
  };
}

export function setFirstTime(flag) {
  return {
    type: FIRST_TIME,
    payload: flag,
  };
}

export function signOut() {
  return (dispatch) => {
    localForage.removeItem("person").then(dispatch(signOutSuccess()));
  };
}

export function registerAccount(name, email, password) {
  return (dispatch) => {
    dispatch(registerInit());
    register(name, email, password)
      .then(dispatch(registerSuccess()))
      .catch((error) => dispatch(registerFailure(error)));
  };
}

export function logout() {
  return (dispatch) =>
    logoutCall()
      .then((result) => {
        setTimeout(dispatch(signOutSuccess()), 500);
      })
      .catch((e) => {
        dispatch(signOutFailure(e.response))
      });
}

export function signOutFailure(payload){
  return {type:SIGN_OUT_FAILURE,
    payload:payload

  }
}
export function signOutSuccess() {
  return {
    type: SIGN_OUT_SUCCESS,
  };
}

export function registerInit() {
  return {
    type: REGISTER_INIT,
  };
}

export function registerSuccess() {
  return {
    type: REGISTER_SUCCESS,
  };
}

export function registerFailure(error) {
  return {
    type: REGISTER_FAILURE,
    payload: error,
  };
}

export function forgetAccount(email) {
  return (dispatch) => {
    dispatch(forgetInit());
    forget(email)
      .then(dispatch(forgetSuccess()))
      .catch((error) => dispatch(forgetFailure(error)));
  };
}

export function forgetInit() {
  return {
    type: FORGET_INIT,
  };
}

export function forgetSuccess() {
  return {
    type: FORGET_SUCCESS,
  };
}

export function forgetFailure(error) {
  return {
    type: FORGET_FAILURE,
    payload: error,
  };
}

export function confirmPassword(password, token) {
  return (dispatch) => {
    dispatch(confirmPasswordInit());
    confirmPasswordCall(password, token)
      .then(dispatch(confirmPasswordSuccess()))
      .catch((error) => dispatch(confirmPasswordFailure(error)));
  };
}

export function confirmPasswordInit() {
  return {
    type: CONFIRM_PASSWORD_INIT,
  };
}

export function confirmPasswordSuccess() {
  return {
    type: CONFIRM_PASSWORD_SUCCESS,
  };
}

export function confirmPasswordFailure(error) {
  return {
    type: CONFIRM_PASSWORD_FAILURE,
    payload: error,
  };
}

export function confirmPasswordNoToken() {
  return {
    type: CONFIRM_PASSWORD_NO_TOKEN,
  };
}

export function confirmUser(token) {
  return (dispatch) => {
    dispatch(confirmUserInit());
    confirmUserCall(token)
      .then(dispatch(confirmUserSuccess()))
      .catch((error) => dispatch(confirmUserFailure(error)));
  };
}

export function confirmUserInit() {
  return {
    type: CONFIRM_USER_INIT,
  };
}

export function confirmUserSuccess() {
  return {
    type: CONFIRM_USER_SUCCESS,
  };
}

export function confirmUserFailure(error) {
  return {
    type: CONFIRM_USER_FAILURE,
    payload: error,
  };
}

export function confirmUserNoToken() {
  return {
    type: CONFIRM_USER_NO_TOKEN,
  };
}

export function confirmEmail(token) {
  return (dispatch) => {
    dispatch(confirmEmailInit());
    confirmEmailCall(token)
      .then(dispatch(confirmEmailSuccess()))
      .catch((error) => dispatch(confirmEmailFailure(error)));
  };
}

export function confirmEmailInit() {
  return {
    type: CONFIRM_EMAIL_INIT,
  };
}

export function confirmEmailSuccess() {
  return {
    type: CONFIRM_EMAIL_SUCCESS,
  };
}

export function confirmEmailFailure(error) {
  return {
    type: CONFIRM_EMAIL_FAILURE,
    payload: error,
  };
}

export function confirmEmailNoToken() {
  return {
    type: CONFIRM_EMAIL_NO_TOKEN,
  };
}
export function innerToggleDrawer(payload) {
  return {
    type: INNER_TOGGLE_DRAWER,
    payload,
  };
}
export function outerToggleDrawer(payload) {
  return {
    type: OUTER_TOGGLE_DRAWER,
    payload,
  };
}

export function initToken(obj) {
  return (dispatch) => {
    dispatch(initTokeStart());
    initTokenCall(obj)
      .then((result) => dispatch(initTokenSuccess(result.data)))
      .catch((e) => dispatch(initTokenFailed(e.response)));
  };
}
export function initTokeStart() {
  return {
    type: INIT_TOKEN,
    payload: true,
  };
}
export function initTokenSuccess(data) {
  return {
    type: INIT_TOKEN_SUCCESS,
    payload: data,
  };
}
export function initTokenFailed(data) {
  return {
    type: INIT_TOKEN_FAILURE,
    payload: data,
  };
}
export function tokenList() {
  return (dispatch) => {
    getListToken()
      .then((res) => {
        dispatch({
          type: TOKEN_LIST,
          payload: res.data,
        });
        dispatch({
          type: IS_LOADER,
          payload: true,
        });
      })
      .catch((error) => {
        dispatch({
          type: TOKEN_LIST_FAILURE,
          payload: error.response,
        });
        dispatch({
          type: IS_LOADER,
          payload: true,
        });
      });
  };
}
export function generateToken(value) {
  return (dispatch) => {
    generateServerToken(value)
      .then((res) =>
        dispatch({
          type: GENERATE_TOKEN,
          payload: res.data,
        })
      )
      .catch((error) =>
        dispatch({ type: GENERATE_TOKEN_FAILURE, payload: error.response })
      );
  };
}
export function callRevokeToken(value) {
  return (dispatch) => {
    dispatch({
      type: IS_LOADER,
      payload: false,
    });
    revokeToken(value)
      .then((res) => {
        dispatch({
          type: REVOKE_TOKEN,
        });
      })
      .catch((e) => {
        // console.log(e.response);
        dispatch({
          type: REVOKE_TOKEN_FAIURE,
          payload: e.response,
        });
        dispatch({
          type: IS_LOADER,
          payload: true,
        });
      });
  };
}

export function clearError() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ERROR_MESSAGE,
      payload: "",
    });
    dispatch({
      type: CLEAR_STATUS_CODE,
      payload: "",
    });
  };
}

export function successModal(value) {
  return (dispatch) =>
    dispatch({
      type: SUCCESS_MODAL_STATE,
      payload: value,
    });
}

export function cancelModal(value) {
  return (dispatch) =>
    dispatch({
      type: CANCEL_MODAL,
      payload: value,
    });
}
