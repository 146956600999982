import React, {Component} from 'react';
import {connect} from "react-redux";
import {Snackbar, withStyles} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import FileCopyIcon from '@material-ui/icons/FileCopy';

const styles = theme => ({
    close: {
        width: theme.spacing.unit * 4,
        height: theme.spacing.unit * 4,
    },
    snackbarAlert: {
        padding: "0px 16px",
        backgroundColor: "#4c7cda"
    },
    sanckbarMessage: {
        fontSize: 12,
        opacity: 0.5
    },
    snackbar: {
        marginTop: 20
    }
});


class CustomSnackbar extends Component {

    constructor(props) {
        super(props);
        this.hideSnackbar = this.hideSnackbar.bind(this);
    }

    hideSnackbar(event, reason) {
        this.props.dismissNotification();
    }

    render() {
        const {classes, display, message, title} = this.props;

        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={display}
                className={classes.snackbar}
                autoHideDuration={2000}
                onClose={this.hideSnackbar}>
                <Alert className={classes.snackbarAlert}
                       variant="filled"
                       icon={message && <FileCopyIcon fontSize="inherit"/>}
                       severity="info">
                    {title}<br/>
                    <span className={classes.sanckbarMessage}>{message}</span>
                </Alert>
            </Snackbar>
        );
    }
}

const mapStateToProps = (state) => ({})


const mapDispatchToProps = Object.assign(
    {},
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomSnackbar));
