import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  Snackbar,
  Paper,
  Typography,
  InputAdornment,
  Input,
  TextField,
  withStyles,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { initSignActions } from "CreateDoc";
import { UiActions } from "globalUI";
import { authActions } from "auth";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Template from "./Template";
import Upload from "./Upload";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/core/SvgIcon/SvgIcon";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import { GenerateButton } from "../../../components/CustomizeButton";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CustomSnackbar from "../../../components/custom-snackbar";
import FormControl from "@material-ui/core/FormControl";
import { Alert } from "@material-ui/lab";
import API from "../../account/settings/apiTab";
import { tokenList } from "auth/actions";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(4),
  },
  navButton: {
    marginRight: theme.spacing(3),
    borderRadius: 0,
    borderBottom: `2px solid ${theme.palette.grey[100]}`,
    "&:hover": {
      borderBottom: `2px solid ${theme.palette.grey[500]}`,
    },
  },
  navButtonActive: {
    marginRight: theme.spacing(3),
    color: theme.palette.primary.main,
    borderRadius: 0,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    "&:hover": {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  },
  contentBox: {
    background: theme.palette.background.paper,
    padding: "50px",
    boxShadow: "0px 0px 50px -20px #ccc",
    marginTop: "50px",
    borderRadius: "10px",
    marginBottom: "1rem",
  },
  copyDiv: {
    background: theme.palette.action.hover,
    paddingRight: 10,
    cursor: "pointer",
  },
  customBtn: {
    textTransform: "capitalize",
    backgroundColor: "rgb(63 81 181)",
    fontSize: 16,
  },
  copyEndornment: {
    textTransform: "uppercase",
    cursor: "pointer",
  },
  copyTextField: {
    "&::before": {
      borderBottom: "1px solid #c5c5c58f",
    },
  },
  divider: {
    marginBottom: "1rem",
    marginTop: "1rem",
    width: "15%",
    background: "#3f51b5",
    height: "2px",
    borderRadius: "50px",
  },
}));

const CustomTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#5367da",
      },
      "&.Mui-focused .MuiInputAdornment-root": {
        color: "#5367da",
      },
    },
  },
})(TextField);

function Create({ setActiveStep, ...props }) {
  const classes = useStyles();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [notification, setNotification] = useState(false);
  const [generatedLink, setLinkGenerate] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(props.errorMsg);
  }, [props.errorMsg]);

  // useEffect(() => {
  //    setLinkGenerate(props.generatedLink)
  // }, [props.generatedLink])

  const onSubmit = async (data) => {
    // let payload = {
    //     data: {
    //         prefill_options: {
    //             full_name: data.fullName,
    //             mobile_number: data.phone,
    //             user_email: data.email
    //         },
    //         verify_phone: false,
    //         verify_email: true,
    //         allow_selection: false,
    //         redirect_url: "https://digilocker-dot-neopt-capture.el.r.appspot.com/verified",
    //         documents_required: [
    //             {
    //                 "type_of_document": "pan",
    //                 "must_have": true
    //             },
    //             {
    //                 "type_of_document": "aadhaar",
    //                 "must_have": false
    //             },
    //             {
    //                 "type_of_document": "rc",
    //                 "must_have": true
    //             },
    //             {
    //                 "type_of_document": "license",
    //                 "must_have": true
    //             }
    //         ]
    //     }
    // }
    // await props.initToken(payload)
    setLinkGenerate(
      "https://surecowin.surepass.io/?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MjI3MDQ0NDksIm5iZiI6MTYyMjcwNDQ0OSwianRpIjoiYWEyOWUxNDItMDkxMS00ZWFiLWFkNzAtOGM3Zjg2MDdjZmMxIiwiZXhwIjoxNjIyNzkwODQ5LCJpZGVudGl0eSI6ImRpZ2lsb2NrZXJfb21idWZ4TmxkT096REhkR05sckUiLCJmcmVzaCI6ZmFsc2UsInR5cGUiOiJhY2Nlc3MiLCJ1c2VyX2NsYWltcyI6eyJnYXRld2F5Ijoic2FuZGJveCIsInR5cGUiOiJkaWdpbG9ja2VyIn19.g-4SFULvaEjD9-LKsxrZe3drpqEpXzwSzmxW9i4LzYs"
    );
  };

  const onCopy = () => {
    setNotification(true);
  };
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const phoneRegex = /^\+?([0-9]{2})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{5})$/;
  const phoneRegex2 = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const nameRegex = /^[a-zA-Z/\s]{2,30}$/;
  useEffect(() => {
    tokenList();
  }, []);
  return (
    // dont wrap inside a div or any component keep main container and footer structure
    <>
      {/* main container */}
      <div>
        {/* <CustomSnackbar
          dismissNotification={() => setNotification(false)}
          display={notification}
          message={"Successfully copied"}
          title={"Link"}
        /> */}
        <Snackbar
          open={!!error}
          autoHideDuration={3000}
          onClose={() => setError(null)}
        >
          <Alert onClose={() => setError(null)} severity="error">
            {error}
          </Alert>
        </Snackbar>
        <Grid container justifyContent="center">
          <Grid item xs={10} className={classes.contentBox}>
            <Typography color={"textPrimary"} variant={"h4"}>
              API
            </Typography>
            <Divider className={classes.divider} />
            <Grid container>
              <Grid item xs={10}>
                <API />
              </Grid>

              {/* <Grid item xs={5}>
                <Typography color="textPrimary" gutterBottom>
                  Full Name *
                </Typography>
                <CustomTextField
                  required
                  size={"small"}
                  type={"text"}
                  error={!!errors?.fullName}
                  inputRef={register({
                    validate: (val) => nameRegex.test(val),
                  })}
                  name="fullName"
                  placeholder="Ex:John doe"
                  variant="outlined"
                  helperText={
                    !!errors?.fullName && "Full name includes only alphabets"
                  }
                  autoComplete="off"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <br />
                <br />
              </Grid> */}
              {/* 
                                <Grid item xs={5} style={{marginLeft:20}}>
                                    <Typography color="textPrimary" gutterBottom>Email *</Typography>
                                    <CustomTextField
                                        required
                                        size={"small"}
                                        error={!!errors?.email}
                                        inputRef={register({
                                            validate:(val)=>emailRegex.test(val)
                                        })}
                                        name="email"
                                        type={"email"}
                                        placeholder="Ex:abc@gmail.com"
                                        variant="outlined"
                                        helperText={!!errors?.email && "Please enter a valid email"}
                                        autoComplete="off"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EmailIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <br/>
                                    <br/>
                                </Grid>

                                <Grid item xs={5}>
                                    <Typography color="textPrimary" gutterBottom>Phone Number *</Typography>
                                    <CustomTextField
                                        required
                                        size={"small"}
                                        error={!!errors?.phone}
                                        inputRef={register({
                                            validate:(val)=>(phoneRegex.test(val) || phoneRegex2.test(val))
                                        })}
                                        name="phone"
                                        type={"number"}
                                        placeholder="Ex: 9999999999"
                                        helperText={!!errors?.phone && "Please enter a valid phone number of 10 digits"}
                                        variant="outlined"
                                        autoComplete="off"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PhoneIphoneIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <br/><br/>
                                </Grid> */}

              {/* <Grid item xs={12}> */}
              {/* <Button
                  variant={"contained"}
                  color={"primary"}
                  size={"small"}
                  onClick={handleSubmit(onSubmit)}
                  endIcon={<ArrowForwardIosIcon />}
                  className={classes.customBtn}
                >
                  Generate Link
                </Button>
                <br />
                <br /> */}
              {/* <Collapse in={!!generatedLink} unmountOnExit>
                  <Grid item xs={8}>
                    <Typography color="textPrimary" gutterBottom>
                      Generated Link
                    </Typography>

                    <Grid container>
                      <Grid item xs={10}>
                        <TextField
                          fullWidth
                          variant={"outlined"}
                          value={generatedLink}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <CopyToClipboard text={generatedLink} onCopy={onCopy}>
                          <IconButton>
                            <FileCopyIcon style={{ color: "#888888" }} />
                          </IconButton>
                        </CopyToClipboard>
                      </Grid>
                    </Grid>
                  </Grid>
                </Collapse> */}
              {/* </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  createData: state.ui.createData,
  generatedLink: state.auth.generatedLink,
  errorMsg: state.auth.errorMsg,
});

const mapDispatchToProps = {
  setCreateData: UiActions.setCreateData,
  initSign: initSignActions.initSign,
  initToken: authActions.initToken,
  tokenList: authActions.tokenList,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Create));
