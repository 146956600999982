import {
    Box, Collapse, FormControlLabel,
    Switch, Typography
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import { Controller, useWatch } from "react-hook-form";

export default function SignDrawer({ item, idx, control }) {

    const aadhaarSign = useWatch({ control, name: `invitees[${idx}].signType.aadhaarSign` });
    const virtualSign = useWatch({ control, name: `invitees[${idx}].signType.virtualSign` });


    return (
        <>
            <Box p={2}>
                <Controller
                    render={(
                        { onChange, onBlur, value, name, ref },
                        { invalid, isTouched, isDirty }
                    ) => (
                        <FormControlLabel
                            control={<Switch
                                onBlur={onBlur}
                                onChange={(e) => onChange(e.target.checked)}
                                checked={value}
                                inputRef={ref}
                            />}
                            label="Aadhaar Sign"
                        />
                    )}
                    control={control}
                    name={`invitees[${idx}].signType.aadhaarSign`}
                    defaultValue={item?.signType?.aadhaarSign ?? true}
                />
                <Collapse in={aadhaarSign}>
                    <>
                        <Box pl={5} m={1}>
                            <Controller
                                render={(
                                    { onChange, onBlur, value, name, ref },
                                    { invalid, isTouched, isDirty }
                                ) => (
                                    <FormControlLabel
                                        control={<Switch
                                            onBlur={onBlur}
                                            onChange={(e) => onChange(e.target.checked)}
                                            checked={value}
                                            inputRef={ref}
                                        />}
                                        label={<Typography variant="body2">OTP</Typography>}

                                    />
                                )}
                                control={control}
                                name={`invitees[${idx}].signType.aadhaar.otp`}
                                defaultValue={item?.signType?.aadhaar?.otp ?? true}
                            />
                        </Box>
                        <Box pl={5} m={1}>
                            <Controller
                                render={(
                                    { onChange, onBlur, value, name, ref },
                                    { invalid, isTouched, isDirty }
                                ) => (
                                    <FormControlLabel
                                        control={<Switch
                                            onBlur={onBlur}
                                            onChange={(e) => onChange(e.target.checked)}
                                            checked={value}
                                            inputRef={ref}
                                        />}
                                        label={<Typography variant="body2">Biometric thumb scan</Typography>}
                                    />
                                )}
                                control={control}
                                name={`invitees[${idx}].signType.aadhaar.thumbScan`}
                                defaultValue={item?.signType?.aadhaar?.thumbScan ?? false}
                            />
                        </Box>
                        <Box pl={5} m={1}>
                            <Controller
                                render={(
                                    { onChange, onBlur, value, name, ref },
                                    { invalid, isTouched, isDirty }
                                ) => (
                                    <FormControlLabel
                                        control={<Switch
                                            onBlur={onBlur}
                                            onChange={(e) => onChange(e.target.checked)}
                                            checked={value}
                                            inputRef={ref}
                                        />}
                                        label={<Typography variant="body2">Iris Scan</Typography>}
                                    />
                                )}
                                control={control}
                                name={`invitees[${idx}].signType.aadhaar.irisScan`}
                                defaultValue={item?.signType?.aadhaar?.irisScan ?? false}
                            />
                        </Box>
                    </>
                </Collapse>
            </Box>
            <Divider />

            <Box p={2}>
                <Controller
                    render={(
                        { onChange, onBlur, value, name, ref },
                        { invalid, isTouched, isDirty }
                    ) => (
                        <FormControlLabel
                            control={<Switch
                                onBlur={onBlur}
                                onChange={(e) => onChange(e.target.checked)}
                                checked={value}
                                inputRef={ref}
                            />}
                            label="Virtual Sign"
                        />
                    )}
                    control={control}
                    name={`invitees[${idx}].signType.virtualSign`}
                    defaultValue={item?.signType?.virtualSign ?? false}
                />
                <Collapse in={virtualSign}>
                    <>
                        <Box pl={5} m={1}>
                            <Controller
                                render={(
                                    { onChange, onBlur, value, name, ref },
                                    { invalid, isTouched, isDirty }
                                ) => (
                                    <FormControlLabel
                                        control={<Switch
                                            onBlur={onBlur}
                                            onChange={(e) => onChange(e.target.checked)}
                                            checked={value}
                                            inputRef={ref}
                                        />}
                                        label={<Typography variant="body2">Allow invitee to draw signature </Typography>}
                                    />
                                )}

                                control={control}
                                name={`invitees[${idx}].signType.virtual.drawSign`}
                                defaultValue={item?.signType?.virtual?.drawSign ?? true}
                            />
                        </Box>
                        <Box pl={5} m={1}>
                            <Controller
                                render={(
                                    { onChange, onBlur, value, name, ref },
                                    { invalid, isTouched, isDirty }
                                ) => (
                                    <FormControlLabel
                                        control={<Switch
                                            onBlur={onBlur}
                                            onChange={(e) => onChange(e.target.checked)}
                                            checked={value}
                                            inputRef={ref}
                                        />}
                                        label={<Typography variant="body2">Allow invitee to choose computer-generated signature</Typography>}
                                    />
                                )}
                                control={control}
                                name={`invitees[${idx}].signType.virtual.cgSign`}
                                defaultValue={item?.signType?.virtual?.cgSign ?? true}
                            />
                        </Box>
                    </>
                </Collapse>
            </Box>
            <Divider />
        </>
    )
}
