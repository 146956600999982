import { Box, Button, Collapse, Divider, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { NaturePeopleOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import Branding from './Branding';
import Signature from './eSignature';
import Reviewer from './Reviewer';
import Security from './Security';
import Stamp from './Stamp';


const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(4),
        marginTop: theme.spacing(4),
        textTransform: "none",
    },
    navButton: {
        marginRight: theme.spacing(3),
        borderRadius: 0,
        textTransform: "none",
        borderBottom: `2px solid #fff `,
        color: theme.palette.grey[700],
        "&:hover": {
            color: theme.palette.grey[900],
            borderBottom: `2px solid ${theme.palette.grey[700]}`
        }
    },
    navButtonActive: {
        marginRight: theme.spacing(3),
        borderRadius: 0,
        textTransform: "none",
        color: theme.palette.primary.main,
        borderBottom: `2px solid ${theme.palette.primary.main}`
    }
}));

const getTab = (idx) => {
    return (
        <>
            <Collapse in={idx === 0}>
                <Branding />
            </Collapse>

            <Collapse in={idx === 1}>
                <Security />
            </Collapse>

            <Collapse in={idx === 2}>
                <Signature />
            </Collapse>

            <Collapse in={idx === 3}>
                <Stamp />
            </Collapse>

            <Collapse in={idx === 4}>
                <Reviewer />
            </Collapse>
        </>
    )
}


export default function Department() {
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState(0);

    return (
        <Grid container justify="center">
            <Grid item xs={11}>
                <Box my={4} display="flex" alignItems="flex-end">
                    <NaturePeopleOutlined /> &nbsp;&nbsp;&nbsp;
                    <Typography variant="caption">
                        Manage your Department wide Settings here!
                    </Typography>
                </Box>
            </Grid>
            <Grid component={Paper} variant="outlined" container item xs={11} justify="center" style={{ padding: "20px 0" }}>
                <Grid item xs={11}>
                    <Button className={activeTab === 0 ? classes.navButtonActive : classes.navButton}
                        onClick={() => setActiveTab(0)}
                    >
                        Branding
                    </Button>
                    <Button className={activeTab === 1 ? classes.navButtonActive : classes.navButton}
                        onClick={() => setActiveTab(1)}
                    >
                        Security
                    </Button>
                    <Button className={activeTab === 2 ? classes.navButtonActive : classes.navButton}
                        onClick={() => setActiveTab(2)}
                    >
                        eSignature
                    </Button>
                    <Button className={activeTab === 3 ? classes.navButtonActive : classes.navButton}
                        onClick={() => setActiveTab(3)}
                    >
                        Stamp
                    </Button>
                    <Button className={activeTab === 4 ? classes.navButtonActive : classes.navButton}
                        onClick={() => setActiveTab(4)}
                    >
                        Reviewer
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={11}>
                    {getTab(activeTab)}
                </Grid>
            </Grid>
        </Grid >
    )
}
